import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import DateTimePicker from 'components/molecules/DateTimePicker/DateTimePicker';
import DetailView from 'components/molecules/DetailView/DetailView';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import MarketInformation from 'components/organisms/MarketInformation/MarketInformation';
import {
  editMarketInfoToDetailState,
  getInitialMarketDate,
  getMarketDetailOptionsForEdit,
  getMarketDetailOptionsForReview,
  marketDetailToUid,
  marketInfosToDetailState,
  validateMarketInfos,
} from 'components/organisms/MarketInformationView/helpers';
import useMarketInfos from 'components/organisms/MarketInformationView/useMarketInfos';
import { MISO_MARKET_DETAIL, SPP_MARKET_DETAIL } from 'constants/ETag';
import { DATE_FORMAT } from 'constants/time';
import { ECompositeState, EMarketInfoMarket } from 'enums/ETag';
import { ERetreiveState, ESeverity, EUpdateState } from 'enums/General';
import { EViewResize } from 'enums/View';
import { IOption, IValidationMessage } from 'interfaces/Component';
import { getEditInfoKey } from 'utils/detail';
import {
  IEditMarketInfo,
  IEditMisoMarketData,
  IEditSppMarketData,
  IMarketInfos,
} from 'interfaces/Detail';
import {
  IETagMarketDetail,
  IETagMarketInfo,
  IETagMarketInfoPrice,
  IETagMisoMarketData,
  IETagSppMarketData,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { IViewProps } from 'interfaces/View';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  detailEditETagDetail,
  detailSetValidations,
} from 'reduxes/Detail/actions';
import styled from 'styled-components';
import { TFormatTemplate, TTimeZone } from 'types/DateTime';
import { TRootState } from 'types/Redux';
import { isMarketInfosEditable } from 'utils/detail';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { IDetailMarketSegment } from '../../../reduxes/Detail/types';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import { EDIT_MARKET_INFOS_LABEL } from 'constants/Detail';
import { TDetailValidations } from 'types/Detail';
import Tooltip from 'components/molecules/Tooltip/Tooltip';

const MarketDatePicker = styled(DateTimePicker)`
  width: 100px;
`;

const MarketDate = styled.div`
  font-size: 12px;
`;

const MarketSelect = styled((props: ISelectProps<IETagMarketDetail>) =>
  Select<IETagMarketDetail>(props),
)`
  width: 67px;
`;

interface IMarketInformationViewProps extends IViewProps {}

const retrieveMarketInformationViewState = (state: TRootState) => {
  const {
    config,
    composite_state,
    marketInfos,
    pageMode,
    retrievingDetail,
    start_date,
    updatingDetail,
    viewMode,
    transmission_physical_segments,
    loadPhysicalSegment,
    generationPhysicalSegment,
    marketSegments,
    tag_id,
  } = state.detail.present;
  const isDetailLoading: boolean =
    retrievingDetail !== ERetreiveState.NotRetrieving &&
    retrievingDetail !== ERetreiveState.RetrievingCompleted;
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;

  if (config === undefined) {
    throw new Error(`Missing config`);
  } else if (config.market_info_markets === undefined) {
    throw new Error(`Missing config.market_info_markets`);
  } else if (config.market_info_markets.length === 0) {
    throw new Error(`Missing market_info_markets markets`);
  }

  let hasSWPPOnMarketSegment = false;
  let hasMISOOnMarketSegment = false;

  if (tag_id && tag_id.lca) {
    hasSWPPOnMarketSegment =
      hasSWPPOnMarketSegment || tag_id.lca.entity_code === 'SWPP';
    hasMISOOnMarketSegment =
      hasMISOOnMarketSegment || tag_id.lca.entity_code === 'MISO';
  }

  if (tag_id && tag_id.gca) {
    hasSWPPOnMarketSegment =
      hasSWPPOnMarketSegment || tag_id.gca.entity_code === 'SWPP';
    hasMISOOnMarketSegment =
      hasMISOOnMarketSegment || tag_id.gca.entity_code === 'MISO';
  }

  if (generationPhysicalSegment && generationPhysicalSegment.mo_code) {
    hasSWPPOnMarketSegment =
      hasSWPPOnMarketSegment ||
      generationPhysicalSegment.mo_code.entity_code === 'SWPP';
    hasMISOOnMarketSegment =
      hasMISOOnMarketSegment ||
      generationPhysicalSegment.mo_code.entity_code === 'MISO';
  }

  if (marketSegments) {
    hasSWPPOnMarketSegment =
      hasSWPPOnMarketSegment ||
      marketSegments.findIndex(
        (segment: IDetailMarketSegment) => segment.pse?.entity_code === 'SWPP',
      ) > -1;
    hasMISOOnMarketSegment =
      hasMISOOnMarketSegment ||
      marketSegments.findIndex(
        (segment: IDetailMarketSegment) => segment.pse?.entity_code === 'MISO',
      ) > -1;
  }

  if (loadPhysicalSegment && loadPhysicalSegment.mo_code) {
    hasSWPPOnMarketSegment =
      hasSWPPOnMarketSegment ||
      loadPhysicalSegment.mo_code.entity_code === 'SWPP';
    hasMISOOnMarketSegment =
      hasMISOOnMarketSegment ||
      loadPhysicalSegment.mo_code.entity_code === 'MISO';
  }

  if (transmission_physical_segments) {
    hasSWPPOnMarketSegment =
      hasSWPPOnMarketSegment ||
      transmission_physical_segments.findIndex(
        (segment: IETagTransmissionPhysicalSegment) =>
          segment.tp_code?.entity_code === 'SWPP',
      ) > -1;
    hasMISOOnMarketSegment =
      hasMISOOnMarketSegment ||
      transmission_physical_segments.findIndex(
        (segment: IETagTransmissionPhysicalSegment) =>
          segment.tp_code?.entity_code === 'MISO',
      ) > -1;
  }

  if (transmission_physical_segments) {
    hasSWPPOnMarketSegment =
      hasSWPPOnMarketSegment ||
      transmission_physical_segments.findIndex(
        (segment: IETagTransmissionPhysicalSegment) =>
          segment.mo_code?.entity_code === 'SWPP',
      ) > -1;
    hasMISOOnMarketSegment =
      hasMISOOnMarketSegment ||
      transmission_physical_segments.findIndex(
        (segment: IETagTransmissionPhysicalSegment) =>
          segment.mo_code?.entity_code === 'MISO',
      ) > -1;
  }

  return {
    config,
    composite_state,
    isDetailLoading,
    isDetailUpdating,
    marketInfos,
    pageMode,
    start_date,
    viewMode,
    hasMISOOnMarketSegment,
    hasSWPPOnMarketSegment,
  };
};

const MarketInformationView = ({
  layoutGrid,
  resize,
  viewId,
}: IMarketInformationViewProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    config,
    composite_state,
    isDetailLoading,
    isDetailUpdating,
    marketInfos,
    pageMode,
    start_date,
    viewMode,
    hasMISOOnMarketSegment,
    hasSWPPOnMarketSegment,
  } = useSelector(retrieveMarketInformationViewState);
  const [selectedMarketDetail, setSelectedMarketDetail] = useState<
    IETagMarketDetail | undefined
  >(undefined);
  const [isMarketInfoChecked, setIsMarketInfoChecked] =
    useState<boolean>(false);
  const [selectedMarketDate, setSelectedMarketDate] =
    useState<ZonedDateTime | null>(null);

  const isEditable: boolean = useMemo(
    (): boolean => isMarketInfosEditable(viewMode),
    [viewMode],
  );

  const { adjustedMarketInfos, initialMarketInfos }: IMarketInfos =
    useMarketInfos(
      config!.market_info_markets!,
      marketInfos,
      isEditable,
      isDetailLoading,
      isDetailUpdating,
      pageMode,
      start_date,
    );

  useEffect(() => {
    if (!isDetailLoading && adjustedMarketInfos !== marketInfos) {
      dispatch(
        detailEditETagDetail({
          // We must include isDetailEdited: true in order to ensure that
          // things like save, undo, redo etc. recognises a change in the detail
          // state.
          isDetailEdited: true,
          isMarketInfosEdited: true,
          stateTransform: marketInfosToDetailState(adjustedMarketInfos),
        }),
      );
    }
  }, [adjustedMarketInfos, dispatch, isDetailLoading, marketInfos]);

  const marketDetailOptions: IOption<IETagMarketDetail>[] = useMemo(
    () =>
      isEditable
        ? getMarketDetailOptionsForEdit(config.market_info_markets!)
        : getMarketDetailOptionsForReview(adjustedMarketInfos),
    [adjustedMarketInfos, config, isEditable],
  );

  useEffect(() => {
    if (selectedMarketDetail === undefined && marketDetailOptions.length > 0) {
      const marketDetailOptionIndex: number =
        adjustedMarketInfos.length > 0
          ? marketDetailOptions.findIndex(
              (option: IOption<IETagMarketDetail>): boolean => {
                const enabledMarketInfo =
                  adjustedMarketInfos.find((info) => info.data.enabled) ||
                  adjustedMarketInfos[0];
                return (
                  option.value.market === enabledMarketInfo.market_info_market
                );
              },
            )
          : -1;
      if (marketDetailOptionIndex !== -1) {
        setSelectedMarketDetail(
          marketDetailOptions[marketDetailOptionIndex].value,
        );
      }
    }
  }, [adjustedMarketInfos, marketDetailOptions, selectedMarketDetail]);

  useEffect(() => {
    if (adjustedMarketInfos.length > 0) {
      if (!isDetailLoading && selectedMarketDetail !== undefined) {
        const { market, timeZone } = selectedMarketDetail;
        const marketInfo: IETagMarketInfo | undefined =
          adjustedMarketInfos.find(
            (marketInfo: IETagMarketInfo): boolean =>
              marketInfo.market_info_market === market,
          );

        if (marketInfo === undefined) {
          throw new Error(`Invalid market: ${market}`);
        } else {
          let dateTimeFormat: TFormatTemplate | undefined = undefined;
          let marketDateString: string | null | undefined = undefined;
          let marketDate: ZonedDateTime | undefined = undefined;
          let isInfoChecked: boolean | undefined = undefined;

          if (market === EMarketInfoMarket.MISO) {
            marketDateString = (marketInfo.data as IETagMisoMarketData)
              .miso_market_date;
            isInfoChecked = (marketInfo.data as IETagMisoMarketData).enabled;

            dateTimeFormat = MISO_MARKET_DETAIL.dateTimeFormat;
          } else if (market === EMarketInfoMarket.SPP) {
            marketDateString = (marketInfo.data as IETagSppMarketData)
              .spp_market_date;

            dateTimeFormat = SPP_MARKET_DETAIL.dateTimeFormat;
            isInfoChecked = (marketInfo.data as IETagSppMarketData).enabled;
          }

          if (!isEmptyValue(marketDateString)) {
            if (dateTimeFormat === undefined) {
              marketDate = ZonedDateTime.parseIso(marketDateString!, timeZone);
            } else {
              marketDate = ZonedDateTime.parse(
                marketDateString!,
                timeZone,
                dateTimeFormat,
              );
            }
          }
          if (isInfoChecked !== undefined) {
            setIsMarketInfoChecked(isInfoChecked);
          }

          if (marketDate === undefined && start_date !== null) {
            marketDate = ZonedDateTime.parseIso(start_date, timeZone);
          }

          if (marketDate !== undefined) {
            setSelectedMarketDate(marketDate);
          }
        }
      }
    } else {
      setSelectedMarketDate(null);
    }
  }, [
    adjustedMarketInfos,
    initialMarketInfos,
    isDetailLoading,
    selectedMarketDetail,
    start_date,
  ]);

  const handleMarketInformationChange = useCallback(
    (editMarketInfo: IEditMarketInfo) => {
      dispatch(
        detailEditETagDetail({
          // We must include isDetailEdited: true in order to ensure that
          // things like save, undo, redo etc. recognises a change in the detail
          // state.
          isDetailEdited: true,
          isMarketInfosEdited: true,
          stateTransform: editMarketInfoToDetailState(editMarketInfo),
        }),
      );
    },
    [dispatch],
  );

  const handleMarketDetailChange = useCallback(
    (eTagMarketDetail: IETagMarketDetail | undefined) => {
      if (eTagMarketDetail !== undefined) {
        const { market } = eTagMarketDetail;
        const marketInfo: IETagMarketInfo | undefined =
          adjustedMarketInfos.find(
            (marketInfo: IETagMarketInfo): boolean =>
              marketInfo.market_info_market === market,
          );

        if (marketInfo === undefined) {
          throw new Error(`Invalid market: ${market}`);
        } else {
          const { data } = marketInfo;
          let marketDateTimeString: string | null | undefined = undefined;
          let timeZone: TTimeZone | undefined = undefined;
          let dateTimeFormat: TFormatTemplate | undefined = undefined;

          if (market === EMarketInfoMarket.MISO) {
            const { miso_market_date } = data as IETagMisoMarketData;
            const { enabled } = data as IETagMisoMarketData;

            marketDateTimeString = miso_market_date;
            timeZone = MISO_MARKET_DETAIL.timeZone;
            dateTimeFormat = MISO_MARKET_DETAIL.dateTimeFormat;
            setIsMarketInfoChecked(enabled);
          } else if (market === EMarketInfoMarket.SPP) {
            const { spp_market_date } = data as IETagSppMarketData;
            const { enabled } = data as IETagSppMarketData;
            setIsMarketInfoChecked(enabled);
            marketDateTimeString = spp_market_date;
            timeZone = SPP_MARKET_DETAIL.timeZone;
            dateTimeFormat = SPP_MARKET_DETAIL.dateTimeFormat;
          }

          if (!isEmptyValue(marketDateTimeString) && timeZone !== undefined) {
            if (dateTimeFormat === undefined) {
              setSelectedMarketDate(
                ZonedDateTime.parseIso(marketDateTimeString!, timeZone),
              );
            } else {
              setSelectedMarketDate(
                ZonedDateTime.parse(
                  marketDateTimeString!,
                  timeZone,
                  dateTimeFormat,
                ),
              );
            }
          }
        }
      }

      setSelectedMarketDetail(eTagMarketDetail);
    },
    [adjustedMarketInfos],
  );

  const handleMarketDateChange = useCallback(
    (zonedDateTime: ZonedDateTime | null) => {
      if (selectedMarketDetail !== undefined && zonedDateTime !== null) {
        const { market } = selectedMarketDetail;
        const marketInfo: IETagMarketInfo | undefined =
          adjustedMarketInfos.find(
            (marketInfo: IETagMarketInfo): boolean =>
              marketInfo.market_info_market === market,
          );

        if (marketInfo === undefined) {
          throw new Error(`Invalid market: ${market}`);
        } else {
          const editMarketInfo: IEditMarketInfo = {
            data: {},
            market,
          };
          let hasMarketDataDateChanged: boolean = false;

          if (market === EMarketInfoMarket.MISO) {
            const { miso_market_date } = marketInfo.data as IETagMisoMarketData;
            const marketDate: ZonedDateTime = zonedDateTime.withTimeZone(
              'UTC',
              false,
            );
            const updatedMarketDate: string =
              MISO_MARKET_DETAIL.dateTimeFormat === undefined
                ? marketDate.isoFormat()
                : marketDate.format(MISO_MARKET_DETAIL.dateTimeFormat);

            if (miso_market_date !== updatedMarketDate) {
              (editMarketInfo.data as IEditMisoMarketData).miso_market_date =
                updatedMarketDate;

              hasMarketDataDateChanged = true;
            }
          } else if (market === EMarketInfoMarket.SPP) {
            const { spp_market_date } = marketInfo.data as IETagSppMarketData;
            const marketDate: ZonedDateTime = zonedDateTime.withTimeZone(
              'UTC',
              false,
            );
            const updatedMarketDate: string =
              SPP_MARKET_DETAIL.dateTimeFormat === undefined
                ? marketDate.isoFormat()
                : marketDate.format(SPP_MARKET_DETAIL.dateTimeFormat);

            if (spp_market_date !== updatedMarketDate) {
              (editMarketInfo.data as IEditSppMarketData).spp_market_date =
                updatedMarketDate;

              hasMarketDataDateChanged = true;
            }
          }

          setSelectedMarketDate(zonedDateTime);

          if (hasMarketDataDateChanged) {
            handleMarketInformationChange(editMarketInfo);
          }
        }
      }
    },
    [adjustedMarketInfos, handleMarketInformationChange, selectedMarketDetail],
  );
  const isValidSppMarketInfo = (sppData: IETagSppMarketData): boolean =>
    !(
      !sppData.spp_market_date ||
      !sppData.spp_market_type ||
      !sppData.spp_transaction_type
    );

  const isValidMisoMarketInfo = (misoData: IETagMisoMarketData): boolean =>
    !(
      misoData.miso_create_fin_schedule === undefined ||
      misoData.miso_create_fin_schedule === null ||
      !misoData.miso_market_type ||
      !misoData.miso_transaction_type ||
      !misoData.miso_market_date
    );

  const isValidPriceInfo = (priceInfo: IETagMarketInfoPrice): boolean => {
    if (
      (!priceInfo.price && !priceInfo.mw) ||
      (priceInfo.price && priceInfo.mw)
    ) {
      return true;
    } else if (
      (priceInfo.price && !priceInfo.mw) ||
      (!priceInfo.price && priceInfo.mw)
    ) {
      return false;
    }
    return false;
  };

  const validateMarketInfoData = useCallback(() => {
    const detailValidations: TDetailValidations = {};
    const validationMessages: IValidationMessage[] = [];
    if (selectedMarketDetail !== undefined) {
      const sppMarketInfo: IETagMarketInfo | undefined =
        adjustedMarketInfos.find(
          (marketInfo: IETagMarketInfo): boolean =>
            marketInfo.market_info_market === EMarketInfoMarket.SPP,
        );

      const misoMarketInfo: IETagMarketInfo | undefined =
        adjustedMarketInfos.find(
          (marketInfo: IETagMarketInfo): boolean =>
            marketInfo.market_info_market === EMarketInfoMarket.MISO,
        );
      let isMisoInvalid = false;
      let isSPPInvalid = false;
      let isMisoError = false;
      let isSPPError = false;
      let isMisoPriceError = false;
      let isSPPPriceError = false;

      if (misoMarketInfo) {
        isMisoInvalid =
          !misoMarketInfo.data.enabled &&
          isValidMisoMarketInfo(misoMarketInfo.data as IETagMisoMarketData);
        isMisoError =
          misoMarketInfo.data.enabled &&
          !isValidMisoMarketInfo(misoMarketInfo.data as IETagMisoMarketData);
      }
      if (sppMarketInfo) {
        isSPPInvalid =
          !sppMarketInfo.data.enabled &&
          isValidSppMarketInfo(sppMarketInfo.data as IETagSppMarketData);
        isSPPError =
          sppMarketInfo.data.enabled &&
          !isValidSppMarketInfo(sppMarketInfo.data as IETagSppMarketData);
      }
      if (isMisoInvalid || isSPPInvalid) {
        validationMessages.push({
          message: `${
            isMisoInvalid && isSPPInvalid
              ? 'MISO and SPP'
              : isMisoInvalid
              ? 'MISO'
              : 'SPP'
          } market info is complete but disabled`,
          severity: ESeverity.Warning,
        });
      }

      if (isSPPError || isMisoError) {
        validationMessages.push({
          message: `${
            isMisoError && isSPPError
              ? 'MISO and SPP'
              : isMisoError
              ? 'MISO'
              : 'SPP'
          } market info is enabled but incomplete`,
          severity: ESeverity.Error,
        });
      }
      if (!isSPPError && !isMisoError) {
        if (
          (misoMarketInfo?.data as IETagMisoMarketData)?.enabled &&
          (misoMarketInfo?.data as IETagMisoMarketData)?.miso_price_list
            .filter((f) => f !== undefined)
            .map((f) => isValidPriceInfo(f))
            .includes(false)
        ) {
          isMisoPriceError = true;
        }
        if (
          (sppMarketInfo?.data as IETagSppMarketData)?.enabled &&
          (sppMarketInfo?.data as IETagSppMarketData)?.spp_price_list
            .filter((f) => f !== undefined)
            .map((f) => isValidPriceInfo(f))
            .includes(false)
        ) {
          isSPPPriceError = true;
        }
      }
      if (isSPPPriceError || isMisoPriceError) {
        validationMessages.push({
          message: `${
            isSPPPriceError && isMisoPriceError
              ? 'MISO and SPP'
              : isMisoPriceError
              ? 'MISO'
              : 'SPP'
          } market info is enabled but Price Info is incomplete`,
          severity: ESeverity.Error,
        });
      }
    }
    detailValidations[getEditInfoKey(EDIT_MARKET_INFOS_LABEL, 0, 0)] = true;
    return {
      detailValidations: detailValidations,
      validationMessages: validationMessages,
    };
  }, [adjustedMarketInfos, selectedMarketDetail]);

  const handleIsMarketInfoEnabled = useCallback(
    (e: any) => {
      if (selectedMarketDetail !== undefined) {
        const { market } = selectedMarketDetail;
        const marketInfo: IETagMarketInfo | undefined =
          adjustedMarketInfos.find(
            (marketInfo: IETagMarketInfo): boolean =>
              marketInfo.market_info_market === market,
          );

        if (marketInfo === undefined) {
          throw new Error(`Invalid market: ${market}`);
        } else {
          const editMarketInfo: IEditMarketInfo = {
            data: {},
            market,
          };
          let hasInfoChanged: boolean = false;

          if (market === EMarketInfoMarket.MISO) {
            (editMarketInfo.data as IEditMisoMarketData).enabled = e;
            hasInfoChanged = true;
          } else if (market === EMarketInfoMarket.SPP) {
            (editMarketInfo.data as IEditSppMarketData).enabled = e;
            hasInfoChanged = true;
          }

          setIsMarketInfoChecked(hasInfoChanged);

          if (hasInfoChanged) {
            handleMarketInformationChange(editMarketInfo);
          }
        }
      }
    },
    [adjustedMarketInfos, handleMarketInformationChange, selectedMarketDetail],
  );

  const initialMarketDate: ZonedDateTime | null | undefined = useMemo(
    (): ZonedDateTime | null | undefined =>
      isEditable
        ? getInitialMarketDate(selectedMarketDetail?.market, initialMarketInfos)
        : undefined,
    [initialMarketInfos, isEditable, selectedMarketDetail],
  );

  const actions = useMemo(() => {
    const isDisabled: boolean = isDetailLoading || isDetailUpdating;
    return (
      <SeparatedRowLayout>
        <Tooltip
          isDisabled={!isEditable}
          title={
            isMarketInfoChecked
              ? 'Uncheck to Disable Market Information'
              : 'Check to Enable Market Information'
          }
        >
          <Checkbox
            isDisabled={!isEditable}
            checked={isMarketInfoChecked}
            onChange={handleIsMarketInfoEnabled}
          />
        </Tooltip>
        <MarketSelect
          isDisabled={isDisabled}
          onChange={handleMarketDetailChange}
          options={marketDetailOptions}
          placeholder='Select Market'
          value={selectedMarketDetail}
          valueToUid={marketDetailToUid}
        />
        {selectedMarketDetail === undefined ? null : isEditable ? (
          <MarketDatePicker
            format={DATE_FORMAT}
            initialValue={isDisabled ? undefined : initialMarketDate}
            isDisabled={isDisabled}
            onChange={handleMarketDateChange}
            placeholder=''
            timeZone={selectedMarketDetail.timeZone}
            value={selectedMarketDate}
          />
        ) : (
          <MarketDate>{selectedMarketDate?.format(DATE_FORMAT)}</MarketDate>
        )}
      </SeparatedRowLayout>
    );
  }, [
    handleIsMarketInfoEnabled,
    handleMarketDateChange,
    handleMarketDetailChange,
    initialMarketDate,
    isDetailLoading,
    isDetailUpdating,
    isEditable,
    isMarketInfoChecked,
    marketDetailOptions,
    selectedMarketDate,
    selectedMarketDetail,
  ]);

  const { detailValidations, validationMessages } = useMemo(() => {
    if (isDetailLoading) {
      return {
        detailValidations: undefined,
        validationMessages: undefined,
      };
    }
    const initialValidations = validateMarketInfos(
      hasMISOOnMarketSegment,
      hasSWPPOnMarketSegment,
    );
    const misoAndSppValidations = validateMarketInfoData();
    if (
      misoAndSppValidations &&
      misoAndSppValidations!.validationMessages.length > 0
    ) {
      initialValidations.detailValidations =
        misoAndSppValidations.detailValidations;
      initialValidations.validationMessages.push(
        ...misoAndSppValidations.validationMessages,
      );
    }
    return (
      initialValidations ?? {
        detailValidations: undefined,
        validationMessages: undefined,
      }
    );

    //return validateMarketInfos(hasMISOOnMarketSegment, hasSWPPOnMarketSegment);
  }, [
    hasMISOOnMarketSegment,
    hasSWPPOnMarketSegment,
    isDetailLoading,
    validateMarketInfoData,
  ]);

  useEffect(() => {
    if (detailValidations !== undefined) {
      dispatch(detailSetValidations({ detailValidations }));
    }
  }, [detailValidations, dispatch]);

  return (
    <>
      {composite_state === ECompositeState.Draft ||
      ((hasMISOOnMarketSegment || hasSWPPOnMarketSegment) &&
        composite_state !== ECompositeState.Pending) ? (
        <DetailView
          className={'market-information-view'}
          isLoading={isDetailLoading}
          layoutGrid={layoutGrid}
          leftActions={actions}
          resize={resize}
          title='Market Information'
          validationMessages={validationMessages}
          viewId={viewId}
          viewResizeSetting={EViewResize.Initial}
        >
          {selectedMarketDate !== null && selectedMarketDetail !== undefined ? (
            <MarketInformation
              initialMarketInfos={initialMarketInfos}
              isDisabled={isDetailUpdating || isDetailUpdating}
              isEditable={isEditable}
              isUnconstrained={false}
              marketDate={selectedMarketDate}
              marketDetail={selectedMarketDetail}
              marketInfos={adjustedMarketInfos}
              onChange={handleMarketInformationChange}
            />
          ) : (
            <></>
          )}
        </DetailView>
      ) : (
        <></>
      )}
    </>
  );
};

export default MarketInformationView;
