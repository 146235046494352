import { Tag as AntDesignTag } from 'antd';
import Button from 'components/atoms/Button/Button';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import DetailAdHocProfileSplit from 'components/organisms/DetailAdHocSplitProfile/DetailAdHocProfileSplit';
import ProfileDataGrid from 'components/organisms/ProfileDataGrid/ProfileDataGrid';
import DataGridContextMenu from 'components/organisms/ProfileInformationView/DataGridContextMenu';
import {
  adHocProfilesToDetailState,
  editProfileDataGridToDetailState,
  getInitialProfileChangeRow,
  getProfileChangeColumns,
  getRequestTypeOptions,
} from 'components/organisms/ProfileInformationView/ProfileChangeManager/helpers';
import SameEnergyProfilesCheckbox from 'components/organisms/ProfileInformationView/SameEnergyProfilesCheckbox/SameEnergyProfilesCheckbox';
import {
  IEditProfileDataGrid,
  IEditProfileDataGridEditedRowValues,
  IEditProfileDataGridEditedValues,
  IEditProfileDataGridRow,
  IProfileDateRange,
} from 'components/organisms/ProfileInformationView/types';
import DetectableOverflow from 'components/services/DetectableOverflow/DetectableOverflow';
import {
  INITIAL_RECORD_ID,
  PROFILE_CHANGE_ID_QUERY_PARAM,
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
} from 'constants/Detail';
import {
  INPUT_HEIGHT,
  PHYSICAL_SEGMENTS_PROFILES_HEADER_ROW_HEIGHT_VALUE,
  PROFILE_DATA_GRID_ROW_HEIGHT_VALUE,
  STANDARD_SPACING,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import {
  EProfileDataGridCellType,
  EProfileTimeSplit,
  EProfileTimeUnit,
} from 'enums/Detail';
import { ERequestType } from 'enums/ETag';
import { EViewMode } from 'enums/View';
import useInitialData from 'hooks/useInitialData';
import usePrevious from 'hooks/usePrevious';
import { IDataGridHandle, IOption } from 'interfaces/Component';
import { IAdHocProfile } from 'interfaces/Detail';
import {
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { IDateTimeValidation } from 'interfaces/General';
import { IToEntity } from 'interfaces/ToEntity';
import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DataGridHandle } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  detailEditETagDetail,
  detailSetSelectedRequestType,
} from 'reduxes/Detail/actions';
import { IDetailLoadPhysicalSegment } from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TRow } from 'types/Component';
import { TTimeZone } from 'types/DateTime';
import { TProfileDataGridColumn, TProfileDataGridRow } from 'types/Detail';
import { TRootState } from 'types/Redux';
import {
  getEditInfoKey,
  getGenerationMarketLevelKey,
  getLoadMarketLevelKey,
  getSplitEditInfoKey,
  isValidProfileDateTime,
} from 'utils/detail';
import { encodeIds, isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';
import DetailProfileDateRange from '../../DetailProfileDateRange/DetailProfileDateRange';
import { useLocation } from 'react-router-dom';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TitleBar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${STANDARD_SPACING};
  width: 100%;
`;

const RequestIndicator = styled(AntDesignTag)`
  height: ${INPUT_HEIGHT};
  padding: ${STANDARD_SPACING_VALUE / 2}px;
`;

const RequestTypeSelect = styled((props: ISelectProps<ERequestType>) =>
  Select<ERequestType>(props),
)`
  width: 201px;
`;

const ProfileDateSelect = styled((props: ISelectProps<ZonedDateTime>) =>
  Select<ZonedDateTime>(props),
)`
  width: 107px;
`;

const retrieveProfileChangeManagerState = (state: TRootState) => {
  const {
    end_date,
    isDetailEdited,
    profileChanges,
    selectedRequestType,
    start_date,
    useUniqueProfiles,
    viewMode,
  } = state.detail.present;

  return {
    end_date,
    isDetailEdited,
    profileChanges,
    selectedRequestType,
    start_date,
    useUniqueProfiles,
    viewMode,
  };
};

interface IProfileChangeManagerProps {
  encodedPermissionsId: string;
  generationPhysicalSegmentName: string;
  isViewFull: boolean;
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null;
  loadPhysicalSegmentName: string;
  profileDateOptions: IOption<ZonedDateTime>[];
  setHasDataGridOverflowedX: (hasDataGridOverflowedX: boolean) => void;
  setHasDataGridOverflowedY: (hasDataGridOverflowedY: boolean) => void;
  handleSelectedProfileChangeDateChange: (
    value: ZonedDateTime | undefined,
  ) => void;
  sortedTransmissionAllocations: IETagTransmissionAllocation[];
  timeZone: TTimeZone;
  toEntity: IToEntity | null;
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null;
  viewMode: EViewMode;
}

const ProfileChangeManager = ({
  encodedPermissionsId,
  generationPhysicalSegmentName,
  isViewFull,
  loadPhysicalSegment,
  loadPhysicalSegmentName,
  profileDateOptions,
  setHasDataGridOverflowedX,
  setHasDataGridOverflowedY,
  handleSelectedProfileChangeDateChange,
  sortedTransmissionAllocations,
  timeZone,
  toEntity,
  transmissionPhysicalSegments,
  viewMode,
}: IProfileChangeManagerProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    end_date,
    isDetailEdited,
    profileChanges,
    selectedRequestType,
    start_date,
    useUniqueProfiles,
  } = useSelector(retrieveProfileChangeManagerState);
  const { search } = useLocation();
  const profileDataGridRef =
    useRef<IDataGridHandle>() as RefObject<IDataGridHandle>;
  const dataGridRef = useRef<DataGridHandle>() as RefObject<DataGridHandle>;
  const previousIsDetailDeleted: boolean | undefined =
    usePrevious(isDetailEdited);
  const [usedDefaultProfiles, setUsedDefaultProfiles] =
    useState<boolean>(false);
  const [selectedProfileDate, setSelectedProfileDate] = useState<
    ZonedDateTime | undefined
  >();

  const [updatedProfileValuesByDate, setUpdatedProfileValuesByDate] = useState<
    IEditProfileDataGridEditedValues | undefined
  >(undefined);

  const [updatedGridRows, setUpdatedGridRows] = useState<
    IEditProfileDataGridRow[]
  >([]);

  const [firstChanges, setFirstChanges] = useState<IEditProfileDataGridRow[]>(
    [],
  );

  const [timeSplit, setTimeSplit] = useState<EProfileTimeSplit>(
    EProfileTimeSplit.Hourly,
  );

  const [isCopyProfileDisabled, setIsCopyProfileDisabled] =
    useState<boolean>(true);

  const requestTypeOptions: IOption<ERequestType>[] = useMemo(() => {
    const hideBtf: boolean = end_date // end date is before now, btf adjustment doesn't make sense
      ? ZonedDateTime.parseIso(end_date, timeZone).isBefore(
          ZonedDateTime.now(timeZone),
        )
      : false;

    const hideAtf: boolean = start_date // start date is after now, atf adjustment doesn't make sense
      ? ZonedDateTime.parseIso(start_date, timeZone).isAfter(
          ZonedDateTime.now(timeZone),
        )
      : false;

    return getRequestTypeOptions(
      encodeIds([encodedPermissionsId, 'requestTypeOptions']),
      toEntity,
      viewMode,
      hideAtf,
      hideBtf,
    );
  }, [
    encodedPermissionsId,
    end_date,
    start_date,
    timeZone,
    toEntity,
    viewMode,
  ]);

  const setSelectedRequestType = useCallback(
    (selectedRequestType: ERequestType | undefined, ignore?: boolean) => {
      setUsedDefaultProfiles(false);
      dispatch(detailSetSelectedRequestType({ ignore, selectedRequestType }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (requestTypeOptions.length === 1) {
      setSelectedRequestType(requestTypeOptions[0].value, true);
    }
  }, [requestTypeOptions, setSelectedRequestType]);

  const handleChange = useCallback(
    (editProfileDataGrid: IEditProfileDataGrid) => {
      dispatch(
        detailEditETagDetail({
          isDetailEdited: true,
          stateTransform: editProfileDataGridToDetailState(
            editProfileDataGrid,
            generationPhysicalSegmentName,
            loadPhysicalSegmentName,
            sortedTransmissionAllocations,
            transmissionPhysicalSegments,
            timeZone,
          ),
        }),
      );
    },
    [
      dispatch,
      generationPhysicalSegmentName,
      loadPhysicalSegmentName,
      sortedTransmissionAllocations,
      timeZone,
      transmissionPhysicalSegments,
    ],
  );

  const handleInsertAboveRow = useCallback(
    (profileDataGridRow: TProfileDataGridRow) => {
      handleChange({ addBeforeRow: profileDataGridRow });
    },
    [handleChange],
  );

  const handleInsertBelowRow = useCallback(
    (profileDataGridRow: TProfileDataGridRow) => {
      handleChange({ addAfterRow: profileDataGridRow });
    },
    [handleChange],
  );

  const { profileStart, profileStop } = useMemo((): {
    profileStart: string | undefined;
    profileStop: string | undefined;
  } => {
    if (selectedRequestType === undefined) {
      return { profileStart: undefined, profileStop: undefined };
    }

    const now: ZonedDateTime = ZonedDateTime.now(timeZone);
    if (start_date && end_date) {
      const startDate: ZonedDateTime = ZonedDateTime.parseIso(
        start_date,
        timeZone,
      );
      const endDate: ZonedDateTime = ZonedDateTime.parseIso(end_date, timeZone);

      if (
        selectedRequestType === ERequestType.AtfAdjustment ||
        selectedRequestType === ERequestType.BtfAdjustment
      ) {
        if (startDate.add(1, 'day').isAfter(endDate)) {
          // tag is less than 24 hours
          return {
            profileStart: startDate.isoFormat(),
            profileStop: endDate.isoFormat(),
          };
        }
        if (selectedProfileDate) {
          return {
            profileStart: selectedProfileDate.startOf('day').isoFormat(),
            profileStop: selectedProfileDate
              .startOf('day')
              .add(1, 'day')
              .isoFormat(),
          };
        }

        if (startDate.isAfter(now) || now.isAfter(endDate)) {
          // starts later than now or ends before now
          return {
            profileStart: startDate.isoFormat(),
            profileStop: endDate.isBefore(startDate.add(1, 'day'))
              ? endDate.isoFormat()
              : startDate.add(1, 'day').isoFormat(),
          };
        }

        if (
          now.isAfter(startDate) &&
          now.isBefore(endDate) // current time is within tag span
        ) {
          return {
            profileStart: now.startOf('day').isAfter(startDate)
              ? now.startOf('day').isoFormat()
              : startDate.isoFormat(),
            profileStop: (now.startOf('day').isAfter(startDate)
              ? now.startOf('day')
              : startDate
            )
              .add(24, 'hours')
              .isoFormat(),
          };
        }
      }
    }

    if (selectedRequestType === ERequestType.AtfAdjustment) {
      // default
      return {
        profileStart: now.startOf('hour').subtract(1, 'hour').isoFormat(),
        profileStop: now.startOf('hour').isoFormat(),
      };
    }

    if (selectedRequestType === ERequestType.BtfAdjustment) {
      // default
      return {
        profileStart: now.startOf('hour').add(1, 'hour').isoFormat(),
        profileStop: now.startOf('hour').add(2, 'hour').isoFormat(),
      };
    }
    return { profileStart: undefined, profileStop: undefined };
  }, [
    end_date,
    selectedProfileDate,
    selectedRequestType,
    start_date,
    timeZone,
  ]);

  const handleAdHocProfileSplitApply = useCallback(
    (adHocProfile: IAdHocProfile) => {
      dispatch(
        detailEditETagDetail({
          isDetailEdited: true,
          stateTransform: adHocProfilesToDetailState(
            adHocProfile,
            generationPhysicalSegmentName,
            loadPhysicalSegmentName,
            sortedTransmissionAllocations,
            transmissionPhysicalSegments,
            updatedProfileValuesByDate,
            setUpdatedGridRows,
          ),
        }),
      );
    },
    [
      dispatch,
      generationPhysicalSegmentName,
      loadPhysicalSegmentName,
      sortedTransmissionAllocations,
      transmissionPhysicalSegments,
      updatedProfileValuesByDate,
      setUpdatedGridRows,
    ],
  );

  useEffect(() => {
    // Only use default profiles if a profile change wasn't passed in via URL
    const query: URLSearchParams = new URLSearchParams(search);
    if (!query.get(PROFILE_CHANGE_ID_QUERY_PARAM)) {
      if (
        (profileChanges.length === 0 || !usedDefaultProfiles) &&
        profileStart &&
        profileStop
      ) {
        const pStart = ZonedDateTime.parseIso(profileStart, timeZone);
        const pStop = ZonedDateTime.parseIso(profileStop, timeZone);

        const baseParams = {
          customIntervals: [],
          fixedDuration: null,
          startDateTime: pStart,
          stopDateTime: pStop,
          timeUnit: EProfileTimeUnit.Minute,
          genValue: null,
        };

        let additionalParams = {};

        if (updatedProfileValuesByDate) {
          if (
            pStart.isSameOrAfter(updatedProfileValuesByDate.startDateTime) &&
            pStop.isSameOrBefore(updatedProfileValuesByDate.stopDateTime)
          ) {
            additionalParams = {
              timeSplit: timeSplit,
              fromDateChange: true,
            };
          } else {
            additionalParams = {
              timeSplit: EProfileTimeSplit.Hourly,
              skipEditedValues: true,
              fromDateChange: true,
            };
          }
        } else {
          additionalParams = {
            timeSplit: EProfileTimeSplit.Hourly,
            skipEditedValues: true,
          };
        }

        handleAdHocProfileSplitApply({
          ...baseParams,
          ...additionalParams,
        });

        setUsedDefaultProfiles(true);
      }
    }
  }, [
    profileStart,
    profileStop,
    profileChanges,
    handleAdHocProfileSplitApply,
    timeZone,
    usedDefaultProfiles,
    timeSplit,
    updatedProfileValuesByDate,
    updatedGridRows,
    search,
  ]);

  const rows: TProfileDataGridRow[] = useMemo(
    () =>
      profileChanges.length === 0
        ? [
            getInitialProfileChangeRow(
              INITIAL_RECORD_ID,
              getGenerationMarketLevelKey(generationPhysicalSegmentName),
              getLoadMarketLevelKey(loadPhysicalSegmentName),
              sortedTransmissionAllocations,
              transmissionPhysicalSegments,
              profileStart,
              profileStop,
            ),
          ]
        : profileChanges,
    [
      generationPhysicalSegmentName,
      loadPhysicalSegmentName,
      profileChanges,
      profileStart,
      profileStop,
      sortedTransmissionAllocations,
      transmissionPhysicalSegments,
    ],
  );

  const removeEditedRows = useCallback(
    (fromRow: number, toRow: number) => {
      const deletedRows = rows.slice(fromRow, toRow);
      let rowsToRemove: IEditProfileDataGridRow[] = [];
      deletedRows.forEach((row) => {
        const rowId = row['id'];
        if (rowId) {
          const idValue = rowId.value;
          const updatedRow = updatedGridRows.filter(
            (row) => row.updatedRow['id']?.value === idValue,
          );
          rowsToRemove = rowsToRemove.concat(updatedRow);
        }
      });
      const uniqueRows = updatedGridRows.filter(
        (row) => !rowsToRemove.includes(row),
      );
      setUpdatedGridRows(uniqueRows);
    },
    [rows, updatedGridRows],
  );

  const handleDeleteRowsAbove = useCallback(
    (profileDataGridRow: TProfileDataGridRow) => {
      const rowIndex = rows.findIndex(
        (row) => row['id']?.value === profileDataGridRow['id']?.value,
      );
      removeEditedRows(0, rowIndex);
      handleChange({ removeBeforeRow: profileDataGridRow });

      profileDataGridRef.current?.resetSelectionToFirstRow();
    },
    [handleChange, removeEditedRows, rows],
  );

  const handleDeleteRowsBelow = useCallback(
    (profileDataGridRow: TProfileDataGridRow) => {
      const rowIndex = rows.findIndex(
        (row) => row['id']?.value === profileDataGridRow['id']?.value,
      );
      removeEditedRows(rowIndex, rows.length - 1);
      handleChange({ removeAfterRow: profileDataGridRow });

      profileDataGridRef.current?.resetSelection();
    },
    [handleChange, removeEditedRows, rows],
  );

  const handleDeleteSelectedRows = useCallback(
    (fromRow: TRow, toRow: TRow) => {
      removeEditedRows(fromRow, toRow + 1);
      handleChange({ removeRows: { fromRow, toRow } });

      profileDataGridRef.current?.resetSelection();
    },
    [handleChange, removeEditedRows],
  );

  const addOneHour = useCallback(() => {
    if (
      rows[rows.length - 1].id &&
      rows[rows.length - 1].id?.type === EProfileDataGridCellType.String &&
      rows[rows.length - 1]['path-stop']?.type ===
        EProfileDataGridCellType.DateTimeString
    ) {
      const { label, primaryId, editIndex } = getSplitEditInfoKey(
        rows[rows.length - 1].id?.value as string,
      );
      const newRow: TProfileDataGridRow = {
        id: {
          type: EProfileDataGridCellType.String,
          value: getEditInfoKey(label, primaryId + 1, editIndex),
        },
        'path-start': rows[rows.length - 1]['path-stop'],
        'path-stop': {
          type: EProfileDataGridCellType.DateTimeString,
          value: ZonedDateTime.parseIso(
            rows[rows.length - 1]['path-stop']?.value as string,
            timeZone,
          )
            .add(1, 'hour')
            .toIsoString(),
        },
      };
      handleChange({ addExtraRows: [newRow] });
    }
  }, [handleChange, rows, timeZone]);

  const hasChanged = useCallback(
    (): boolean => previousIsDetailDeleted === true && isDetailEdited === false,
    [isDetailEdited, previousIsDetailDeleted],
  );

  const initialProfileChanges: TProfileDataGridRow[] | undefined =
    useInitialData(profileChanges, hasChanged);

  const startDisabledDate = useCallback(
    (dateTime: ZonedDateTime | null, isSelected?: boolean): boolean => {
      const { isValid } = isValidProfileDateTime(
        start_date,
        selectedRequestType,
        dateTime,
        isSelected,
      );

      return !isValid;
    },
    [selectedRequestType, start_date],
  );

  const startDateTimeInputValidator = useCallback(
    (dateTime: ZonedDateTime | null): IDateTimeValidation =>
      isValidProfileDateTime(start_date, selectedRequestType, dateTime, true),
    [selectedRequestType, start_date],
  );

  const columns: TProfileDataGridColumn[] = useMemo(
    () =>
      getProfileChangeColumns(
        selectedRequestType,
        generationPhysicalSegmentName,
        loadPhysicalSegment,
        loadPhysicalSegmentName,
        transmissionPhysicalSegments,
        sortedTransmissionAllocations,
        useUniqueProfiles,
        startDisabledDate,
        startDateTimeInputValidator,
        initialProfileChanges,
      ),
    [
      generationPhysicalSegmentName,
      initialProfileChanges,
      loadPhysicalSegment,
      loadPhysicalSegmentName,
      selectedRequestType,
      sortedTransmissionAllocations,
      startDisabledDate,
      startDateTimeInputValidator,
      transmissionPhysicalSegments,
      useUniqueProfiles,
    ],
  );

  const handleOnRowsChange = useCallback(
    (updatedRows: TProfileDataGridRow[], extraRows?: TProfileDataGridRow[]) => {
      if (updatedRows.length !== rows.length) {
        throw new Error(
          `Unexpected change in row lengths, rows: ${rows.length} updatedRows: ${updatedRows.length}`,
        );
      }

      const editProfileDataGridRows: IEditProfileDataGridRow[] = [];

      rows.forEach((row: TProfileDataGridRow, index: number) => {
        const updatedRow: TProfileDataGridRow = updatedRows[index];

        if (row !== updatedRow) {
          editProfileDataGridRows.push({
            isSameTransAllocProfile: false,
            row,
            updatedRow,
          });
        }
      });

      if (editProfileDataGridRows.length > 0 || extraRows !== undefined) {
        setIsCopyProfileDisabled(false);
        handleChange({
          addExtraRows: extraRows,
          editProfileDataGridRows,
        });
      }

      const arr: any[] = updatedGridRows;

      editProfileDataGridRows.forEach((editedRow) => {
        const existingRowIndex = updatedGridRows.findIndex(
          (row) =>
            row.updatedRow['path-start'] === editedRow.updatedRow['path-start'],
        );
        if (existingRowIndex === -1) {
          if (updatedGridRows.length === 0) {
            setUpdatedGridRows(editProfileDataGridRows);
          } else {
            setUpdatedGridRows(updatedGridRows.concat(editProfileDataGridRows));
          }
        } else {
          arr[existingRowIndex] = { updatedRow: editedRow.updatedRow };
          setUpdatedGridRows(arr);
        }
      });
    },
    [handleChange, rows, updatedGridRows],
  );
  const isDisabled: boolean = selectedRequestType === undefined;

  const dataGridElement: HTMLDivElement | undefined =
    !isEmptyValue(dataGridRef.current) && dataGridRef.current!.element !== null
      ? dataGridRef.current!.element
      : undefined;

  const isAddHourDisabled: boolean = !(
    profileStop &&
    profileStart &&
    ZonedDateTime.now(timeZone)
      .add(1, 'day')
      .isSameOrAfter(ZonedDateTime.parseIso(profileStop, timeZone)) &&
    ZonedDateTime.parseIso(profileStart, timeZone)
      .add(1, 'day')
      .isSameOrBefore(ZonedDateTime.parseIso(profileStop, timeZone))
  );

  const handleSelectedDateChange = useCallback(
    (date: ZonedDateTime | undefined) => {
      setUsedDefaultProfiles(false);
      setSelectedProfileDate(date);
      handleSelectedProfileChangeDateChange(date);
    },
    [handleSelectedProfileChangeDateChange],
  );

  useEffect(() => {
    if (updatedGridRows.length > 0) {
      setFirstChanges(updatedGridRows);
      const updatedProfileValuesByDateArray = updatedProfileValuesByDate;
      if (updatedProfileValuesByDateArray?.originalRows) {
        updatedProfileValuesByDateArray.originalRows = updatedGridRows;
        setUpdatedProfileValuesByDate(updatedProfileValuesByDateArray);
      }
    }
  }, [updatedGridRows, firstChanges, updatedProfileValuesByDate]);

  const handleProfileDateRangeApply = useCallback(
    (profiles: IProfileDateRange) => {
      if (profiles) {
        const updatedProfileValuesByDateArray: IEditProfileDataGridEditedRowValues[] =
          [];
        let minutesDiff = '60';
        let currentMinutes = 0;

        updatedGridRows.forEach((row) => {
          const startDate = row.updatedRow[PROFILE_PATH_START_KEY];
          const stopDate = row.updatedRow[PROFILE_PATH_STOP_KEY];
          if (startDate && stopDate) {
            const sDate = ZonedDateTime.parseIso(
              startDate.value.toString(),
              timeZone,
            );
            const eDate = ZonedDateTime.parseIso(
              stopDate.value.toString(),
              timeZone,
            );

            if (currentMinutes === 0) {
              currentMinutes = sDate.getMinute();
            }
            const t = ZonedDateTime.parseIso(
              stopDate.value.toString(),
              timeZone,
            );
            const t2 = ZonedDateTime.parseIso(
              startDate.value.toString(),
              timeZone,
            );
            minutesDiff = t.diff(t2, 'minutes').toString();
            const startHour = ZonedDateTime.parseIso(
              startDate.value.toString(),
              timeZone,
            ).getHour();
            const endHour = ZonedDateTime.parseIso(
              stopDate.value.toString(),
              timeZone,
            ).getHour();
            const startHourExists = updatedProfileValuesByDateArray.findIndex(
              (row) => row.startHour === startHour,
            );
            if (startHourExists === -1) {
              updatedProfileValuesByDateArray.push({
                startHour,
                endHour,
                editedRow: row,
                startDate: sDate,
                endDate: eDate,
              });
            }
          }
        });
        let currentTimeSplit: EProfileTimeSplit;
        switch (minutesDiff) {
          case '5':
            currentTimeSplit = EProfileTimeSplit.Five;
            break;
          case '15':
            currentTimeSplit = EProfileTimeSplit.Fifteen;
            break;
          case '60':
            currentTimeSplit = EProfileTimeSplit.Hourly;
            break;
          default:
            currentTimeSplit = EProfileTimeSplit.Hourly;
        }
        setTimeSplit(currentTimeSplit);
        setUpdatedProfileValuesByDate({
          startDateTime: (profiles.startDateTime as ZonedDateTime).startOf(
            'day',
          ),
          stopDateTime: (profiles.stopDateTime as ZonedDateTime)
            .startOf('day')
            .add(1, 'day'),
          carryOverValues: updatedProfileValuesByDateArray,
          originalRows: firstChanges,
        });
        handleAdHocProfileSplitApply({
          customIntervals: [],
          fixedDuration: 0,
          startDateTime: (profiles.startDateTime as ZonedDateTime)
            .startOf('day')
            .withMinute(currentMinutes),
          stopDateTime: (profiles.stopDateTime as ZonedDateTime)
            .startOf('day')
            .add(1, 'day'),
          timeSplit: currentTimeSplit,
          timeUnit: EProfileTimeUnit.Minute,
          genValue: null,
        });
        setSelectedProfileDate(undefined);
      } else {
        setUpdatedProfileValuesByDate(undefined);
      }
    },
    [updatedGridRows, firstChanges, timeZone, handleAdHocProfileSplitApply],
  );

  useEffect(() => {
    if (updatedProfileValuesByDate) {
      handleAdHocProfileSplitApply({
        customIntervals: [],
        fixedDuration: 0,
        startDateTime: updatedProfileValuesByDate.startDateTime,
        stopDateTime: updatedProfileValuesByDate.stopDateTime,
        timeSplit: timeSplit,
        timeUnit: EProfileTimeUnit.Minute,
        genValue: null,
      });
    }
  }, [updatedProfileValuesByDate, handleAdHocProfileSplitApply, timeSplit]);

  useEffect(() => {
    let date: ZonedDateTime | undefined = undefined;
    if (end_date) {
      const endDate = ZonedDateTime.fromDate(new Date(end_date), timeZone);
      // Setting start date of profile to start date of tag if end date of tag is in the past
      // If somehow we don't have a start date here, default to using the end date
      const startDate = ZonedDateTime.fromDate(
        new Date(start_date ?? end_date),
        timeZone,
      );
      if (endDate.isBefore(ZonedDateTime.now(timeZone))) {
        date = startDate;
      } else {
        if (start_date) {
          const startDate = ZonedDateTime.fromDate(
            new Date(start_date),
            timeZone,
          );
          if (startDate.isSameOrAfter(ZonedDateTime.now(timeZone))) {
            date = startDate;
          } else {
            date = ZonedDateTime.now(timeZone).startOf('day');
          }
        }
      }
      setUsedDefaultProfiles(false);
      setSelectedProfileDate(date);
      setTimeout(() => {
        handleSelectedProfileChangeDateChange(date);
      }, 100);
    }
  }, [
    end_date,
    start_date,
    timeZone,
    handleSelectedDateChange,
    setUsedDefaultProfiles,
    setSelectedProfileDate,
    handleSelectedProfileChangeDateChange,
  ]);

  return (
    <Layout>
      <TitleBar>
        {isViewFull ? null : (
          <RequestIndicator color='blue'>
            Profile Data Change Request
          </RequestIndicator>
        )}
        <SeparatedRowLayout>
          <DetailProfileDateRange
            timeZone={timeZone}
            onApply={handleProfileDateRangeApply}
            isDisabled={isCopyProfileDisabled}
            startDate={start_date}
            endDate={end_date}
          />
          <SameEnergyProfilesCheckbox isDisabled={isDisabled} />
          <DetailAdHocProfileSplit
            defaultStartDate={profileStart ?? ''}
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'detailAdHocProfileSplit',
            ])}
            isDisabled={isDisabled}
            onApply={handleAdHocProfileSplitApply}
            requestType={selectedRequestType}
            startDate={
              viewMode === EViewMode.EditETagAdjustment ||
              viewMode === EViewMode.EditETagAdjustmentWithATF
                ? start_date
                : undefined
            }
            timeZone={timeZone}
          />
          <Tooltip title='Populate Date'>
            <ProfileDateSelect
              allowClear={true}
              isDisabled={profileDateOptions.length === 1}
              onChange={handleSelectedDateChange}
              options={profileDateOptions}
              placeholder='Select Date'
              value={selectedProfileDate}
              valueToUid={(value: ZonedDateTime): string =>
                value.valueOf().toString()
              }
            />
          </Tooltip>
          <Button
            isDisabled={isAddHourDisabled}
            label='+1 Hour'
            onClick={addOneHour}
          />
          <Tooltip title='Profile Change Request Type Selection'>
            <RequestTypeSelect
              isDisabled={requestTypeOptions.length === 1}
              onChange={setSelectedRequestType}
              options={requestTypeOptions}
              placeholder='Select Change Request Type'
              value={selectedRequestType}
              valueToUid={(value: ERequestType): string => value as string}
            />
          </Tooltip>
        </SeparatedRowLayout>
      </TitleBar>
      <DetectableOverflow
        elementRef={dataGridElement}
        onOverflowedX={setHasDataGridOverflowedX}
        onOverflowedY={setHasDataGridOverflowedY}
      >
        <ProfileDataGrid
          columns={columns}
          dataGridRef={dataGridRef}
          headerRowHeight={PHYSICAL_SEGMENTS_PROFILES_HEADER_ROW_HEIGHT_VALUE}
          isDisabled={isDisabled}
          isEditable={!isDisabled}
          onRowsChange={handleOnRowsChange}
          ref={profileDataGridRef}
          rowHeight={PROFILE_DATA_GRID_ROW_HEIGHT_VALUE}
          rows={rows}
          timeZone={timeZone}
        />
      </DetectableOverflow>
      <DataGridContextMenu
        isDisabled={profileChanges.length === 0}
        onDeleteRowsAbove={handleDeleteRowsAbove}
        onDeleteRowsBelow={handleDeleteRowsBelow}
        onDeleteSelectedRows={handleDeleteSelectedRows}
        onInsertAboveRow={handleInsertAboveRow}
        onInsertBelowRow={handleInsertBelowRow}
      />
    </Layout>
  );
};

export default ProfileChangeManager;
