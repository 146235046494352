import { Card as AntDesignCard } from 'antd';
import { AxiosResponse } from 'axios';
import {
  ATF_OFFSET_HOURS,
  BTF_OFFSET_HOURS,
  CURRENT_PENDING_REQUEST_ID,
  CURRENT_PENDING_REQUEST_KEY,
  CURRENT_REQUEST_ID,
  CURRENT_REQUEST_KEY,
  EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
  EDIT_ETAG_TAG_ID_LABEL,
  EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
  EDIT_GENERATION_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
  EDIT_GENERATION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
  EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
  EDIT_LOAD_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
  EDIT_LOAD_PHYSICAL_SEGMENT_LABEL,
  EDIT_LOAD_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
  EDIT_LOSS_ACCOUNTING_LABEL,
  EDIT_LOSS_METHODS_CONTRACTS_PRIMARY_KEY,
  EDIT_LOSS_METHODS_ETAG_TAG_IDS_PRIMARY_KEY,
  EDIT_MARKET_SEGMENT_CONTACT_INFO_LABEL,
  EDIT_MARKET_SEGMENT_CONTRACTS_LABEL,
  EDIT_MARKET_SEGMENT_MISC_INFO_LABEL,
  EDIT_MARKET_SEGMENT_PSE_CONTACT_INFO_LABEL,
  EDIT_MISO_MARKET_DATA_LABEL,
  EDIT_SPP_MARKET_DATA_LABEL,
  EDIT_SUMMARY_INFORMATION_CONTACT_INFO_LABEL,
  EDIT_TRANSACTION_STATUSES_CONTACT_INFO_LABEL,
  EDIT_TRANSMISSION_ALLOCATION_LABEL,
  EDIT_TRANSMISSION_ALLOCATION_MISC_INFO_PRIMARY_KEY,
  EDIT_TRANSMISSION_PHYSICAL_SEGMENT_LABEL,
  EDIT_TRANSMISSION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
  INITIAL_RECORD_ID,
  TRANSMISSION_CONTRACT_NUMBER_SPECIAL_KEY,
  TRANSMISSION_KEY_REG_EXP,
  TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY,
  TRANSMISSION_POD_KEY_REG_EXP,
} from 'constants/Detail';
import { MISO_MARKET_DETAIL, SPP_MARKET_DETAIL } from 'constants/ETag';
import { ID_KEY } from 'constants/General';
import { FORBIDDEN_STATUS_CODE, NOT_FOUND_STATUS_CODE } from 'constants/misc';
import {
  STATE_GREEN,
  STATE_GREY,
  STATE_ORANGE,
  STATE_RED,
} from 'constants/styles';
import {
  EDayOfWeek,
  EDetailIdType,
  EMarketInfoPricesDataGridCellType,
  EProfileDataGridCellType,
  EProfileTimeSplit,
  EProfileTimeUnit,
} from 'enums/Detail';
import { EEntityType } from 'enums/Entity';
import {
  ECompositeState,
  EEditMode,
  EEnergyProfileType,
  ELossMethodEntryType,
  EMarketInfoMarket,
  ERequestType,
  ETransactionType,
} from 'enums/ETag';
import { EPointType } from 'enums/Point';
import { EViewMode } from 'enums/View';
import {
  IDailyProductProfile,
  ISingleDayProfileInterval,
  ISingleDayProfileTemplate,
} from 'interfaces/Config';
import { IInterval } from 'interfaces/DateTime';
import {
  IAdHocProfile,
  ICustomInterval,
  IDetailPageLocationOptions,
  IDetailRequest,
  ILocationDescriptor,
  IMarketInfoPricesDataGridCell,
  IMarketInfoPricesRowCellData,
  IProfileDataGridCell,
  IProfileInterval,
  IProfileTransmission,
} from 'interfaces/Detail';
import {
  IEntityInfo,
  IRegistryEntitiesResponse,
  IRegistryEntity,
} from 'interfaces/Entity';
import {
  IETagDefaultRange,
  IETagDraft,
  IETagDraftResponse,
  IETagDraftUpdateResponse,
  IETagEditModeResponse,
  IETagEnergyProfileDetail,
  IETagEnergyProfileSnapshot,
  IETagEnergyProfileSnapshotInterval,
  IETagEnergyProfileSnapshotsResponse,
  IETagGenerationPhysicalSegment,
  IETagLoadPhysicalSegment,
  IETagLossAccounting,
  IETagLossAccountingResponse,
  IETagLossMethod,
  IETagLossMethodEntry,
  IETagMarketData,
  IETagMarketInfo,
  IETagMarketInfoPrice,
  IETagMarketInfosResponse,
  IETagMarketSegment,
  IETagMarketSegmentResponse,
  IETagMisoMarketData,
  IETagPath,
  IETagPathEnergyProfile,
  IETagPathProfile,
  IETagPathTransmissionAllocationProfile,
  IETagPhysicalSegment,
  IETagPhysicalSegmentProfile,
  IETagPhysicalSegmentResponse,
  IETagPhysicalSegmentsProfile,
  IETagPhysicalSegmentsProfiles,
  IETagPhysicalSegmentsProfilesResponse,
  IETagSppMarketData,
  IETagSummaryAttributeResponse,
  IETagTagId,
  IETagTagMetaAttribute,
  IETagTemplate,
  IETagTemplateMessageMetaData,
  IETagTemplateResponse,
  IETagTemplateUpdateResponse,
  IETagTransactionStatus,
  IETagTransactionStatuses,
  IETagTransactionStatusesResponse,
  IETagTransmissionAllocation,
  IETagTransmissionAllocationProfile,
  IETagTransmissionAllocationProfileDetail,
  IETagTransmissionAllocationResponse,
  IETagTransmissionPhysicalSegment,
  IETagTransmissionPhysicalSegmentProfile,
  IEtagTransmissionPriorityConfiguration,
  IEtagTransmissionPriorityConfigurationResponse,
  IETagTransmissionSegment,
  IETagZonedDateTimeEnergyProfileDetail,
  IETagZonedDateTimeTransmissionAllocationProfileDetail,
} from 'interfaces/ETag';
import {
  IContactInfo,
  IContract,
  ICurtailment,
  ICurtailmentData,
  IDateTimeValidation,
  IEnergyProductInfo,
  IEnergyProfile,
  IMiscInfo,
  IPseContactInfo,
} from 'interfaces/General';
import { IPointInfo } from 'interfaces/Point';
import { IToEntity } from 'interfaces/ToEntity';
import { IViewDataTableColumn } from 'interfaces/View';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { ReactNode } from 'react';
import {
  IDetailEnergyProfileSnapshot,
  IDetailEnergyProfileSnapshotInterval,
  IDetailEnergyProfileSnapshots,
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
  IDetailLossAccounting,
  IDetailLossMethod,
  IDetailMarketSegment,
  IDetailState,
  IDetailTransactionStatus,
  IDetailTransactionStatuses,
} from 'reduxes/Detail/types';
import {
  retrieveETagMarketInfos,
  updateETagMarketInfos,
} from 'services/agent/marketInfos';
import {
  retrieveETagDistributedEditMode,
  retrieveETagDistributedEnergyProfileSnapshots,
  retrieveETagDistributedLossAccountings,
  retrieveETagDistributedMarketSegment,
  retrieveETagDistributedPhysicalSegment,
  retrieveETagDistributedPhysicalSegmentsProfiles,
  retrieveETagDistributedSummaryAttribute,
  retrieveETagDistributedTransactionStatuses,
  retrieveETagDistributedTransmissionAllocations,
} from 'services/agent/tags/distributed';
import { retrieveETagDraft, updateETagDraft } from 'services/agent/tags/drafts';
import {
  saveAndRequestNewTag,
  saveAndValidateNewTag,
} from 'services/agent/tags/requests';
import {
  retrieveETagTemplate,
  updateETagTemplate,
} from 'services/agent/templates';
import { retrieveRegistryEntities } from 'services/naesb-registry/registry';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import {
  TDetailPageLocationParameters,
  TDetailPageLocationUpdate,
  TDetailPrintPageLocationParameters,
  TDetailValidations,
  TMarketInfoPricesDataGridRow,
  TProfileDataGridRow,
} from 'types/Detail';
import {
  TETagDraftId,
  TETagMarketInfoMarketData,
  TETagTagPrimaryKey,
  TETagTemplateId,
} from 'types/ETag';
import { TMap, TStateLoadTransform, TStateTransform } from 'types/General';
import { TToEntityId } from 'types/ToEntity';
import { NotFoundError } from 'utils/error';
import {
  copyETagMisoMarketData,
  copyETagSppMarketData,
  copyETagTagId,
} from 'utils/eTag';
import { isEmptyValue, isSuccessStatus } from 'utils/general';
import { stringSortWithEmpties } from 'utils/sort';
import { checkToEntity } from 'utils/toEntity';
import { getColumnRender } from 'utils/views';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { retrieveTransmissionPrioritySegment } from '../services/agent/transmissionPrioritySegment';

const Card = styled(AntDesignCard)`
  margin-right: 1px;

  > .ant-card-head {
    > .ant-card-head-wrapper {
      > .ant-card-head-title {
        white-space: pre;
      }
    }
  }
`;

const EDIT_INFO_KEY_REGEXP = /(.+):(\d+):(\d+)/;

export const detailPrintPageLocationString = (
  linkOptions: TDetailPrintPageLocationParameters,
): string => {
  const queryString = Object.keys(linkOptions)
    .map((key: string) => {
      const value: string | undefined =
        linkOptions[key as keyof TDetailPrintPageLocationParameters];
      return value === undefined ? '' : `${key}=${value}`;
    })
    .join('&');
  return `/detail-print?${queryString}`;
};

export const detailPageLocationString = (
  linkOptions: TDetailPageLocationParameters,
): string => {
  const queryString = Object.keys(linkOptions)
    .map((key: string) => {
      const value: string | undefined =
        linkOptions[key as keyof TDetailPageLocationParameters];
      return value === undefined ? '' : `${key}=${value}`;
    })
    .join('&');
  return `/detail?${queryString}`;
};

export const updateDetailPageLocationDescriptor = (
  search: string,
  locationUpdates: TDetailPageLocationUpdate,
): ILocationDescriptor => {
  const query = new URLSearchParams(search);
  Object.keys(locationUpdates).forEach((key: string) => {
    const value = locationUpdates[key as keyof IDetailPageLocationOptions];
    if (value === null) {
      query.delete(key);
    } else if (value !== undefined) {
      query.set(key, value);
    }
  });
  return { pathname: '/detail', search: `?${query.toString()}` };
};

export const isAutoNavigateToTag = (
  urlSearchParams: URLSearchParams,
): boolean => {
  const autoNavigateToTag: string | null =
    urlSearchParams.get('autoNavigateToTag');
  return (
    autoNavigateToTag !== null &&
    autoNavigateToTag.toLowerCase().trim() === 'true'
  );
};

export const isValidProfileDateTime = (
  profileStartDate: string | null,
  requestType: ERequestType | undefined,
  dateTime: ZonedDateTime | null,
  isSelected?: boolean,
): IDateTimeValidation => {
  if (dateTime !== null) {
    const timeZone: TTimeZone = dateTime.timeZone();
    let adjustedDateTime: ZonedDateTime = dateTime;

    if (profileStartDate !== null) {
      const start: ZonedDateTime = ZonedDateTime.parseIso(
        profileStartDate,
        timeZone,
      );

      if (isSelected !== true) {
        adjustedDateTime = adjustedDateTime
          .withHour(start.getHour())
          .withMinute(start.getMinute());
      }

      if (adjustedDateTime.isBefore(start, 'minutes')) {
        return {
          errorMessage: 'Date time is before the start date of the profile',
          isValid: false,
        };
      }
    }

    const adjustmentOffsetHours: number | undefined =
      requestType === ERequestType.AtfAdjustment
        ? ATF_OFFSET_HOURS
        : requestType === ERequestType.BtfAdjustment
        ? BTF_OFFSET_HOURS
        : undefined;

    if (adjustmentOffsetHours !== undefined) {
      const now: ZonedDateTime = ZonedDateTime.now(timeZone);

      if (isSelected !== true) {
        adjustedDateTime = adjustedDateTime
          .withHour(now.getHour())
          .withMinute(now.getMinute());
      }

      adjustedDateTime = adjustedDateTime.add(adjustmentOffsetHours, 'hours');

      if (!now.isSameOrBefore(adjustedDateTime, 'minutes')) {
        return {
          errorMessage: `Date time cannot be more than ${adjustmentOffsetHours} hour(s) before now`,
          isValid: false,
        };
      }
    }
  }

  return {
    errorMessage: null,
    isValid: true,
  };
};

export const adjustmentDisabledDate =
  (profileStartDate: string | null, requestType: ERequestType | undefined) =>
  (dateTime: ZonedDateTime | null, isSelected?: boolean): boolean =>
    !isValidProfileDateTime(profileStartDate, requestType, dateTime, isSelected)
      .isValid;

export const stopDisabledDate = (
  start: string | null,
  dateTime: ZonedDateTime | null,
  isSelected?: boolean,
): boolean => {
  if (dateTime !== null) {
    if (isEmptyValue(start)) {
      return true;
    }

    const timeZone: TTimeZone = dateTime.timeZone();
    const startDateTime: ZonedDateTime = ZonedDateTime.parseIso(
      start!,
      timeZone,
    );
    let adjustedDateTime: ZonedDateTime = dateTime;

    if (isSelected !== true) {
      adjustedDateTime = adjustedDateTime
        .withHour(startDateTime.getHour())
        .withMinute(startDateTime.getMinute() + 1);

      if (
        !adjustedDateTime.isSame(dateTime, 'date') &&
        startDateTime.isSame(dateTime, 'date')
      ) {
        return true;
      }
    }

    return adjustedDateTime.isSameOrBefore(startDateTime, 'minute');
  }
  return false;
};

// NOTE: label can be another key allowing us to embed keys within keys
export const getEditInfoKey = (
  label: string,
  primaryId: number,
  editIndex: number,
): string => `${label}:${primaryId}:${editIndex}`;

export const getSplitEditInfoKey = (editInfoKey: string) => {
  // We expect all keys to have the format 'label:{primaryId}:{editIndex}', where
  // label could potentially be another key
  const matches: RegExpMatchArray | null =
    editInfoKey.match(EDIT_INFO_KEY_REGEXP);

  if (matches === null) {
    throw new Error(`Invalid editInfoKey: ${editInfoKey}`);
  }

  return {
    label: matches[1],
    primaryId: parseInt(matches[2], 10),
    editIndex: parseInt(matches[3], 10),
  };
};

export const getIsReviewingProfileDataGrid = (viewMode: EViewMode): boolean =>
  viewMode === EViewMode.ReviewETag ||
  viewMode === EViewMode.EditETagAdjustment ||
  viewMode === EViewMode.EditETagAdjustmentWithATF;

export const getGenerationPhysicalSegmentName = (
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
): string =>
  generationPhysicalSegment === null ||
  generationPhysicalSegment.generation_source === null
    ? ''
    : generationPhysicalSegment.generation_source.point_name;

export const getGenerationMarketLevelKey = (
  generationPhysicalSegmentName: string,
): string => `gen:${generationPhysicalSegmentName}:marketLevel`;

export const getLoadPhysicalSegmentName = (
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
): string =>
  loadPhysicalSegment === null || loadPhysicalSegment.load_sink === null
    ? ''
    : loadPhysicalSegment.load_sink.point_name;

export const getLoadMarketLevelKey = (
  loadPhysicalSegmentName: string,
): string => `load:${loadPhysicalSegmentName}:marketLevel`;

export const getKeyForProfileTransmission = ({
  transAllocId,
  physicalSegmentRef,
  transmissionName,
  adjustedContractNumber,
}: IProfileTransmission): string =>
  `t${transAllocId}:${
    physicalSegmentRef === null
      ? TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY
      : physicalSegmentRef
  }:${transmissionName}:${adjustedContractNumber}`;

export const getProfileTransmissionForKey = (
  profileTransmissionKey: string,
): IProfileTransmission | undefined => {
  const matches: RegExpMatchArray | null = profileTransmissionKey.match(
    TRANSMISSION_KEY_REG_EXP,
  );

  if (matches !== null) {
    return {
      adjustedContractNumber: matches[4],
      physicalSegmentRef:
        matches[2] === TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY
          ? null
          : parseInt(matches[2], 10),
      transAllocId: parseInt(matches[1], 10),
      transmissionName: matches[3],
    };
  }

  return undefined;
};

export const getPhysicalSegmentRefForTransmissionPodkey = (
  key: string,
): number | null | undefined => {
  const matches: RegExpMatchArray | null = key.match(
    TRANSMISSION_POD_KEY_REG_EXP,
  );

  if (matches !== null) {
    const physicalSegmentRefString: string = matches[2];

    return physicalSegmentRefString ===
      TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY
      ? null
      : parseInt(physicalSegmentRefString, 10);
  }

  return undefined;
};

export const getAdjustedContractNumber = (
  eTagTransmissionAllocation: IETagTransmissionAllocation,
): string =>
  eTagTransmissionAllocation.contract_number === null
    ? `${TRANSMISSION_CONTRACT_NUMBER_SPECIAL_KEY}${eTagTransmissionAllocation.trans_alloc_id}${TRANSMISSION_CONTRACT_NUMBER_SPECIAL_KEY}`
    : eTagTransmissionAllocation.contract_number;

export const getTransmissionName = (
  eTagTransmissionAllocation: IETagTransmissionAllocation,
  eTagTransmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
): string => {
  const foundDetailTransmissionPhysicalSegment:
    | IETagTransmissionPhysicalSegment
    | undefined =
    eTagTransmissionPhysicalSegments === null
      ? undefined
      : eTagTransmissionPhysicalSegments.find(
          (
            eTagTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
          ): boolean =>
            eTagTransmissionAllocation.physical_segment_ref ===
            eTagTransmissionPhysicalSegment.physical_segment_id,
        );

  return foundDetailTransmissionPhysicalSegment === undefined ||
    foundDetailTransmissionPhysicalSegment.pod === null
    ? ''
    : foundDetailTransmissionPhysicalSegment.pod.point_name;
};

export const transmissionAllocationSorter = (
  a: IETagTransmissionAllocation,
  b: IETagTransmissionAllocation,
): number => {
  if (a.physical_segment_ref === null && b.physical_segment_ref === null) {
    return 0;
  } else if (
    a.physical_segment_ref !== null &&
    b.physical_segment_ref === null
  ) {
    return -1;
  } else if (
    a.physical_segment_ref === null &&
    b.physical_segment_ref !== null
  ) {
    return 1;
  } else if (a.physical_segment_ref! < b.physical_segment_ref!) {
    return -1;
  } else if (a.physical_segment_ref! > b.physical_segment_ref!) {
    return 1;
  } else if (a.trans_alloc_id < b.trans_alloc_id) {
    return -1;
  } else if (a.trans_alloc_id > b.trans_alloc_id) {
    return 1;
  }

  return 0;
};

export const transmissionAllocationByIdSorter = (
  a: IETagTransmissionAllocation,
  b: IETagTransmissionAllocation,
): number => {
  if (a.trans_alloc_id < b.trans_alloc_id) {
    return -1;
  } else if (a.trans_alloc_id > b.trans_alloc_id) {
    return 1;
  }

  return 0;
};

export const copyContactInfo = (
  contactInfo: IContactInfo | null,
): IContactInfo | null => (contactInfo === null ? null : { ...contactInfo });

export const copyContract = (contract: IContract | null): IContract | null =>
  contract === null ? null : { ...contract };

export const copyEnergyProductInfo = (
  energyProductInfo: IEnergyProductInfo | null,
): IEnergyProductInfo | null => {
  if (energyProductInfo === null) {
    return null;
  }

  // A runtime check is necessary to catch unexpected null values
  if (energyProductInfo.product_ref === null) {
    throw new Error('Invalid energy product product_ref');
  }

  return { ...energyProductInfo };
};

export const copyEntityInfo = (
  entityInfo: IEntityInfo | null,
): IEntityInfo | null => (entityInfo === null ? null : { ...entityInfo });

export const copyPointInfo = (
  pointInfo: IPointInfo | null,
): IPointInfo | null => (pointInfo === null ? null : { ...pointInfo });

export const copyMiscInfo = (miscInfo: IMiscInfo | null): IMiscInfo | null =>
  miscInfo === null ? null : { ...miscInfo };

export const copyPseContactInfo = (
  pseContactInfo: IPseContactInfo | null,
): IPseContactInfo | null =>
  pseContactInfo === null ? null : { ...pseContactInfo };

export const copyEntityInfos = (
  entityInfos: IEntityInfo[] | null,
): IEntityInfo[] | null =>
  entityInfos === null
    ? null
    : entityInfos.map(
        (entityInfo: IEntityInfo): IEntityInfo => ({ ...entityInfo }),
      );

export const copyContactInfos = (
  contactInfos: IContactInfo[] | null,
): IContactInfo[] | null =>
  contactInfos === null
    ? null
    : contactInfos.map(
        (contactInfo: IContactInfo): IContactInfo => ({ ...contactInfo }),
      );

export const copyContracts = (
  contracts: IContract[] | null,
): IContract[] | null =>
  contracts === null
    ? null
    : contracts.map((contract: IContract): IContract => ({ ...contract }));

export const copyMiscInfos = (
  miscInfo: IMiscInfo[] | null,
): IMiscInfo[] | null =>
  miscInfo === null
    ? null
    : miscInfo.map((miscInfo: IMiscInfo): IMiscInfo => ({ ...miscInfo }));

export const copyTransmissionPhysicalSegment = (
  transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
): IETagTransmissionPhysicalSegment => ({
  energy_product_ref: copyEnergyProductInfo(
    transmissionPhysicalSegment.energy_product_ref,
  ),
  loss_methods:
    transmissionPhysicalSegment.loss_methods === null
      ? null
      : [...transmissionPhysicalSegment.loss_methods],
  market_segment_id: transmissionPhysicalSegment.market_segment_id,
  misc_infos: copyMiscInfos(transmissionPhysicalSegment.misc_infos),
  mo_code: copyEntityInfo(transmissionPhysicalSegment.mo_code),
  oasis_info:
    transmissionPhysicalSegment.oasis_info === null
      ? null
      : [...transmissionPhysicalSegment.oasis_info],
  physical_segment_id: transmissionPhysicalSegment.physical_segment_id,
  pod: copyPointInfo(transmissionPhysicalSegment.pod),
  pod_profile_ref: transmissionPhysicalSegment.pod_profile_ref,
  por: copyPointInfo(transmissionPhysicalSegment.por),
  por_profile_ref: transmissionPhysicalSegment.por_profile_ref,
  pse: copyEntityInfo(transmissionPhysicalSegment.pse),
  scheduling_entities:
    transmissionPhysicalSegment.scheduling_entities === null
      ? null
      : transmissionPhysicalSegment.scheduling_entities.map(
          (entityInfo: IEntityInfo): IEntityInfo => ({ ...entityInfo }),
        ),
  tp_code: copyEntityInfo(transmissionPhysicalSegment.tp_code),
  physical_segment_loss_percentage:
    transmissionPhysicalSegment.physical_segment_loss_percentage,
});

export const initialiseTransmissionPhysicalSegment = (
  transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
): IETagTransmissionPhysicalSegment => ({
  ...copyTransmissionPhysicalSegment(transmissionPhysicalSegment),
  misc_infos:
    transmissionPhysicalSegment.misc_infos === null
      ? null
      : transmissionPhysicalSegment.misc_infos.map(
          (miscInfo: IMiscInfo, index: number): IMiscInfo => ({
            ...miscInfo,
            key: getEditInfoKey(
              EDIT_TRANSMISSION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
              transmissionPhysicalSegment.physical_segment_id,
              index,
            ),
          }),
        ),
  pod_profile_ref: transmissionPhysicalSegment.pod_profile_ref,
  por_profile_ref: transmissionPhysicalSegment.por_profile_ref,
});

export const copyTransmissionPhysicalSegmentWithOverrides = (
  transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
  pse: IEntityInfo | null,
  schedulingEntities: IEntityInfo[],
  oasisInfo: string[],
  lossMethods: string[],
): IETagTransmissionPhysicalSegment => ({
  ...copyTransmissionPhysicalSegment(transmissionPhysicalSegment),
  loss_methods: lossMethods,
  oasis_info: oasisInfo,
  pse,
  scheduling_entities: schedulingEntities,
});

export const copyDetailMarketSegment = (
  detailMarketSegment: IDetailMarketSegment,
): IDetailMarketSegment => ({
  contactInfos: copyContactInfos(detailMarketSegment.contactInfos),
  contracts: copyContracts(detailMarketSegment.contracts),
  energy_product_ref: copyEnergyProductInfo(
    detailMarketSegment.energy_product_ref,
  ),
  market_segment_id: detailMarketSegment.market_segment_id,
  misc_infos: copyMiscInfos(detailMarketSegment.misc_infos),
  pse: copyEntityInfo(detailMarketSegment.pse),
  pse_contact_info: copyPseContactInfo(detailMarketSegment.pse_contact_info),
});

export const copyDetailGenerationPhysicalSegment = (
  detailGenerationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
): IDetailGenerationPhysicalSegment | null =>
  detailGenerationPhysicalSegment === null
    ? null
    : {
        contactInfos: copyContactInfos(
          detailGenerationPhysicalSegment.contactInfos,
        ),
        contracts: copyContracts(detailGenerationPhysicalSegment.contracts),
        energy_product_ref: copyEnergyProductInfo(
          detailGenerationPhysicalSegment.energy_product_ref,
        ),
        gca: copyEntityInfo(detailGenerationPhysicalSegment.gca),
        generation_source: copyPointInfo(
          detailGenerationPhysicalSegment.generation_source,
        ),
        market_segment_id: detailGenerationPhysicalSegment.market_segment_id,
        misc_infos: copyMiscInfos(detailGenerationPhysicalSegment.misc_infos),
        mo_code: copyEntityInfo(detailGenerationPhysicalSegment.mo_code),
        physical_segment_id:
          detailGenerationPhysicalSegment.physical_segment_id,
        profile_ref: detailGenerationPhysicalSegment.profile_ref,
        pse: copyEntityInfo(detailGenerationPhysicalSegment.pse),
      };

export const copyDetailGenerationPhysicalSegmentWithOverrides = (
  detailGenerationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  gca: IEntityInfo | null,
  pse: IEntityInfo | null,
): IDetailGenerationPhysicalSegment | null =>
  detailGenerationPhysicalSegment === null
    ? null
    : {
        ...copyDetailGenerationPhysicalSegment(
          detailGenerationPhysicalSegment,
        )!,
        gca,
        pse,
      };

export const copyDetailLoadPhysicalSegment = (
  detailLoadPhysicalSegment: IDetailLoadPhysicalSegment | null,
): IDetailLoadPhysicalSegment | null =>
  detailLoadPhysicalSegment === null
    ? null
    : {
        contactInfos: copyContactInfos(detailLoadPhysicalSegment.contactInfos),
        contracts: copyContracts(detailLoadPhysicalSegment.contracts),
        energy_product_ref: copyEnergyProductInfo(
          detailLoadPhysicalSegment.energy_product_ref,
        ),
        lca: copyEntityInfo(detailLoadPhysicalSegment.lca),
        load_sink: copyPointInfo(detailLoadPhysicalSegment.load_sink),
        market_segment_id: detailLoadPhysicalSegment.market_segment_id,
        misc_infos: copyMiscInfos(detailLoadPhysicalSegment.misc_infos),
        mo_code: copyEntityInfo(detailLoadPhysicalSegment.mo_code),
        physical_segment_id: detailLoadPhysicalSegment.physical_segment_id,
        profile_ref: detailLoadPhysicalSegment.profile_ref,
        pse: copyEntityInfo(detailLoadPhysicalSegment.pse),
      };

export const copyDetailLoadPhysicalSegmentWithOverrides = (
  detailLoadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  lca: IEntityInfo | null,
  pse: IEntityInfo | null,
): IDetailLoadPhysicalSegment | null =>
  detailLoadPhysicalSegment === null
    ? null
    : {
        ...copyDetailLoadPhysicalSegment(detailLoadPhysicalSegment)!,
        lca,
        pse,
      };

export const copyDetailLossMethod = (
  detailLossMethod: IDetailLossMethod | null,
): IDetailLossMethod | null =>
  detailLossMethod === null
    ? null
    : {
        contractNumbers:
          detailLossMethod.contractNumbers === null
            ? null
            : detailLossMethod.contractNumbers.map(
                (contract: IContract): IContract => ({ ...contract }),
              ),
        loss_method_entry_type: detailLossMethod.loss_method_entry_type,
        tag_ids:
          detailLossMethod.tag_ids === null
            ? null
            : detailLossMethod.tag_ids.map(copyETagTagId),
      };

export const copyDetailLossAccounting = (
  detailLossAccounting: IDetailLossAccounting,
): IDetailLossAccounting => ({
  key: detailLossAccounting.key,
  lossMethod: copyDetailLossMethod(detailLossAccounting.lossMethod),
  physical_segment_ref: detailLossAccounting.physical_segment_ref,
  request_ref: detailLossAccounting.request_ref,
  start: detailLossAccounting.start,
  stop: detailLossAccounting.stop,
});

export const copyTransmissionAllocation = (
  transmissionAllocation: IETagTransmissionAllocation,
): IETagTransmissionAllocation => ({
  contract_number: transmissionAllocation.contract_number,
  misc_infos: copyMiscInfos(transmissionAllocation.misc_infos),
  nits_resource: transmissionAllocation.nits_resource,
  physical_segment_ref: transmissionAllocation.physical_segment_ref,
  trans_alloc_customer_code: copyEntityInfo(
    transmissionAllocation.trans_alloc_customer_code,
  ),
  trans_alloc_id: transmissionAllocation.trans_alloc_id,
  trans_product_ref: copyEnergyProductInfo(
    transmissionAllocation.trans_product_ref,
  ),
});

export const getInitialETagTagId = (key: string): IETagTagId => ({
  gca: null,
  key,
  lca: null,
  pse: null,
  tag_code: null,
  tag_primary_key: null,
  ui_tag_id: null,
});

export const getInitialContactInfo = (key: string): IContactInfo => ({
  contact: null,
  fax: null,
  key,
  phone: null,
});

export const getInitialContract = (key: string): IContract => ({
  contract: null,
  key,
});

export const getInitialMarketSegment = (
  market_segment_id: number,
): IDetailMarketSegment => ({
  contactInfos: null,
  contracts: null,
  energy_product_ref: null,
  market_segment_id,
  misc_infos: null,
  pse: null,
  pse_contact_info: null,
});

export const getInitialMiscInfo = (key: string): IMiscInfo => ({
  key,
  token: null,
  value: null,
});

export const getInitialGenerationPhysicalSegment = (
  tag_id: IETagTagId | null,
): IDetailGenerationPhysicalSegment => ({
  contactInfos: null,
  contracts: null,
  energy_product_ref: null,
  gca: tag_id === null ? null : tag_id.gca,
  generation_source: null,
  market_segment_id: null,
  misc_infos: null,
  mo_code: null,
  physical_segment_id: 1,
  profile_ref: 1,
  pse: null,
});

export const isValidGenerationPhysicalSegment = (
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
): boolean =>
  generationPhysicalSegment !== null &&
  generationPhysicalSegment.market_segment_id !== null;

export const getPODProfileRef = (physicalSegmentId: number): number => {
  return physicalSegmentId > 1 ? 2 : 1;
};

export const getPORProfileRef = (physicalSegmentId: number): number => {
  return physicalSegmentId > 2 ? 2 : 1;
};

export const getTransmissionPhysicalSegmentId = (
  eTagTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment | null,
): number =>
  eTagTransmissionPhysicalSegment === null
    ? 2
    : eTagTransmissionPhysicalSegment.physical_segment_id;

export const getTransmissionPhysicalSegmentKey = (
  eTagTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment | null,
): string =>
  getEditInfoKey(
    EDIT_TRANSMISSION_PHYSICAL_SEGMENT_LABEL,
    getTransmissionPhysicalSegmentId(eTagTransmissionPhysicalSegment),
    0,
  );

// TODO: We going to need to explicitly pass in the profile_id since we can have more than 2 profiles now
export const getInitialTransmissionPhysicalSegment = (
  physical_segment_id: number,
): IETagTransmissionPhysicalSegment => ({
  energy_product_ref: null,
  loss_methods: null,
  market_segment_id: null,
  misc_infos: null,
  mo_code: null,
  oasis_info: null,
  physical_segment_id,
  pod: null,
  pod_profile_ref: getPODProfileRef(physical_segment_id),
  por: null,
  por_profile_ref: getPORProfileRef(physical_segment_id),
  pse: null,
  scheduling_entities: [],
  tp_code: null,
});

export const isValidTransmissionPhysicalSegment = (
  transmissionPhysicalSegment: IETagTransmissionPhysicalSegment | null,
): boolean =>
  transmissionPhysicalSegment !== null &&
  transmissionPhysicalSegment.market_segment_id !== null;

export const getLoadProfileRef = (physicalSegmentId: number): number => 2;
// We add 2 to account for physical segment ids being 1-indexed AND we
// always have a generation physical segment which has a physical segment
// id of 1. However, in the case when all transmission physical segments have
// been removed, there is still a placeholder transmission physical segment
// which will begin at 2, so the minimum value required here will need to 3.
export const getNextLoadPhysicalSegmentId = (
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
): number =>
  transmissionPhysicalSegments === null ||
  transmissionPhysicalSegments.length < 2
    ? 3
    : transmissionPhysicalSegments.length + 2;

export const getNextLoadPhysicalSegmentKey = (
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
): string =>
  getEditInfoKey(
    EDIT_LOAD_PHYSICAL_SEGMENT_LABEL,
    getNextLoadPhysicalSegmentId(transmissionPhysicalSegments),
    0,
  );

export const getInitialLoadPhysicalSegment = (
  physical_segment_id: number,
  tag_id: IETagTagId | null,
): IDetailLoadPhysicalSegment => ({
  contactInfos: null,
  contracts: null,
  energy_product_ref: null,
  lca: tag_id === null ? null : tag_id.lca,
  load_sink: null,
  market_segment_id: null,
  misc_infos: null,
  mo_code: null,
  physical_segment_id,
  profile_ref: getLoadProfileRef(physical_segment_id),
  pse: null,
});

export const isValidLoadPhysicalSegment = (
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
): boolean =>
  loadPhysicalSegment !== null &&
  loadPhysicalSegment.market_segment_id !== null;

export const getRequestKey = (request: IDetailRequest): string => {
  const { correction_id, request_id } = request;
  return request_id === CURRENT_REQUEST_ID
    ? CURRENT_REQUEST_KEY
    : request_id === CURRENT_PENDING_REQUEST_ID
    ? CURRENT_PENDING_REQUEST_KEY
    : `${request_id}${
        (request_id === 0 || request_id === 1) &&
        correction_id !== null &&
        correction_id > 0
          ? `-${correction_id}`
          : ''
      }`;
};

export const getRequestIdFromRequestKey = (requestKey: string): number => {
  if (requestKey === CURRENT_REQUEST_KEY) {
    return CURRENT_REQUEST_ID;
  }

  if (requestKey === CURRENT_PENDING_REQUEST_KEY) {
    return -1;
  }

  if (requestKey.startsWith('0')) {
    return 0;
  }

  const requestId: number = parseInt(requestKey, 10);

  if (isNaN(requestId)) {
    throw new Error(`Invalid requestKey: ${requestKey}`);
  }

  return requestId;
};

export const requestsSorter = (
  a: IDetailRequest,
  b: IDetailRequest,
): number => {
  if (a.request_id > b.request_id) {
    return -1;
  } else if (a.request_id < b.request_id) {
    return 1;
  }

  // We have the same request_id so check correction_id
  if (a.correction_id === null && b.correction_id === null) {
    return 0;
  } else if (a.correction_id === null) {
    return 1;
  } else if (b.correction_id === null) {
    return -1;
  }

  if (a.correction_id > b.correction_id) {
    return -1;
  } else if (a.correction_id < b.correction_id) {
    return 1;
  }

  return 0;
};

export const getPseFromMarketSegments = (
  marketSegmentId: number | null,
  marketSegments: IDetailMarketSegment[] | null,
): IEntityInfo | null => {
  const marketSegment: IDetailMarketSegment | undefined =
    marketSegments === null
      ? undefined
      : marketSegments.find(
          (detailMarketSegment: IDetailMarketSegment): boolean =>
            detailMarketSegment.market_segment_id === marketSegmentId,
        );

  return marketSegment === undefined ? null : marketSegment.pse;
};

export const getOasisInfosForPhysicalSegment = (
  physicalSegmentId: number,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
): IETagTransmissionAllocation[] =>
  transmissionAllocations === null
    ? []
    : transmissionAllocations.filter(
        (eTagTransmissionAllocation: IETagTransmissionAllocation): boolean =>
          eTagTransmissionAllocation.physical_segment_ref ===
            physicalSegmentId &&
          eTagTransmissionAllocation.contract_number !== null,
      );

export const getUniqueOasisInfoForPhysicalSegment = (
  physicalSegmentId: number,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
): string[] =>
  transmissionAllocations === null
    ? []
    : Array.from(
        new Set(
          transmissionAllocations
            .filter(
              (transmissionAllocation: IETagTransmissionAllocation): boolean =>
                transmissionAllocation.physical_segment_ref ===
                  physicalSegmentId &&
                transmissionAllocation.contract_number !== null,
            )
            .map(
              (transmissionAllocation: IETagTransmissionAllocation): string =>
                transmissionAllocation.contract_number!,
            ),
        ),
      );

export const getUniqueLossMethodsForPhysicalSegment = (
  physicalSegmentId: number,
  lossAccountings: IDetailLossAccounting[],
): string[] =>
  Array.from(
    new Set(
      lossAccountings
        .filter(
          (detailLossAccounting: IDetailLossAccounting): boolean =>
            detailLossAccounting.physical_segment_ref === physicalSegmentId &&
            detailLossAccounting.lossMethod !== null &&
            detailLossAccounting.lossMethod.loss_method_entry_type !== null,
        )
        .map(
          (detailLossAccounting: IDetailLossAccounting): string =>
            detailLossAccounting.lossMethod!.loss_method_entry_type!,
        ),
    ),
  );

export const getProfileMwForTransAllocId = (
  eTagTransmissionPhysicalSegmentProfile: IETagTransmissionPhysicalSegmentProfile | null,
  transAllocId: number,
  physical_segment_ref: number = 0,
  show_losses: boolean = false,
): number | null => {
  if (eTagTransmissionPhysicalSegmentProfile !== null) {
    const { transmission_segments } = eTagTransmissionPhysicalSegmentProfile;

    if (transmission_segments !== null) {
      let profileMw: number | null | undefined = undefined;
      transmission_segments.forEach(
        (eTagTransmissionSegment: IETagTransmissionSegment) => {
          const { trans_alloc_profiles } = eTagTransmissionSegment;

          if (
            physical_segment_ref > 0 &&
            show_losses &&
            eTagTransmissionSegment.pod_energy_profile &&
            eTagTransmissionSegment.pod_energy_profile.mw
          ) {
            if (
              eTagTransmissionSegment.physical_segment_id ===
              physical_segment_ref
            ) {
              profileMw = eTagTransmissionSegment.pod_energy_profile.mw;
            }
          } else {
            if (trans_alloc_profiles !== null) {
              trans_alloc_profiles.forEach(
                (
                  eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile,
                  index: number,
                ) => {
                  const { profile_mw, trans_alloc_id } =
                    eTagTransmissionAllocationProfile;
                  if (transAllocId === trans_alloc_id) {
                    if (profileMw === undefined) {
                      profileMw = profile_mw;
                    } else {
                      throw new Error(
                        `Multiple transmission allocation profiles for trans_alloc_id: ${transAllocId} with a profile_mw value: ${profile_mw} at index: ${index} for eTagTransmissionPhysicalSegmentProfile: ${JSON.stringify(
                          eTagTransmissionPhysicalSegmentProfile,
                        )}`,
                      );
                    }
                  }
                },
              );
            }
          }
        },
      );

      return profileMw === undefined ? null : profileMw;
    }
  }

  return null;
};

export const getBackgroundColourForLastRequestType = (
  last_request_type: ERequestType | null,
): string | undefined => {
  switch (last_request_type) {
    case ERequestType.Curtailment: {
      return STATE_RED;
    }
    case ERequestType.Termination: {
      return STATE_GREY;
    }
    case ERequestType.Reload: {
      return STATE_GREEN;
    }
    case ERequestType.AtfAdjustment:
    case ERequestType.BtfAdjustment: {
      return STATE_ORANGE;
    }
    default: {
      return undefined;
    }
  }
};

export const getBackgroundColourForEnergyProfile = (
  energyProfile: IEnergyProfile,
  selectedRequestKey: string,
): string | undefined => {
  if (
    selectedRequestKey === CURRENT_REQUEST_KEY ||
    selectedRequestKey === CURRENT_PENDING_REQUEST_KEY
  ) {
    if (energyProfile.reliability_limit === null) {
      if (
        energyProfile.reloaders !== null &&
        energyProfile.reloaders.length > 0
      ) {
        return STATE_GREEN;
      }
    } else {
      return STATE_RED;
    }
  } else {
    return getBackgroundColourForLastRequestType(
      energyProfile.last_request_type,
    );
  }

  return undefined;
};

export const getTransmissionSegmentFromPhysicalSegmentsProfile = (
  index: number,
  eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile,
  eTagTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
): IETagTransmissionSegment => {
  const eTagTransmissionSegment: IETagTransmissionSegment | undefined =
    eTagPhysicalSegmentsProfile.physical_segments_profiles === null ||
    eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission ===
      null ||
    eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission
      .transmission_segments === null
      ? undefined
      : eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission
          .transmission_segments[index];

  if (eTagTransmissionSegment === undefined) {
    throw new Error(`Missing eTagTransmissionSegment for index: ${index}`);
  }

  if (
    eTagTransmissionPhysicalSegment.physical_segment_id !==
    eTagTransmissionSegment.physical_segment_id
  ) {
    throw new Error(
      `Mismatching eTagTransmissionPhysicalSegment: ${eTagTransmissionPhysicalSegment.physical_segment_id} and eTagTransmissionSegment: ${eTagTransmissionSegment.physical_segment_id}`,
    );
  }

  return eTagTransmissionSegment;
};

export const getTransmissionAllocationTotal = (
  eTagTransmissionSegment: IETagTransmissionSegment | null,
): number | null => {
  if (eTagTransmissionSegment !== null) {
    const { trans_alloc_profiles } = eTagTransmissionSegment;

    if (trans_alloc_profiles !== null && trans_alloc_profiles.length > 0) {
      return trans_alloc_profiles
        .filter(
          (
            eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile,
          ): boolean => eTagTransmissionAllocationProfile.profile_mw !== null,
        )
        .map(
          (
            eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile,
          ): number => eTagTransmissionAllocationProfile.profile_mw!,
        )
        .reduce(
          (previousValue: number, currentValue: number): number =>
            previousValue + currentValue,
          0,
        );
    }
  }

  return null;
};

export const getTransmissionSegmentFromTransmissionPhysicalSegmentProfile = (
  physicalSegmentId: number | null,
  eTagTransmissionPhysicalSegmentProfile: IETagTransmissionPhysicalSegmentProfile | null,
): IETagTransmissionSegment | null => {
  if (
    physicalSegmentId !== null &&
    eTagTransmissionPhysicalSegmentProfile !== null &&
    eTagTransmissionPhysicalSegmentProfile.transmission_segments !== null
  ) {
    const eTagTransmissionSegment: IETagTransmissionSegment | undefined =
      eTagTransmissionPhysicalSegmentProfile.transmission_segments.find(
        (transmissionSegment: IETagTransmissionSegment): boolean =>
          transmissionSegment.physical_segment_id !== null &&
          transmissionSegment.physical_segment_id === physicalSegmentId,
      );

    if (eTagTransmissionSegment !== undefined) {
      return eTagTransmissionSegment;
    }
  }

  return null;
};

export const validateEditInfo = (
  editInfoKey: string,
  detailValidations: TDetailValidations,
): boolean => {
  let isValid: boolean = true;
  const {
    editIndex: editInfoKeyIndex,
    label: editInfoKeyLabel,
    primaryId: editInfoKeyPrimaryId,
  } = getSplitEditInfoKey(editInfoKey);

  for (const key in detailValidations) {
    const { editIndex, label, primaryId } = getSplitEditInfoKey(key);

    if (
      label.includes(editInfoKeyLabel) &&
      editInfoKeyPrimaryId === primaryId &&
      (editInfoKeyPrimaryId === undefined || editInfoKeyIndex === editIndex)
    ) {
      isValid = isValid && detailValidations[key];
    }
  }

  return isValid;
};

export const detailLossMethodToContractsString = (
  detailLossMethod: IDetailLossMethod | null,
): string =>
  detailLossMethod === null || detailLossMethod.contractNumbers === null
    ? ''
    : detailLossMethod.contractNumbers
        .filter((contract: IContract): boolean => contract.contract !== null)
        .map((contract: IContract): string => contract.contract!)
        .join(', ');

export const detailLossMethodToLossMethodString = (
  detailLossMethod: IDetailLossMethod | null,
): string =>
  detailLossMethod === null || detailLossMethod.loss_method_entry_type === null
    ? ''
    : detailLossMethod.loss_method_entry_type;

export const detailLossMethodToTagIdsString = (
  detailLossMethod: IDetailLossMethod | null,
): string =>
  detailLossMethod === null || detailLossMethod.tag_ids === null
    ? ''
    : detailLossMethod.tag_ids
        .filter((tagId: IETagTagId): boolean => tagId.ui_tag_id !== null)
        .map((tagId: IETagTagId): string => tagId.ui_tag_id!)
        .join(', ');

export const getInitialLossAccountingKey = (
  primaryId?: number,
  index?: number,
): string =>
  getEditInfoKey(
    EDIT_LOSS_ACCOUNTING_LABEL,
    primaryId || INITIAL_RECORD_ID,
    index || INITIAL_RECORD_ID,
  );

export const getInitialLossAccounting = (
  key: string,
  physical_segment_ref?: number | null,
): IDetailLossAccounting => ({
  key,
  lossMethod: null,
  physical_segment_ref: physical_segment_ref || null,
  request_ref: null,
  start: null,
  stop: null,
});

export const isValidLossAccounting = (
  lossAccounting: IDetailLossAccounting | null,
): boolean =>
  lossAccounting !== null && lossAccounting.physical_segment_ref !== null;

export const getInitialLossMethod = (): IDetailLossMethod => ({
  contractNumbers: null,
  loss_method_entry_type: null,
  tag_ids: null,
});

export const getTransmissionAllocationKey = (
  transmissionAllocation: IETagTransmissionAllocation,
): string =>
  getEditInfoKey(
    EDIT_TRANSMISSION_ALLOCATION_LABEL,
    transmissionAllocation.trans_alloc_id,
    0,
  );

export const getInitialTransmissionAllocation = (
  trans_alloc_id: number,
): IETagTransmissionAllocation => ({
  contract_number: null,
  misc_infos: null,
  nits_resource: null,
  physical_segment_ref: null,
  trans_alloc_customer_code: null,
  trans_alloc_id,
  trans_product_ref: null,
});

export const isValidTransmissionAllocation = (
  transmissionAllocation: IETagTransmissionAllocation | null,
): boolean =>
  transmissionAllocation !== null &&
  transmissionAllocation.physical_segment_ref !== null;

export const getInitialEnergyProfile = (
  profile_id: number,
): IEnergyProfile => ({
  adjusted: null,
  curtailments: [],
  last_request_id: null,
  last_request_type: null,
  market_level: null,
  mw: null,
  profile_id,
  reliability_limit: null,
  reloaders: [],
  start_ramp_dur: null,
  stop_ramp_dur: null,
});

export const getInitialGenerationPhysicalSegmentProfile = (
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
): IETagPhysicalSegmentProfile => ({
  // Generation physical segment always has id 1
  physical_segment_id: 1,
  // Generation physical segment always takes the first profile
  profile: getInitialEnergyProfile(1),
  source_sink:
    generationPhysicalSegment === null
      ? null
      : copyPointInfo(generationPhysicalSegment.generation_source),
});

export const getInitialLoadPhysicalSegmentProfile = (
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
): IETagPhysicalSegmentProfile => {
  const physical_segment_id: number = getNextLoadPhysicalSegmentId(
    transmissionPhysicalSegments,
  );

  const profile =
    transmissionPhysicalSegments &&
    transmissionPhysicalSegments.length > 0 &&
    transmissionPhysicalSegments[transmissionPhysicalSegments?.length - 1]
      .pod_profile_ref
      ? transmissionPhysicalSegments[transmissionPhysicalSegments?.length - 1]
          .pod_profile_ref
      : 0;

  return {
    physical_segment_id,
    profile: getInitialEnergyProfile(profile || 0),
    source_sink:
      loadPhysicalSegment === null
        ? null
        : copyPointInfo(loadPhysicalSegment.load_sink),
  };
};

export const getInitialTransmissionSegment = (
  physicalSegmentId: number,
  pod?: IPointInfo | null,
  podEnergyProfile?: IEnergyProfile | null,
  por?: IPointInfo | null,
  porEnergyProfile?: IEnergyProfile | null,
  tpCode?: IEntityInfo | null,
  transAllocProfiles?: IETagTransmissionAllocationProfile[] | null,
): IETagTransmissionSegment => ({
  physical_segment_id: physicalSegmentId,
  pod: pod === undefined ? null : pod,
  pod_energy_profile:
    podEnergyProfile === undefined
      ? getInitialEnergyProfile(getPODProfileRef(physicalSegmentId))
      : podEnergyProfile,
  por: por === undefined ? null : por,
  por_energy_profile:
    porEnergyProfile === undefined
      ? getInitialEnergyProfile(getPORProfileRef(physicalSegmentId))
      : porEnergyProfile,
  tp_code: tpCode === undefined ? null : tpCode,
  transmission_limit: null,
  trans_alloc_profiles:
    transAllocProfiles === undefined ? null : transAllocProfiles,
});

export const getInitialTransmissionSegments = (
  transmission_physical_segments: IETagTransmissionPhysicalSegment[] | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
): IETagTransmissionSegment[] | null => {
  if (transmission_physical_segments !== null) {
    const transmissionSegments: IETagTransmissionSegment[] = [];

    transmission_physical_segments.forEach(
      (transmissionPhysicalSegment: IETagTransmissionPhysicalSegment) => {
        const { physical_segment_id, pod, por, tp_code } =
          transmissionPhysicalSegment;
        let trans_alloc_profiles: IETagTransmissionAllocationProfile[] | null =
          null;

        if (transmissionAllocations !== null) {
          trans_alloc_profiles = [];

          transmissionAllocations.forEach(
            (transmissionAllocation: IETagTransmissionAllocation) => {
              const { contract_number, physical_segment_ref, trans_alloc_id } =
                transmissionAllocation;

              if (physical_segment_id === physical_segment_ref) {
                trans_alloc_profiles!.push({
                  contract_number,
                  last_request_type: null,
                  profile_mw: null,
                  trans_alloc_id,
                });
              }
            },
          );
        }

        transmissionSegments.push({
          physical_segment_id,
          pod: copyPointInfo(pod),
          pod_energy_profile: getInitialEnergyProfile(
            transmissionPhysicalSegment?.pod_profile_ref || 1,
          ),
          por: copyPointInfo(por),
          por_energy_profile: getInitialEnergyProfile(
            transmissionPhysicalSegment?.por_profile_ref || 1,
          ),
          tp_code: copyEntityInfo(tp_code),
          transmission_limit: null,
          trans_alloc_profiles,
        });
      },
    );

    return transmissionSegments;
  }

  return null;
};

export const getInitialTransmissionPhysicalSegmentProfile = (
  transmission_physical_segments: IETagTransmissionPhysicalSegment[] | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
): IETagTransmissionPhysicalSegmentProfile => ({
  transmission_limit: null,
  transmission_segments: getInitialTransmissionSegments(
    transmission_physical_segments,
    transmissionAllocations,
  ),
});

export const getInitialPhysicalSegmentsProfiles = (
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
): IETagPhysicalSegmentsProfiles => ({
  generation: getInitialGenerationPhysicalSegmentProfile(
    generationPhysicalSegment,
  ),
  load: getInitialLoadPhysicalSegmentProfile(
    loadPhysicalSegment,
    transmissionPhysicalSegments,
  ),
  transmission: getInitialTransmissionPhysicalSegmentProfile(
    transmissionPhysicalSegments,
    transmissionAllocations,
  ),
});

export const getInitialPhysicalSegmentsProfile = (
  key: string,
  start: string | null,
  stop: string | null,
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
): IETagPhysicalSegmentsProfile => ({
  key,
  physical_segments_profiles: getInitialPhysicalSegmentsProfiles(
    generationPhysicalSegment,
    loadPhysicalSegment,
    transmissionPhysicalSegments,
    transmissionAllocations,
  ),
  start,
  stop,
});

export const copyCurtailment = (curtailment: ICurtailment): ICurtailment => ({
  entity: copyEntityInfo(curtailment.entity)!,
  mw: curtailment.mw,
});

export const copyEnergyProfile = (
  energyProfile: IEnergyProfile | null,
): IEnergyProfile | null =>
  energyProfile === null
    ? null
    : {
        ...energyProfile,
        curtailments: energyProfile.curtailments.map(copyCurtailment),
        reloaders: copyEntityInfos(energyProfile.reloaders)!,
      };

const copyRegistryEntity = (
  registryEntity: IRegistryEntity,
): IRegistryEntity => ({
  ...registryEntity,
});

export const retrieveAndTransformRegistryEntities =
  (toEntity: TToEntityId): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const response: AxiosResponse<IRegistryEntitiesResponse> =
      await retrieveRegistryEntities(toEntity);
    const registryEntitiesResponse: IRegistryEntitiesResponse = response.data;

    if (!isSuccessStatus(response.status)) {
      throw new Error(registryEntitiesResponse.errorMessage!);
    }

    const registryEntities: IRegistryEntity[] =
      registryEntitiesResponse.response;

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      registryEntities: registryEntities.map(copyRegistryEntity),
    });
  };

export const retrieveAndTransformDistributedTagSummary =
  (
    toEntity: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const response: AxiosResponse<IETagSummaryAttributeResponse> =
      await retrieveETagDistributedSummaryAttribute(toEntity, tagPrimaryKey);
    const eTagSummaryAttributeResponse: IETagSummaryAttributeResponse =
      response.data;

    if (!isSuccessStatus(response.status)) {
      if (response.status === NOT_FOUND_STATUS_CODE) {
        throw new NotFoundError(
          `Distributed Tag Summary Not Found: ${eTagSummaryAttributeResponse.errorMessage}`,
        );
      }

      throw new Error(eTagSummaryAttributeResponse.errorMessage!);
    }

    const {
      approval_right,
      approved_termination_time,
      cc_list,
      composite_state,
      contact,
      end_date,
      fax,
      notes,
      phone,
      start_date,
      tag_id,
      test_flag,
      transaction_type,
    } = eTagSummaryAttributeResponse.response;

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      approval_right,
      cc_list:
        cc_list === null
          ? null
          : cc_list.map(
              (entityInfo: IEntityInfo): IEntityInfo => ({ ...entityInfo }),
            ),
      composite_state,
      contact_info: {
        contact,
        fax,
        key: getEditInfoKey(EDIT_SUMMARY_INFORMATION_CONTACT_INFO_LABEL, 0, 0),
        phone,
      },
      end_date,
      notes,
      start_date,
      tag_id: {
        gca: tag_id.gca === null ? null : { ...tag_id.gca },
        key: getEditInfoKey(EDIT_ETAG_TAG_ID_LABEL, 0, 0),
        lca: tag_id.lca === null ? null : { ...tag_id.lca },
        pse: tag_id.pse === null ? null : { ...tag_id.pse },
        tag_code: tag_id.tag_code,
        tag_primary_key: tag_id.tag_primary_key,
        ui_tag_id: tag_id.ui_tag_id,
      },
      approved_termination_time,
      test_flag,
      transaction_type,
    });
  };

const transformETagMarketSegment = (
  marketSegment: IETagMarketSegment,
): IDetailMarketSegment => ({
  contactInfos:
    marketSegment.contact_info === null
      ? null
      : [
          {
            ...marketSegment.contact_info,
            key: getEditInfoKey(
              EDIT_MARKET_SEGMENT_CONTACT_INFO_LABEL,
              marketSegment.market_segment_id,
              0,
            ),
          },
        ],
  contracts:
    marketSegment.contracts === null
      ? null
      : marketSegment.contracts.map(
          (contract: string, index: number): IContract => ({
            contract,
            key: getEditInfoKey(
              EDIT_MARKET_SEGMENT_CONTRACTS_LABEL,
              marketSegment.market_segment_id,
              index,
            ),
          }),
        ),
  energy_product_ref: copyEnergyProductInfo(marketSegment.energy_product_ref),
  market_segment_id: marketSegment.market_segment_id,
  misc_infos:
    marketSegment.misc_infos === null
      ? null
      : marketSegment.misc_infos.map(
          (miscInfo: IMiscInfo, index: number): IMiscInfo => ({
            ...miscInfo,
            key: getEditInfoKey(
              EDIT_MARKET_SEGMENT_MISC_INFO_LABEL,
              marketSegment.market_segment_id,
              index,
            ),
          }),
        ),
  pse: marketSegment.pse === null ? null : { ...marketSegment.pse },
  pse_contact_info:
    marketSegment.pse_contact_info === null
      ? null
      : {
          ...marketSegment.pse_contact_info,
          key: getEditInfoKey(
            EDIT_MARKET_SEGMENT_PSE_CONTACT_INFO_LABEL,
            marketSegment.market_segment_id,
            0,
          ),
        },
});

export const retrieveAndTransformDistributedTagMarketSegment =
  (
    toEntity: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const response: AxiosResponse<IETagMarketSegmentResponse> =
      await retrieveETagDistributedMarketSegment(toEntity, tagPrimaryKey, '*');
    const eTagMarketSegmentResponse: IETagMarketSegmentResponse = response.data;

    if (!isSuccessStatus(response.status)) {
      if (response.status === NOT_FOUND_STATUS_CODE) {
        throw new NotFoundError(
          `Distributed Tag Market Segment Not Found: ${eTagMarketSegmentResponse.errorMessage}`,
        );
      }

      throw new Error(eTagMarketSegmentResponse.errorMessage!);
    }

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      marketSegments:
        eTagMarketSegmentResponse.response === null
          ? null
          : eTagMarketSegmentResponse.response.map(transformETagMarketSegment),
    });
  };

const transformGenerationPhysicalSegment = (
  generationPhysicalSegment: IETagGenerationPhysicalSegment | null,
): IDetailGenerationPhysicalSegment | null =>
  generationPhysicalSegment === null
    ? null
    : {
        contactInfos:
          generationPhysicalSegment.contact_info === null
            ? null
            : [
                {
                  ...generationPhysicalSegment.contact_info,
                  key: getEditInfoKey(
                    EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
                    generationPhysicalSegment.physical_segment_id,
                    0,
                  ),
                },
              ],
        contracts:
          generationPhysicalSegment.contracts === null
            ? null
            : generationPhysicalSegment.contracts.map(
                (contract: string, index: number): IContract => ({
                  contract,
                  key: getEditInfoKey(
                    EDIT_GENERATION_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
                    generationPhysicalSegment.physical_segment_id,
                    index,
                  ),
                }),
              ),
        energy_product_ref: copyEnergyProductInfo(
          generationPhysicalSegment.energy_product_ref,
        ),
        gca:
          generationPhysicalSegment.gca === null
            ? null
            : { ...generationPhysicalSegment.gca },
        generation_source:
          generationPhysicalSegment.generation_source === null
            ? null
            : { ...generationPhysicalSegment.generation_source },
        market_segment_id: generationPhysicalSegment.market_segment_id,
        misc_infos:
          generationPhysicalSegment.misc_infos === null
            ? null
            : generationPhysicalSegment.misc_infos.map(
                (miscInfo: IMiscInfo, index: number): IMiscInfo => ({
                  ...miscInfo,
                  key: getEditInfoKey(
                    EDIT_GENERATION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
                    generationPhysicalSegment.physical_segment_id,
                    index,
                  ),
                }),
              ),
        mo_code:
          generationPhysicalSegment.mo_code === null
            ? null
            : { ...generationPhysicalSegment.mo_code },
        physical_segment_id: generationPhysicalSegment.physical_segment_id,
        profile_ref: generationPhysicalSegment.profile_ref,
        pse:
          generationPhysicalSegment.pse === null
            ? null
            : { ...generationPhysicalSegment.pse },
      };

export const transformLoadPhysicalSegment = (
  loadPhysicalSegment: IETagLoadPhysicalSegment | null,
): IDetailLoadPhysicalSegment | null =>
  loadPhysicalSegment === null
    ? null
    : {
        contactInfos:
          loadPhysicalSegment.contact_info === null
            ? null
            : [
                {
                  ...loadPhysicalSegment.contact_info,
                  key: getEditInfoKey(
                    EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
                    loadPhysicalSegment.physical_segment_id,
                    0,
                  ),
                },
              ],
        contracts:
          loadPhysicalSegment.contracts === null
            ? null
            : loadPhysicalSegment.contracts.map(
                (contract: string, index: number): IContract => ({
                  contract,
                  key: getEditInfoKey(
                    EDIT_LOAD_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
                    loadPhysicalSegment.physical_segment_id,
                    index,
                  ),
                }),
              ),
        energy_product_ref: copyEnergyProductInfo(
          loadPhysicalSegment.energy_product_ref,
        ),
        lca:
          loadPhysicalSegment.lca === null
            ? null
            : { ...loadPhysicalSegment.lca },
        load_sink:
          loadPhysicalSegment.load_sink === null
            ? null
            : { ...loadPhysicalSegment.load_sink },
        market_segment_id: loadPhysicalSegment.market_segment_id,
        misc_infos:
          loadPhysicalSegment.misc_infos === null
            ? null
            : loadPhysicalSegment.misc_infos.map(
                (miscInfo: IMiscInfo, index: number): IMiscInfo => ({
                  ...miscInfo,
                  key: getEditInfoKey(
                    EDIT_LOAD_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
                    loadPhysicalSegment.physical_segment_id,
                    index,
                  ),
                }),
              ),
        mo_code:
          loadPhysicalSegment.mo_code === null
            ? null
            : { ...loadPhysicalSegment.mo_code },
        physical_segment_id: loadPhysicalSegment.physical_segment_id,
        profile_ref: loadPhysicalSegment.profile_ref,
        pse:
          loadPhysicalSegment.pse === null
            ? null
            : { ...loadPhysicalSegment.pse },
      };

export const retrieveAndTransformDistributedTagPhysicalSegment =
  (
    toEntity: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const response: AxiosResponse<IETagPhysicalSegmentResponse> =
      await retrieveETagDistributedPhysicalSegment(toEntity, tagPrimaryKey);
    const eTagPhysicalSegmentResponse: IETagPhysicalSegmentResponse =
      response.data;

    if (!isSuccessStatus(response.status)) {
      if (response.status === NOT_FOUND_STATUS_CODE) {
        throw new NotFoundError(
          `Distributed Tag Physical Segment Not Found: ${eTagPhysicalSegmentResponse.errorMessage}`,
        );
      }

      throw new Error(eTagPhysicalSegmentResponse.errorMessage!);
    }

    const eTagGenerationPhysicalSegment: IETagGenerationPhysicalSegment | null =
      eTagPhysicalSegmentResponse.response.generation_physical_segment;
    const eTagLoadPhysicalSegment: IETagLoadPhysicalSegment | null =
      eTagPhysicalSegmentResponse.response.load_physical_segment;
    const eTagTransmissionPhysicalSegments:
      | IETagTransmissionPhysicalSegment[]
      | null =
      eTagPhysicalSegmentResponse.response.transmission_physical_segments;

    const generationPhysicalSegment: IDetailGenerationPhysicalSegment | null =
      transformGenerationPhysicalSegment(eTagGenerationPhysicalSegment);

    const loadPhysicalSegment: IDetailLoadPhysicalSegment | null =
      transformLoadPhysicalSegment(eTagLoadPhysicalSegment);

    const transmission_physical_segments:
      | IETagTransmissionPhysicalSegment[]
      | null =
      eTagTransmissionPhysicalSegments === null
        ? null
        : eTagTransmissionPhysicalSegments.map(
            initialiseTransmissionPhysicalSegment,
          );

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      generationPhysicalSegment,
      loadPhysicalSegment,
      transmission_physical_segments,
    });
  };

const transformLossMethodToDetailLossMethod = (
  lossMethod: IETagLossMethod,
  baseKey: string,
): IDetailLossMethod => ({
  contractNumbers:
    lossMethod.contract_numbers === null
      ? null
      : lossMethod.contract_numbers.map(
          (contract: string, index: number): IContract => ({
            contract,
            key: getEditInfoKey(
              baseKey,
              EDIT_LOSS_METHODS_CONTRACTS_PRIMARY_KEY,
              index,
            ),
          }),
        ),
  loss_method_entry_type: lossMethod.loss_method_entry_type,
  tag_ids:
    lossMethod.tag_ids === null
      ? null
      : lossMethod.tag_ids.map(
          (eTagTagId: IETagTagId, index: number): IETagTagId => ({
            ...copyETagTagId(eTagTagId),
            key: getEditInfoKey(
              baseKey,
              EDIT_LOSS_METHODS_ETAG_TAG_IDS_PRIMARY_KEY,
              index,
            ),
          }),
        ),
});

const transformETagLossAccountingsToDetailLossAccountings = (
  eTagLossAccountings: IETagLossAccounting[],
): IDetailLossAccounting[] => {
  const detailLossAccountings: IDetailLossAccounting[] = [];
  let index: number = 0;

  eTagLossAccountings.forEach((eTagLossAccounting: IETagLossAccounting) => {
    const physical_segment_ref: number | null =
      eTagLossAccounting.physical_segment_ref;
    const primaryId: number =
      eTagLossAccounting.physical_segment_ref === null
        ? 0
        : eTagLossAccounting.physical_segment_ref;
    if (
      eTagLossAccounting.loss_method_entries === null ||
      eTagLossAccounting.loss_method_entries.length === 0
    ) {
      detailLossAccountings.push({
        key: getEditInfoKey(EDIT_LOSS_ACCOUNTING_LABEL, primaryId, index),
        lossMethod: null,
        physical_segment_ref,
        request_ref: null,
        start: null,
        stop: null,
      });

      index += 1;
    } else {
      eTagLossAccounting.loss_method_entries.forEach(
        (eTagLossMethodEntry: IETagLossMethodEntry) => {
          const { loss_method, request_ref, start, stop } = eTagLossMethodEntry;
          const detailLossAccountingKey: string = getEditInfoKey(
            EDIT_LOSS_ACCOUNTING_LABEL,
            primaryId,
            index,
          );

          detailLossAccountings.push({
            key: detailLossAccountingKey,
            lossMethod:
              loss_method === null
                ? null
                : transformLossMethodToDetailLossMethod(
                    loss_method,
                    detailLossAccountingKey,
                  ),
            physical_segment_ref,
            request_ref,
            start,
            stop,
          });

          index += 1;
        },
      );
    }
  });

  return detailLossAccountings;
};

export const retrieveAndTransformDistributedTagLossAccountings =
  (
    toEntity: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const response: AxiosResponse<IETagLossAccountingResponse> =
      await retrieveETagDistributedLossAccountings(toEntity, tagPrimaryKey);
    const eTagLossAccountingResponse: IETagLossAccountingResponse =
      response.data;

    if (!isSuccessStatus(response.status)) {
      if (response.status === NOT_FOUND_STATUS_CODE) {
        throw new NotFoundError(
          `Distributed Tag Loss Accountings Not Found: ${eTagLossAccountingResponse.errorMessage}`,
        );
      }

      throw new Error(eTagLossAccountingResponse.errorMessage!);
    }

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      lossAccountings: transformETagLossAccountingsToDetailLossAccountings(
        eTagLossAccountingResponse.response,
      ),
    });
  };

export const retrieveAndTransformDistributedTagTransmissionAllocations =
  (
    toEntity: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const response: AxiosResponse<IETagTransmissionAllocationResponse> =
      await retrieveETagDistributedTransmissionAllocations(
        toEntity,
        tagPrimaryKey,
      );
    const eTagTransmissionAllocationResponse: IETagTransmissionAllocationResponse =
      response.data;

    if (!isSuccessStatus(response.status)) {
      if (response.status === NOT_FOUND_STATUS_CODE) {
        throw new NotFoundError(
          `Distributed Tag Transmission Allocations Not Found: ${eTagTransmissionAllocationResponse.errorMessage}`,
        );
      }

      throw new Error(eTagTransmissionAllocationResponse.errorMessage!);
    }

    // We have to filter out transmission allocations that have the same
    // physical_segment_ref and contract_number, since these are treated as the
    // same on the frontend.
    const transmissionAllocations: IETagTransmissionAllocation[] = [];
    const transmissionKeySet = new Set<string>();

    eTagTransmissionAllocationResponse.response.forEach(
      (eTagTransmissionAllocation: IETagTransmissionAllocation) => {
        const { contract_number, physical_segment_ref } =
          eTagTransmissionAllocation;
        const transmissionKey: string = `${physical_segment_ref}:${contract_number}`;

        if (!transmissionKeySet.has(transmissionKey)) {
          transmissionKeySet.add(transmissionKey);

          transmissionAllocations.push({
            contract_number,
            misc_infos:
              eTagTransmissionAllocation.misc_infos === null
                ? null
                : eTagTransmissionAllocation.misc_infos.map(
                    (
                      miscInfo: IMiscInfo,
                      miscInfoIndex: number,
                    ): IMiscInfo => ({
                      ...miscInfo,
                      key: getEditInfoKey(
                        getTransmissionAllocationKey(
                          eTagTransmissionAllocation,
                        ),
                        EDIT_TRANSMISSION_ALLOCATION_MISC_INFO_PRIMARY_KEY,
                        miscInfoIndex,
                      ),
                    }),
                  ),
            nits_resource: eTagTransmissionAllocation.nits_resource,
            physical_segment_ref,
            trans_alloc_customer_code:
              eTagTransmissionAllocation.trans_alloc_customer_code === null
                ? null
                : {
                    ...eTagTransmissionAllocation.trans_alloc_customer_code,
                  },
            trans_alloc_id: eTagTransmissionAllocation.trans_alloc_id,
            trans_product_ref:
              eTagTransmissionAllocation.trans_product_ref === null
                ? null
                : {
                    ...eTagTransmissionAllocation.trans_product_ref,
                  },
          });
        }
      },
    );

    transmissionAllocations.sort(
      (
        a: IETagTransmissionAllocation,
        b: IETagTransmissionAllocation,
      ): number => a.trans_alloc_id - b.trans_alloc_id,
    );

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      transmissionAllocations,
    });
  };

export const retrieveAndTransformDistributedTagAllTransmissionAllocations =
  (
    toEntity: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const response: AxiosResponse<IETagTransmissionAllocationResponse> =
      await retrieveETagDistributedTransmissionAllocations(
        toEntity,
        tagPrimaryKey,
        true,
      );
    const eTagTransmissionAllocationResponse: IETagTransmissionAllocationResponse =
      response.data;

    if (!isSuccessStatus(response.status)) {
      if (response.status === NOT_FOUND_STATUS_CODE) {
        throw new NotFoundError(
          `Distributed Tag Transmission Allocations Not Found: ${eTagTransmissionAllocationResponse.errorMessage}`,
        );
      }

      throw new Error(eTagTransmissionAllocationResponse.errorMessage!);
    }

    // We have to filter out transmission allocations that have the same
    // physical_segment_ref and contract_number, since these are treated as the
    // same on the frontend.
    const allTransmissionAllocations: IETagTransmissionAllocation[] = [];
    const allUnfilteredTransmissionAllocations: IETagTransmissionAllocation[] =
      [];
    let transmissionKeySet = new Set<string>();

    eTagTransmissionAllocationResponse.response.forEach(
      (eTagTransmissionAllocation: IETagTransmissionAllocation) => {
        const { contract_number, physical_segment_ref } =
          eTagTransmissionAllocation;
        const transmissionKey: string = `${physical_segment_ref}:${contract_number}`;

        if (!transmissionKeySet.has(transmissionKey)) {
          transmissionKeySet.add(transmissionKey);

          allTransmissionAllocations.push({
            contract_number,
            misc_infos:
              eTagTransmissionAllocation.misc_infos === null
                ? null
                : eTagTransmissionAllocation.misc_infos.map(
                    (
                      miscInfo: IMiscInfo,
                      miscInfoIndex: number,
                    ): IMiscInfo => ({
                      ...miscInfo,
                      key: getEditInfoKey(
                        getTransmissionAllocationKey(
                          eTagTransmissionAllocation,
                        ),
                        EDIT_TRANSMISSION_ALLOCATION_MISC_INFO_PRIMARY_KEY,
                        miscInfoIndex,
                      ),
                    }),
                  ),
            nits_resource: eTagTransmissionAllocation.nits_resource,
            physical_segment_ref,
            trans_alloc_customer_code:
              eTagTransmissionAllocation.trans_alloc_customer_code === null
                ? null
                : {
                    ...eTagTransmissionAllocation.trans_alloc_customer_code,
                  },
            trans_alloc_id: eTagTransmissionAllocation.trans_alloc_id,
            trans_product_ref:
              eTagTransmissionAllocation.trans_product_ref === null
                ? null
                : {
                    ...eTagTransmissionAllocation.trans_product_ref,
                  },
          });
        }
      },
    );

    transmissionKeySet = new Set<string>();

    eTagTransmissionAllocationResponse.response.forEach(
      (eTagTransmissionAllocation: IETagTransmissionAllocation) => {
        const { contract_number, physical_segment_ref } =
          eTagTransmissionAllocation;
        const transmissionKey: string = `${physical_segment_ref}:${contract_number}`;

        transmissionKeySet.add(transmissionKey);

        allUnfilteredTransmissionAllocations.push({
          contract_number,
          misc_infos:
            eTagTransmissionAllocation.misc_infos === null
              ? null
              : eTagTransmissionAllocation.misc_infos.map(
                  (miscInfo: IMiscInfo, miscInfoIndex: number): IMiscInfo => ({
                    ...miscInfo,
                    key: getEditInfoKey(
                      getTransmissionAllocationKey(eTagTransmissionAllocation),
                      EDIT_TRANSMISSION_ALLOCATION_MISC_INFO_PRIMARY_KEY,
                      miscInfoIndex,
                    ),
                  }),
                ),
          nits_resource: eTagTransmissionAllocation.nits_resource,
          physical_segment_ref,
          trans_alloc_customer_code:
            eTagTransmissionAllocation.trans_alloc_customer_code === null
              ? null
              : {
                  ...eTagTransmissionAllocation.trans_alloc_customer_code,
                },
          trans_alloc_id: eTagTransmissionAllocation.trans_alloc_id,
          trans_product_ref:
            eTagTransmissionAllocation.trans_product_ref === null
              ? null
              : {
                  ...eTagTransmissionAllocation.trans_product_ref,
                },
        });
      },
    );

    allTransmissionAllocations.sort(
      (
        a: IETagTransmissionAllocation,
        b: IETagTransmissionAllocation,
      ): number => a.trans_alloc_id - b.trans_alloc_id,
    );

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      allTransmissionAllocations,
      allUnfilteredTransmissionAllocations,
    });
  };

export const retrieveAndTransformDistributedTagTransactionStatuses =
  (
    toEntityId: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const response: AxiosResponse<IETagTransactionStatusesResponse> =
      await retrieveETagDistributedTransactionStatuses(
        toEntityId,
        tagPrimaryKey,
      );
    const eTagTransactionStatusesResponse: IETagTransactionStatusesResponse =
      response.data;

    if (!isSuccessStatus(response.status)) {
      if (response.status === NOT_FOUND_STATUS_CODE) {
        throw new NotFoundError(
          `Distributed Tag Transaction Statuses Not Found: ${eTagTransactionStatusesResponse.errorMessage}`,
        );
      }

      throw new Error(eTagTransactionStatusesResponse.errorMessage!);
    }

    const transactionStatuses: IDetailTransactionStatuses[] = [];
    eTagTransactionStatusesResponse.response.forEach(
      (eTagTransactionStatuses: IETagTransactionStatuses) => {
        transactionStatuses.push({
          act_on_by_time: eTagTransactionStatuses.act_on_by_time,
          approval_rights: eTagTransactionStatuses.approval_rights,
          contact_info:
            eTagTransactionStatuses.contact_info === null
              ? null
              : {
                  ...eTagTransactionStatuses.contact_info,
                  key: getEditInfoKey(
                    EDIT_TRANSACTION_STATUSES_CONTACT_INFO_LABEL,
                    eTagTransactionStatuses.request_id,
                    0,
                  ),
                },
          correction_id: eTagTransactionStatuses.correction_id,
          notes: eTagTransactionStatuses.notes,
          request_id: eTagTransactionStatuses.request_id,
          requestor:
            eTagTransactionStatuses.requestor == null
              ? null
              : {
                  ...eTagTransactionStatuses.requestor,
                },
          request_timestamp: eTagTransactionStatuses.request_timestamp,
          resolution_status: eTagTransactionStatuses.resolution_status,
          statuses:
            eTagTransactionStatuses.statuses === null
              ? null
              : eTagTransactionStatuses.statuses.map(
                  (
                    eTagTransactionStatus: IETagTransactionStatus,
                  ): IDetailTransactionStatus => ({
                    approval_status: eTagTransactionStatus.approval_status,
                    approval_status_type:
                      eTagTransactionStatus.approval_status_type,
                    approval_timestamp:
                      eTagTransactionStatus.approval_timestamp,
                    approver_notes: eTagTransactionStatus.approver_notes,
                    delivery_status: eTagTransactionStatus.delivery_status,
                    entity_code:
                      eTagTransactionStatus.entity === null
                        ? null
                        : eTagTransactionStatus.entity.entity_code,
                    entity_type:
                      eTagTransactionStatus.entity === null
                        ? null
                        : eTagTransactionStatus.entity.entity_type,
                  }),
                ),
          ui_transaction_message_type:
            eTagTransactionStatuses.ui_transaction_message_type,
        });
      },
    );

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      transactionStatuses,
    });
  };

const copyTransmissionAllocationProfile = (
  eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile,
): IETagTransmissionAllocationProfile => ({
  contract_number: eTagTransmissionAllocationProfile.contract_number,
  last_request_type: eTagTransmissionAllocationProfile.last_request_type,
  profile_mw: eTagTransmissionAllocationProfile.profile_mw,
  trans_alloc_id: eTagTransmissionAllocationProfile.trans_alloc_id,
});

const copyTransmissionSegment = (
  eTagTransmissionSegment: IETagTransmissionSegment,
): IETagTransmissionSegment => ({
  physical_segment_id: eTagTransmissionSegment.physical_segment_id,
  pod:
    eTagTransmissionSegment.pod === null
      ? null
      : { ...eTagTransmissionSegment.pod },
  pod_energy_profile: copyEnergyProfile(
    eTagTransmissionSegment.pod_energy_profile,
  ),
  por:
    eTagTransmissionSegment.por === null
      ? null
      : { ...eTagTransmissionSegment.por },
  por_energy_profile: copyEnergyProfile(
    eTagTransmissionSegment.por_energy_profile,
  ),
  tp_code:
    eTagTransmissionSegment.tp_code === null
      ? null
      : { ...eTagTransmissionSegment.tp_code },
  transmission_limit: eTagTransmissionSegment.transmission_limit,
  trans_alloc_profiles:
    eTagTransmissionSegment.trans_alloc_profiles === null
      ? null
      : eTagTransmissionSegment.trans_alloc_profiles.map(
          copyTransmissionAllocationProfile,
        ),
});

export const retrieveAndTransformDistributedTagPhysicalSegmentsProfiles =
  (
    toEntityId: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
    selectedRequestKey: string = CURRENT_REQUEST_KEY,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const response: AxiosResponse<IETagPhysicalSegmentsProfilesResponse> =
      await retrieveETagDistributedPhysicalSegmentsProfiles(
        toEntityId,
        tagPrimaryKey,
        selectedRequestKey === '0-1' ? '0' : selectedRequestKey,
      );
    const eTagPhysicalSegmentsProfilesResponse: IETagPhysicalSegmentsProfilesResponse =
      response.data;

    if (!isSuccessStatus(response.status)) {
      if (response.status === NOT_FOUND_STATUS_CODE) {
        throw new NotFoundError(
          `Distributed Tag Physical Segments Profiles Not Found: ${eTagPhysicalSegmentsProfilesResponse.errorMessage}`,
        );
      }

      throw new Error(eTagPhysicalSegmentsProfilesResponse.errorMessage!);
    }

    const physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [];
    eTagPhysicalSegmentsProfilesResponse.response.forEach(
      (
        eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile,
        index: number,
      ) => {
        physicalSegmentsProfiles.push({
          key: getEditInfoKey(
            EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
            // We must offset by 1 since the first index (0) is the
            // INITIAL_RECORD_ID
            index + 1,
            0,
          ),
          physical_segments_profiles:
            eTagPhysicalSegmentsProfile.physical_segments_profiles === null
              ? null
              : {
                  generation:
                    eTagPhysicalSegmentsProfile.physical_segments_profiles
                      .generation === null
                      ? null
                      : {
                          physical_segment_id:
                            eTagPhysicalSegmentsProfile
                              .physical_segments_profiles.generation
                              .physical_segment_id,
                          profile: copyEnergyProfile(
                            eTagPhysicalSegmentsProfile
                              .physical_segments_profiles.generation.profile,
                          ),
                          source_sink:
                            eTagPhysicalSegmentsProfile
                              .physical_segments_profiles.generation
                              .source_sink === null
                              ? null
                              : {
                                  ...eTagPhysicalSegmentsProfile
                                    .physical_segments_profiles.generation
                                    .source_sink,
                                },
                        },
                  load:
                    eTagPhysicalSegmentsProfile.physical_segments_profiles
                      .load === null
                      ? null
                      : {
                          physical_segment_id:
                            eTagPhysicalSegmentsProfile
                              .physical_segments_profiles.load
                              .physical_segment_id,
                          profile: copyEnergyProfile(
                            eTagPhysicalSegmentsProfile
                              .physical_segments_profiles.load.profile,
                          ),
                          source_sink:
                            eTagPhysicalSegmentsProfile
                              .physical_segments_profiles.load.source_sink ===
                            null
                              ? null
                              : {
                                  ...eTagPhysicalSegmentsProfile
                                    .physical_segments_profiles.load
                                    .source_sink,
                                },
                        },
                  transmission:
                    eTagPhysicalSegmentsProfile.physical_segments_profiles
                      .transmission === null
                      ? null
                      : {
                          transmission_limit:
                            eTagPhysicalSegmentsProfile
                              .physical_segments_profiles.transmission
                              .transmission_limit,
                          transmission_segments:
                            eTagPhysicalSegmentsProfile
                              .physical_segments_profiles.transmission
                              .transmission_segments === null
                              ? null
                              : eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission.transmission_segments.map(
                                  copyTransmissionSegment,
                                ),
                        },
                },
          start: eTagPhysicalSegmentsProfile.start,
          stop: eTagPhysicalSegmentsProfile.stop,
        });
      },
    );

    let transAllocProfile: IETagTransmissionAllocationProfile[] = [];

    eTagPhysicalSegmentsProfilesResponse.response.forEach((response) => {
      transAllocProfile = [];
      response.physical_segments_profiles?.transmission?.transmission_segments
        ?.slice()
        .forEach((transmissionSegment) => {
          if (
            transmissionSegment.trans_alloc_profiles &&
            transmissionSegment.trans_alloc_profiles?.length > 1
          ) {
            if (
              transmissionSegment &&
              transmissionSegment.trans_alloc_profiles &&
              transmissionSegment.trans_alloc_profiles[0]
            ) {
              if (transAllocProfile.length === 0) {
                transAllocProfile.push(
                  transmissionSegment.trans_alloc_profiles[1],
                );
              }
              transmissionSegment.trans_alloc_profiles = transAllocProfile;
            }
          }
        });
    });

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      physicalSegmentsProfiles,
    });
  };

export const retrieveAndTransformDistributedTagEnergyProfileSnapshots =
  (
    toEntity: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
    minRequestId: number,
    maxRequestId: number,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    if (maxRequestId < minRequestId) {
      throw new Error(
        `Invalid request ids, minRequestId: ${minRequestId} maxRequestId: ${maxRequestId}`,
      );
    }

    const response: AxiosResponse<IETagEnergyProfileSnapshotsResponse> =
      await retrieveETagDistributedEnergyProfileSnapshots(
        toEntity,
        tagPrimaryKey,
        minRequestId,
        maxRequestId,
      );
    const eTagEnergyProfileSnapshotsResponse: IETagEnergyProfileSnapshotsResponse =
      response.data;

    if (!isSuccessStatus(response.status)) {
      throw new Error(eTagEnergyProfileSnapshotsResponse.errorMessage!);
    }

    const { max_request_id, min_request_id, snapshot_intervals } =
      eTagEnergyProfileSnapshotsResponse.response;
    const detailEnergyProfileSnapshots: IDetailEnergyProfileSnapshots = {
      max_request_id,
      min_request_id,
      snapshotIntervals: snapshot_intervals.map(
        (
          eTagEnergyProfileSnapshotInterval: IETagEnergyProfileSnapshotInterval,
        ): IDetailEnergyProfileSnapshotInterval => ({
          snapshots: eTagEnergyProfileSnapshotInterval.snapshots.map(
            (
              eTagEnergyProfileSnapshot: IETagEnergyProfileSnapshot,
            ): IDetailEnergyProfileSnapshot => ({
              last_request_type: eTagEnergyProfileSnapshot.last_request_type,
              mw: eTagEnergyProfileSnapshot.mw,
              request_id: eTagEnergyProfileSnapshot.request_id,
            }),
          ),
          start: eTagEnergyProfileSnapshotInterval.start,
          stop: eTagEnergyProfileSnapshotInterval.stop,
        }),
      ),
    };

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      energyProfileSnapshots: detailEnergyProfileSnapshots,
    });
  };

const getSegmentKey = (start: string | null, stop: string | null): string =>
  `${start};${stop}`;

const getStartStopFromSegmentKey = (segmentKey: string): (string | null)[] => {
  const startStop: string[] = segmentKey.split(';');

  return startStop.map((value: string): string | null =>
    value === 'null' ? null : value,
  );
};

const retrieveTransmissionAllocations = (
  transAllocProfiles: IETagPathTransmissionAllocationProfile[] | null,
): IETagTransmissionAllocation[] => {
  const transmissionAllocations: IETagTransmissionAllocation[] = [];
  if (transAllocProfiles !== null) {
    transAllocProfiles.forEach(
      (
        eTagPathTransmissionAllocationProfile: IETagPathTransmissionAllocationProfile,
      ) => {
        const { trans_alloc_profile_attributes } =
          eTagPathTransmissionAllocationProfile;

        if (trans_alloc_profile_attributes !== null) {
          const {
            contract_number,
            misc_infos,
            nits_resource,
            physical_segment_ref,
            trans_alloc_customer_code,
            trans_alloc_id,
            trans_product_ref,
          } = trans_alloc_profile_attributes;
          const transmissionAllocationKey: string = getEditInfoKey(
            EDIT_TRANSMISSION_ALLOCATION_LABEL,
            trans_alloc_id,
            0,
          );

          transmissionAllocations.push({
            contract_number,
            misc_infos:
              misc_infos === null
                ? null
                : misc_infos.map(
                    (
                      misc_info: IMiscInfo,
                      miscInfoIndex: number,
                    ): IMiscInfo => ({
                      ...misc_info,
                      key: getEditInfoKey(
                        transmissionAllocationKey,
                        EDIT_TRANSMISSION_ALLOCATION_MISC_INFO_PRIMARY_KEY,
                        miscInfoIndex,
                      ),
                    }),
                  ),
            nits_resource,
            physical_segment_ref,
            trans_alloc_customer_code:
              trans_alloc_customer_code === null
                ? null
                : { ...trans_alloc_customer_code },
            trans_alloc_id,
            trans_product_ref:
              trans_product_ref === null ? null : { ...trans_product_ref },
          });
        }
      },
    );
  }

  return transmissionAllocations;
};

const validateEnergyProfiles = (
  energyProfiles: IETagPathEnergyProfile[] | null,
  throwExceptionOnMissing: boolean,
): number[] => {
  if (energyProfiles !== null) {
    if (energyProfiles.length > 1) {
      const primaryEnergyProfile: IETagPathEnergyProfile | undefined =
        energyProfiles.find(
          (eTagPathEnergyProfile: IETagPathEnergyProfile): boolean =>
            eTagPathEnergyProfile.profile_id === 1,
        );

      if (primaryEnergyProfile === undefined) {
        throw new Error(
          `Missing profile_id 1 for energy profiles ${JSON.stringify(
            energyProfiles,
          )}`,
        );
      }

      const shouldCheckEnergyProfileDetails: boolean =
        primaryEnergyProfile.energy_profile_details !== null &&
        primaryEnergyProfile.energy_profile_details.length > 0;

      const profileIds: Set<number> = new Set<number>();
      let highestProfileId: number = 0;

      energyProfiles.forEach(
        (eTagPathEnergyProfile: IETagPathEnergyProfile) => {
          if (
            (shouldCheckEnergyProfileDetails &&
              eTagPathEnergyProfile.energy_profile_details !== null &&
              eTagPathEnergyProfile.energy_profile_details.length > 0) ||
            !shouldCheckEnergyProfileDetails
          ) {
            profileIds.add(eTagPathEnergyProfile.profile_id);
          }

          if (eTagPathEnergyProfile.profile_id > highestProfileId) {
            highestProfileId = eTagPathEnergyProfile.profile_id;
          }
        },
      );

      if (profileIds.size !== highestProfileId) {
        for (let i: number = 1; i <= highestProfileId; i += 1) {
          if (profileIds.has(i)) {
            profileIds.delete(i);
          } else {
            profileIds.add(i);
          }
        }

        const missingProfileIds: number[] = Array.from(profileIds);

        if (throwExceptionOnMissing) {
          throw new Error(
            `Missing energy profiles for profile_ids ${missingProfileIds.join(
              ',',
            )}`,
          );
        }

        return missingProfileIds;
      }
    } else if (energyProfiles.length === 1) {
      if (energyProfiles[0].profile_id !== 1) {
        throw new Error(
          `Invalid profile_id for energy profile ${JSON.stringify(
            energyProfiles[0],
          )}`,
        );
      }
    }
  }

  return [];
};

const pathEnergyProfileSorter = (
  a: IETagPathEnergyProfile,
  b: IETagPathEnergyProfile,
): number =>
  a.profile_id < b.profile_id ? -1 : a.profile_id > b.profile_id ? 1 : 0;

const insertMissingEnergyProfileDetails = (
  energyProfiles: IETagPathEnergyProfile[] | null,
  transAllocProfiles: IETagPathTransmissionAllocationProfile[] | null,
): IETagPathEnergyProfile[] | null => {
  let updatedEnergyProfiles: IETagPathEnergyProfile[] | null = energyProfiles;

  if (updatedEnergyProfiles === null || updatedEnergyProfiles.length === 0) {
    if (transAllocProfiles !== null && transAllocProfiles.length > 0) {
      updatedEnergyProfiles = [
        {
          profile_id: 1,
          energy_profile_details: [],
        },
      ];
    }
  } else {
    const missingProfileIds: number[] = validateEnergyProfiles(
      updatedEnergyProfiles,
      false,
    );

    if (missingProfileIds.length > 0) {
      const primaryEnergyProfile: IETagPathEnergyProfile =
        updatedEnergyProfiles[0];

      if (primaryEnergyProfile.profile_id !== 1) {
        throw new Error(
          `Invalid profile_id for primaryEnergyProfile ${JSON.stringify(
            primaryEnergyProfile,
          )}`,
        );
      }

      if (primaryEnergyProfile.energy_profile_details !== null) {
        const startStopDateTimeStrings: (string | null)[] = [];

        primaryEnergyProfile.energy_profile_details.forEach(
          (eTagEnergyProfileDetail: IETagEnergyProfileDetail) => {
            startStopDateTimeStrings.push(
              eTagEnergyProfileDetail.start,
              eTagEnergyProfileDetail.stop,
            );
          },
        );

        if (startStopDateTimeStrings.length > 0) {
          updatedEnergyProfiles = [...updatedEnergyProfiles];

          missingProfileIds.forEach((profileId: number) => {
            const eTagPathEnergyProfileIndex: number =
              updatedEnergyProfiles!.findIndex(
                (eTagPathEnergyProfile: IETagPathEnergyProfile): boolean =>
                  eTagPathEnergyProfile.profile_id === profileId,
              );
            const eTagPathEnergyProfile: IETagPathEnergyProfile = {
              profile_id: profileId,
              energy_profile_details: [],
            };

            for (
              let i: number = 0;
              i < startStopDateTimeStrings.length;
              i += 2
            ) {
              const start: string | null = startStopDateTimeStrings[i];
              const stop: string | null = startStopDateTimeStrings[i + 1];

              eTagPathEnergyProfile.energy_profile_details!.push({
                energy_profile_type: null,
                limit_clearing: null,
                mw: null,
                start,
                start_ramp_dur: null,
                stop,
                stop_ramp_dur: null,
              });
            }

            if (eTagPathEnergyProfileIndex === -1) {
              updatedEnergyProfiles!.push(eTagPathEnergyProfile);
            } else {
              updatedEnergyProfiles![eTagPathEnergyProfileIndex] =
                eTagPathEnergyProfile;
            }
          });

          updatedEnergyProfiles.sort(pathEnergyProfileSorter);
        }
      }
    }
  }

  return updatedEnergyProfiles;
};

const createProfileMappings = (
  energyProfiles: IETagPathEnergyProfile[] | null,
) => {
  // Create maps needed to dereference energy profiles
  const physicalSegmentsProfilesMap: TMap<
    number,
    TMap<string, IETagPhysicalSegmentsProfile>
  > = {};
  const segmentProfileIdMap: TMap<string, number[]> = {};
  const energyProfileMap: TMap<number, TMap<string, IEnergyProfile>> = {};

  if (energyProfiles !== null) {
    energyProfiles.forEach((eTagPathEnergyProfile: IETagPathEnergyProfile) => {
      if (eTagPathEnergyProfile.energy_profile_details !== null) {
        const { profile_id } = eTagPathEnergyProfile;
        let physicalSegmentsProfileMap:
          | TMap<string, IETagPhysicalSegmentsProfile>
          | undefined = physicalSegmentsProfilesMap[profile_id];

        if (physicalSegmentsProfileMap === undefined) {
          physicalSegmentsProfileMap = {};

          physicalSegmentsProfilesMap[profile_id] = physicalSegmentsProfileMap;
        }

        energyProfileMap[profile_id] = {};

        eTagPathEnergyProfile.energy_profile_details.forEach(
          (eTagEnergyProfileDetail: IETagEnergyProfileDetail) => {
            const {
              energy_profile_type,
              mw,
              start,
              start_ramp_dur,
              stop,
              stop_ramp_dur,
            } = eTagEnergyProfileDetail;

            const segmentKey: string = getSegmentKey(start, stop);

            if (segmentProfileIdMap[segmentKey] === undefined) {
              segmentProfileIdMap[segmentKey] = [];
            }

            segmentProfileIdMap[segmentKey]!.push(profile_id);

            let eTagPhysicalSegmentsProfile:
              | IETagPhysicalSegmentsProfile
              | undefined = physicalSegmentsProfileMap![segmentKey];

            if (eTagPhysicalSegmentsProfile === undefined) {
              eTagPhysicalSegmentsProfile = {
                key: '',
                physical_segments_profiles: {
                  generation: null,
                  load: null,
                  transmission: null,
                },
                start,
                stop,
              };

              physicalSegmentsProfileMap![segmentKey] =
                eTagPhysicalSegmentsProfile;
            }

            const profile: IEnergyProfile = {
              adjusted: null,
              curtailments: [],
              last_request_id: null,
              last_request_type: null,
              market_level:
                energy_profile_type === EEnergyProfileType.MarketLevel
                  ? mw
                  : null,
              mw,
              profile_id,
              reliability_limit:
                energy_profile_type === EEnergyProfileType.ReliabilityLimit
                  ? mw
                  : null,
              reloaders: [],
              start_ramp_dur,
              stop_ramp_dur,
            };

            energyProfileMap[profile_id]![segmentKey] = profile;
          },
        );
      }
    });
  }

  return {
    energyProfileMap,
    physicalSegmentsProfilesMap,
    segmentProfileIdMap,
  };
};

const attachEnergyProfiles = (
  energyProfileMap: TMap<number, TMap<string, IEnergyProfile>>,
  physicalSegmentsProfilesMap: TMap<
    number,
    TMap<string, IETagPhysicalSegmentsProfile>
  >,
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
) => {
  // Attach energy profiles to physical segments profiles based on their
  // references
  Object.keys(energyProfileMap).forEach((profileIdString: string) => {
    const profileId: number = parseInt(profileIdString, 10);
    const energyProfileRecord: TMap<string, IEnergyProfile> =
      energyProfileMap[profileId]!;

    if (
      generationPhysicalSegment !== null &&
      generationPhysicalSegment.profile_ref === profileId
    ) {
      Object.keys(energyProfileRecord).forEach((segmentKey: string) => {
        const energyProfile: IEnergyProfile = energyProfileRecord[segmentKey]!;

        if (
          physicalSegmentsProfilesMap?.[profileId]?.[segmentKey] !== undefined
        ) {
          physicalSegmentsProfilesMap[profileId]![
            segmentKey
          ]!.physical_segments_profiles!.generation = {
            physical_segment_id: generationPhysicalSegment!.physical_segment_id,
            profile: { ...energyProfile, profile_id: profileId },
            source_sink: copyPointInfo(
              generationPhysicalSegment.generation_source,
            ),
          };
        }
      });
    }

    if (transmissionPhysicalSegments !== null) {
      const transmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined = transmissionPhysicalSegments.find(
        (
          eTagTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
        ): boolean =>
          eTagTransmissionPhysicalSegment.pod_profile_ref === profileId,
      );

      if (transmissionPhysicalSegment !== undefined) {
        Object.keys(energyProfileRecord).forEach((segmentKey: string) => {
          const energyProfile: IEnergyProfile =
            energyProfileRecord[segmentKey]!;
          const eTagPhysicalSegmentsProfile:
            | IETagPhysicalSegmentsProfile
            | undefined =
            physicalSegmentsProfilesMap?.[profileId]?.[segmentKey];

          if (eTagPhysicalSegmentsProfile !== undefined) {
            if (
              eTagPhysicalSegmentsProfile.physical_segments_profiles!
                .transmission === null
            ) {
              eTagPhysicalSegmentsProfile.physical_segments_profiles!.transmission =
                {
                  transmission_limit: null,
                  transmission_segments: [],
                };
            }

            eTagPhysicalSegmentsProfile.physical_segments_profiles!.transmission.transmission_segments!.push(
              getInitialTransmissionSegment(
                transmissionPhysicalSegment.physical_segment_id,
                transmissionPhysicalSegment.pod,
                energyProfile,
                transmissionPhysicalSegment.por,
                transmissionPhysicalSegment.por_profile_ref === null
                  ? undefined
                  : energyProfileMap?.[
                      transmissionPhysicalSegment.por_profile_ref
                    ]?.[segmentKey],
                transmissionPhysicalSegment.tp_code,
                [],
              ),
            );
          }
        });
      }
    }

    if (
      loadPhysicalSegment !== null &&
      loadPhysicalSegment.profile_ref === profileId
    ) {
      Object.keys(energyProfileRecord).forEach((segmentKey: string) => {
        const energyProfile: IEnergyProfile = energyProfileRecord[segmentKey]!;

        if (
          physicalSegmentsProfilesMap?.[profileId]?.[segmentKey] !== undefined
        ) {
          physicalSegmentsProfilesMap[profileId]![
            segmentKey
          ]!.physical_segments_profiles!.load = {
            physical_segment_id: loadPhysicalSegment!.physical_segment_id,
            profile: { ...energyProfile, profile_id: profileId },
            source_sink: copyPointInfo(loadPhysicalSegment.load_sink),
          };
        }
      });
    }
  });
};

const updateMapsForTransAllocProfiles = (
  energyProfileMap: TMap<number, TMap<string, IEnergyProfile>>,
  physicalSegmentsProfilesMap: TMap<
    number,
    TMap<string, IETagPhysicalSegmentsProfile>
  >,
  segmentProfileIdMap: TMap<string, number[]>,
  segmentKey: string,
  profileId: number,
  start: string | null,
  stop: string | null,
) => {
  if (segmentProfileIdMap[segmentKey] === undefined) {
    segmentProfileIdMap[segmentKey] = [];
  }

  if (!segmentProfileIdMap[segmentKey]!.includes(profileId)) {
    if (energyProfileMap[profileId] === undefined) {
      energyProfileMap[profileId] = {};
    }

    if (energyProfileMap[profileId]![segmentKey] === undefined) {
      energyProfileMap[profileId]![segmentKey] =
        getInitialEnergyProfile(profileId);
    }

    if (physicalSegmentsProfilesMap[profileId] === undefined) {
      physicalSegmentsProfilesMap[profileId] = {};
    }

    let eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile | undefined =
      physicalSegmentsProfilesMap[profileId]![segmentKey];

    if (eTagPhysicalSegmentsProfile === undefined) {
      eTagPhysicalSegmentsProfile = {
        key: '',
        physical_segments_profiles: {
          generation: null,
          load: null,
          transmission: {
            transmission_limit: null,
            transmission_segments: [],
          },
        },
        start,
        stop,
      };

      physicalSegmentsProfilesMap[profileId]![segmentKey] =
        eTagPhysicalSegmentsProfile;
    } else {
      if (eTagPhysicalSegmentsProfile.physical_segments_profiles === null) {
        throw new Error(
          `Missing physical_segments_profiles for eTagPhysicalSegmentsProfile ${JSON.stringify(
            eTagPhysicalSegmentsProfile,
          )}`,
        );
      }

      if (
        eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission ===
        null
      ) {
        eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission = {
          transmission_limit: null,
          transmission_segments: [],
        };
      }
    }

    segmentProfileIdMap[segmentKey]!.push(profileId);
  }
};

const updateMwValuesForTransAllocProfiles = (
  energyProfileMap: TMap<number, TMap<string, IEnergyProfile>>,
  physicalSegmentsProfilesMap: TMap<
    number,
    TMap<string, IETagPhysicalSegmentsProfile>
  >,
  segmentProfileIdMap: TMap<string, number[]>,
  transAllocProfiles: IETagPathTransmissionAllocationProfile[] | null,
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  transmissionAllocations: IETagTransmissionAllocation[],
) => {
  // Populate the mw values for transAllocProfiles.
  if (transAllocProfiles !== null) {
    transAllocProfiles.forEach(
      (
        eTagPathTransmissionAllocationProfile: IETagPathTransmissionAllocationProfile,
      ) => {
        const { trans_alloc_profile_attributes, trans_alloc_profile_details } =
          eTagPathTransmissionAllocationProfile;

        if (
          trans_alloc_profile_details !== null &&
          trans_alloc_profile_attributes !== null
        ) {
          const { contract_number, physical_segment_ref, trans_alloc_id } =
            trans_alloc_profile_attributes;

          const transmissionPhysicalSegment:
            | IETagTransmissionPhysicalSegment
            | undefined =
            transmissionPhysicalSegments === null
              ? undefined
              : transmissionPhysicalSegments.find(
                  (
                    eTagTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
                  ): boolean =>
                    eTagTransmissionPhysicalSegment.physical_segment_id ===
                    physical_segment_ref,
                );

          if (
            transmissionPhysicalSegment !== undefined &&
            transmissionPhysicalSegment.pod_profile_ref !== null
          ) {
            trans_alloc_profile_details.forEach(
              (
                eTagTransmissionAllocationProfileDetail: IETagTransmissionAllocationProfileDetail,
              ) => {
                const { mw, start, stop } =
                  eTagTransmissionAllocationProfileDetail;
                const segmentKey: string = getSegmentKey(start, stop);

                if (segmentProfileIdMap[segmentKey] === undefined) {
                  segmentProfileIdMap[segmentKey] = [];
                }

                const transmissionPhysicalSegmentProfileId: number =
                  transmissionPhysicalSegment.pod_profile_ref!;

                // Add any missing energy profiles and physical segments
                // profiles to the maps based on the segment key
                updateMapsForTransAllocProfiles(
                  energyProfileMap,
                  physicalSegmentsProfilesMap,
                  segmentProfileIdMap,
                  segmentKey,
                  transmissionPhysicalSegmentProfileId,
                  start,
                  stop,
                );

                let eTagPhysicalSegmentsProfile:
                  | IETagPhysicalSegmentsProfile
                  | undefined =
                  physicalSegmentsProfilesMap[
                    transmissionPhysicalSegmentProfileId
                  ]?.[segmentKey];

                if (eTagPhysicalSegmentsProfile === undefined) {
                  eTagPhysicalSegmentsProfile =
                    getInitialPhysicalSegmentsProfile(
                      '',
                      start,
                      stop,
                      generationPhysicalSegment,
                      loadPhysicalSegment,
                      transmissionPhysicalSegments,
                      transmissionAllocations,
                    );

                  physicalSegmentsProfilesMap[
                    transmissionPhysicalSegmentProfileId
                  ]![segmentKey] = eTagPhysicalSegmentsProfile;
                }

                if (
                  eTagPhysicalSegmentsProfile.physical_segments_profiles!
                    .transmission === null
                ) {
                  eTagPhysicalSegmentsProfile.physical_segments_profiles!.transmission =
                    {
                      transmission_limit: null,
                      transmission_segments: [],
                    };
                }

                let eTagTransmissionSegment:
                  | IETagTransmissionSegment
                  | undefined = eTagPhysicalSegmentsProfile.physical_segments_profiles!.transmission.transmission_segments!.find(
                  (
                    eTagTransmissionSegment: IETagTransmissionSegment,
                  ): boolean =>
                    eTagTransmissionSegment.physical_segment_id ===
                    physical_segment_ref,
                );

                if (eTagTransmissionSegment === undefined) {
                  eTagTransmissionSegment = getInitialTransmissionSegment(
                    transmissionPhysicalSegment.physical_segment_id,
                  );

                  eTagTransmissionSegment.trans_alloc_profiles = [];

                  eTagPhysicalSegmentsProfile.physical_segments_profiles!.transmission.transmission_segments!.push(
                    eTagTransmissionSegment,
                  );
                }

                eTagTransmissionSegment.pod = copyPointInfo(
                  transmissionPhysicalSegment.pod,
                );

                const energyProfileRecord:
                  | TMap<string, IEnergyProfile>
                  | undefined =
                  energyProfileMap[transmissionPhysicalSegmentProfileId];

                if (energyProfileRecord === undefined) {
                  eTagTransmissionSegment.pod_energy_profile =
                    getInitialEnergyProfile(
                      getPODProfileRef(
                        transmissionPhysicalSegment.physical_segment_id,
                      ),
                    );
                } else {
                  const energyProfile: IEnergyProfile | undefined =
                    energyProfileRecord[segmentKey];

                  if (energyProfile === undefined) {
                    eTagTransmissionSegment.pod_energy_profile =
                      getInitialEnergyProfile(
                        getPODProfileRef(
                          transmissionPhysicalSegment.physical_segment_id,
                        ),
                      );
                  } else {
                    eTagTransmissionSegment.pod_energy_profile = {
                      ...energyProfile,
                      profile_id:
                        transmissionPhysicalSegment?.pod_profile_ref || 1,
                    };
                  }
                }

                eTagTransmissionSegment.por = copyPointInfo(
                  transmissionPhysicalSegment.por,
                );

                if (transmissionPhysicalSegment.por_profile_ref !== null) {
                  const energyProfileRecord:
                    | TMap<string, IEnergyProfile>
                    | undefined =
                    energyProfileMap[
                      transmissionPhysicalSegment.por_profile_ref
                    ];

                  if (energyProfileRecord === undefined) {
                    eTagTransmissionSegment.por_energy_profile =
                      getInitialEnergyProfile(
                        transmissionPhysicalSegment?.por_profile_ref,
                      );
                  } else {
                    const energyProfile: IEnergyProfile | undefined =
                      energyProfileRecord[segmentKey];

                    if (energyProfile === undefined) {
                      eTagTransmissionSegment.por_energy_profile =
                        getInitialEnergyProfile(
                          getPORProfileRef(
                            transmissionPhysicalSegment.physical_segment_id,
                          ),
                        );
                    } else {
                      eTagTransmissionSegment.por_energy_profile = {
                        ...energyProfile,
                        profile_id: transmissionPhysicalSegment.por_profile_ref,
                      };
                    }
                  }
                }

                eTagTransmissionSegment.tp_code = copyEntityInfo(
                  transmissionPhysicalSegment.tp_code,
                );

                const transAllocProfileIndex: number =
                  eTagTransmissionSegment.trans_alloc_profiles!.findIndex(
                    (
                      eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile,
                    ): boolean =>
                      eTagTransmissionAllocationProfile.contract_number ===
                      contract_number,
                  );

                if (transAllocProfileIndex === -1) {
                  eTagTransmissionSegment.trans_alloc_profiles!.push({
                    contract_number,
                    last_request_type: null,
                    profile_mw: mw,
                    trans_alloc_id,
                  });
                } else {
                  eTagTransmissionSegment.trans_alloc_profiles![
                    transAllocProfileIndex
                  ].profile_mw = mw;
                }
              },
            );
          }
        }
      },
    );
  }
};

const updateTransmissionSegmentEnergyProfile = (
  transmissionSegment: IETagTransmissionSegment,
  transmission: IETagTransmissionPhysicalSegmentProfile | null,
  hasMultipleProfiles: boolean,
  usePorEneryProfile: boolean,
  generation?: IETagPhysicalSegmentProfile,
): boolean => {
  if (generation !== undefined) {
    if (generation.profile === null) {
      throw new Error(
        `Invalid profile for generation: ${JSON.stringify(generation)}`,
      );
    }

    // Only the POR energy profile can potentially be
    // referring to the generation energy profile. We must
    // also copy the profile to ensure it is unique to any
    // references used in these generation energy profiles
    transmissionSegment.por_energy_profile = copyEnergyProfile(
      generation.profile,
    );

    return true;
  } else if (transmission !== null) {
    if (transmission.transmission_segments === null) {
      throw new Error(
        `Invalid transmission_segments for transmission: ${JSON.stringify(
          transmission,
        )}`,
      );
    }

    const eTagTransmissionSegment: IETagTransmissionSegment | undefined =
      hasMultipleProfiles
        ? transmission.transmission_segments[0]
        : transmission.transmission_segments.find(
            (eTagTransmissionSegment: IETagTransmissionSegment): boolean =>
              eTagTransmissionSegment.physical_segment_id ===
              transmissionSegment.physical_segment_id,
          );

    if (eTagTransmissionSegment !== undefined) {
      if (usePorEneryProfile) {
        const { pod_energy_profile } = eTagTransmissionSegment;

        if (pod_energy_profile === null) {
          throw new Error(
            `Invalid pod_energy_profile for eTagTransmissionSegment: ${JSON.stringify(
              eTagTransmissionSegment,
            )}`,
          );
        }

        // Copy the profile to ensure it is unique to any references used in
        // these generation energy profiles
        transmissionSegment.por_energy_profile =
          copyEnergyProfile(pod_energy_profile);

        return true;
      } else {
        const { pod_energy_profile } = eTagTransmissionSegment;

        if (pod_energy_profile === null) {
          throw new Error(
            `Invalid pod_energy_profile for eTagTransmissionSegment: ${JSON.stringify(
              eTagTransmissionSegment,
            )}`,
          );
        }

        transmissionSegment.pod_energy_profile = pod_energy_profile;

        return true;
      }
    }
  }

  return false;
};

const updateTransmissionSegmentTransAllocProfiles = (
  transmissionSegment: IETagTransmissionSegment,
  transmission: IETagTransmissionPhysicalSegmentProfile | null,
) => {
  if (transmission !== null) {
    if (transmission.transmission_segments === null) {
      throw new Error(
        `Invalid transmission_segments for transmission: ${JSON.stringify(
          transmission,
        )}`,
      );
    }

    transmission.transmission_segments.forEach(
      (eTagTransmissionSegment: IETagTransmissionSegment) => {
        if (
          transmissionSegment.physical_segment_id ===
          eTagTransmissionSegment.physical_segment_id
        ) {
          const { trans_alloc_profiles } = eTagTransmissionSegment;

          if (trans_alloc_profiles !== null) {
            trans_alloc_profiles.forEach(
              (
                eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile,
              ) => {
                if (transmissionSegment.trans_alloc_profiles === null) {
                  transmissionSegment.trans_alloc_profiles = [];
                }

                const transmissionAllocationProfile:
                  | IETagTransmissionAllocationProfile
                  | undefined = transmissionSegment.trans_alloc_profiles.find(
                  (
                    transmissionAllocationProfile: IETagTransmissionAllocationProfile,
                  ): boolean =>
                    transmissionAllocationProfile.contract_number ===
                      eTagTransmissionAllocationProfile.contract_number &&
                    transmissionAllocationProfile.trans_alloc_id ===
                      eTagTransmissionAllocationProfile.trans_alloc_id,
                );

                if (transmissionAllocationProfile === undefined) {
                  transmissionSegment.trans_alloc_profiles.push(
                    eTagTransmissionAllocationProfile,
                  );
                } else {
                  transmissionAllocationProfile.last_request_type =
                    transmissionAllocationProfile.last_request_type === null
                      ? eTagTransmissionAllocationProfile.last_request_type
                      : transmissionAllocationProfile.last_request_type;

                  transmissionAllocationProfile.profile_mw =
                    transmissionAllocationProfile.last_request_type === null
                      ? eTagTransmissionAllocationProfile.profile_mw
                      : transmissionAllocationProfile.profile_mw;
                }
              },
            );
          }
        }
      },
    );
  }
};

const transformProfileMappingsToPhysicalSegmentsProfiles = (
  physicalSegmentsProfilesMap: TMap<
    number,
    TMap<string, IETagPhysicalSegmentsProfile>
  >,
  segmentProfileIdMap: TMap<string, number[]>,
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  transmissionAllocations: IETagTransmissionAllocation[],
): IETagPhysicalSegmentsProfile[] => {
  const physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [];
  const physicalSegmentsProfilesMapKeys: string[] = Object.keys(
    physicalSegmentsProfilesMap,
  );
  const hasMultipleProfiles: boolean =
    physicalSegmentsProfilesMapKeys.length > 1;

  if (physicalSegmentsProfilesMapKeys.length === 1) {
    const profileId: number = parseInt(physicalSegmentsProfilesMapKeys[0], 10);

    if (profileId !== 1) {
      throw new Error(
        `Invalid profileId for physicalSegmentsProfilesMapKeys: ${physicalSegmentsProfilesMapKeys}`,
      );
    }
  }

  // Object.keys iterates through object keys chronologically, so in order
  // to ensure our times are in the correct sequence, we must sort the keys
  // before we create our physicalSegmentsProfiles
  Object.keys(segmentProfileIdMap)
    .sort()
    .forEach((key: string, index: number) => {
      const profileIds: number[] | undefined = segmentProfileIdMap[key];
      const startStop: (string | null)[] = getStartStopFromSegmentKey(key);
      const physicalSegmentsProfile: IETagPhysicalSegmentsProfile = {
        key: getEditInfoKey(
          EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
          // We must offset by 1 since the first index (0) is the
          // INITIAL_RECORD_ID
          index + 1,
          0,
        ),
        physical_segments_profiles: {
          generation: getInitialGenerationPhysicalSegmentProfile(
            generationPhysicalSegment,
          ),
          load: getInitialLoadPhysicalSegmentProfile(
            loadPhysicalSegment,
            transmissionPhysicalSegments,
          ),
          transmission: getInitialTransmissionPhysicalSegmentProfile(
            transmissionPhysicalSegments,
            transmissionAllocations,
          ),
        },
        start: startStop[0],
        stop: startStop[1],
      };

      // Generation
      {
        const generationProfileId: number = hasMultipleProfiles
          ? physicalSegmentsProfile.physical_segments_profiles!.generation!
              .profile!.profile_id
          : 1;
        const eTagPhysicalSegmentsProfile:
          | IETagPhysicalSegmentsProfile
          | undefined = physicalSegmentsProfilesMap[generationProfileId]?.[key];

        if (eTagPhysicalSegmentsProfile !== undefined) {
          const { physical_segments_profiles } = eTagPhysicalSegmentsProfile;

          if (physical_segments_profiles !== null) {
            const { generation } = physical_segments_profiles;

            if (generation !== null) {
              physicalSegmentsProfile.physical_segments_profiles!.generation =
                generation;
            }
          }
        }
      }

      // Load
      {
        const loadProfileId: number = hasMultipleProfiles
          ? physicalSegmentsProfile.physical_segments_profiles!.load!.profile!
              .profile_id
          : 1;
        const eTagPhysicalSegmentsProfile:
          | IETagPhysicalSegmentsProfile
          | undefined = physicalSegmentsProfilesMap[loadProfileId]?.[key];

        if (eTagPhysicalSegmentsProfile !== undefined) {
          const { physical_segments_profiles } = eTagPhysicalSegmentsProfile;

          if (physical_segments_profiles !== null) {
            const { load } = physical_segments_profiles;

            if (load !== null) {
              physicalSegmentsProfile.physical_segments_profiles!.load = load;
            }
          }
        }
      }

      // Transmission
      if (
        physicalSegmentsProfile.physical_segments_profiles!.transmission!
          .transmission_segments !== null
      ) {
        physicalSegmentsProfile.physical_segments_profiles!.transmission!.transmission_segments.forEach(
          (transmissionSegment: IETagTransmissionSegment) => {
            // POD
            {
              const podProfileId: number = hasMultipleProfiles
                ? transmissionSegment.pod_energy_profile!.profile_id
                : 1;
              const eTagPhysicalSegmentsProfile:
                | IETagPhysicalSegmentsProfile
                | undefined = physicalSegmentsProfilesMap[podProfileId]?.[key];

              if (eTagPhysicalSegmentsProfile !== undefined) {
                const { physical_segments_profiles } =
                  eTagPhysicalSegmentsProfile;

                if (physical_segments_profiles !== null) {
                  const { transmission } = physical_segments_profiles;

                  updateTransmissionSegmentEnergyProfile(
                    transmissionSegment,
                    transmission,
                    hasMultipleProfiles,
                    false,
                  );
                }
              }
            }

            // POR
            {
              const porProfileId: number = hasMultipleProfiles
                ? transmissionSegment.por_energy_profile!.profile_id
                : 1;
              const eTagPhysicalSegmentsProfile:
                | IETagPhysicalSegmentsProfile
                | undefined = physicalSegmentsProfilesMap[porProfileId]?.[key];
              if (eTagPhysicalSegmentsProfile !== undefined) {
                const { physical_segments_profiles } =
                  eTagPhysicalSegmentsProfile;

                if (physical_segments_profiles !== null) {
                  const { generation, transmission } =
                    physical_segments_profiles;

                  const hasUpdated: boolean =
                    updateTransmissionSegmentEnergyProfile(
                      transmissionSegment,
                      transmission,
                      hasMultipleProfiles,
                      true,
                    );

                  if (!hasUpdated && generation !== null) {
                    updateTransmissionSegmentEnergyProfile(
                      transmissionSegment,
                      transmission,
                      hasMultipleProfiles,
                      true,
                      generation,
                    );
                  }
                }
              }
            }

            // Transmission allocation profiles
            if (profileIds !== undefined) {
              profileIds.forEach((profileId: number) => {
                const eTagPhysicalSegmentsProfile:
                  | IETagPhysicalSegmentsProfile
                  | undefined = physicalSegmentsProfilesMap[profileId]?.[key];

                if (eTagPhysicalSegmentsProfile !== undefined) {
                  const { physical_segments_profiles } =
                    eTagPhysicalSegmentsProfile;

                  if (physical_segments_profiles !== null) {
                    const { transmission } = physical_segments_profiles;

                    updateTransmissionSegmentTransAllocProfiles(
                      transmissionSegment,
                      transmission,
                    );
                  }
                }
              });
            }
          },
        );
      }

      physicalSegmentsProfiles.push(physicalSegmentsProfile);
    });

  return physicalSegmentsProfiles;
};

export const transformETagPathEnergyProfile = (
  path_profile: IETagPathProfile | null,
  path: IETagPath | null,
) => {
  const generationPhysicalSegment: IDetailGenerationPhysicalSegment | null =
    path === null || path.physical_segments === null
      ? null
      : transformGenerationPhysicalSegment(
          path.physical_segments.generation_physical_segment,
        );
  const loadPhysicalSegment: IDetailLoadPhysicalSegment | null =
    path === null || path.physical_segments === null
      ? null
      : transformLoadPhysicalSegment(
          path.physical_segments.load_physical_segment,
        );
  let physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [];
  let transmissionAllocations: IETagTransmissionAllocation[] = [];

  if (path_profile !== null) {
    const { energy_profiles, trans_alloc_profiles } = path_profile;
    transmissionAllocations =
      retrieveTransmissionAllocations(trans_alloc_profiles);

    if (
      (energy_profiles !== null || trans_alloc_profiles !== null) &&
      path !== null &&
      path.physical_segments !== null
    ) {
      const { transmission_physical_segments } = path.physical_segments;

      const adjustedEnergyProfiles: IETagPathEnergyProfile[] | null =
        insertMissingEnergyProfileDetails(
          energy_profiles,
          trans_alloc_profiles,
        );

      const {
        energyProfileMap,
        physicalSegmentsProfilesMap,
        segmentProfileIdMap,
      } = createProfileMappings(adjustedEnergyProfiles);

      attachEnergyProfiles(
        energyProfileMap,
        physicalSegmentsProfilesMap,
        generationPhysicalSegment,
        transmission_physical_segments,
        loadPhysicalSegment,
      );

      updateMwValuesForTransAllocProfiles(
        energyProfileMap,
        physicalSegmentsProfilesMap,
        segmentProfileIdMap,
        trans_alloc_profiles,
        generationPhysicalSegment,
        transmission_physical_segments,
        loadPhysicalSegment,
        transmissionAllocations,
      );

      physicalSegmentsProfiles =
        transformProfileMappingsToPhysicalSegmentsProfiles(
          physicalSegmentsProfilesMap,
          segmentProfileIdMap,
          generationPhysicalSegment,
          transmission_physical_segments,
          loadPhysicalSegment,
          transmissionAllocations,
        );
    }
  }

  return {
    generationPhysicalSegment,
    loadPhysicalSegment,
    physicalSegmentsProfiles,
    transmissionAllocations,
  };
};

// exported for use in unit testing
export const pathTransmissionAllocationProfileByPhysicalSegmentSorter = (
  a: IETagPathTransmissionAllocationProfile,
  b: IETagPathTransmissionAllocationProfile,
): number => {
  if (
    a.trans_alloc_profile_attributes === null &&
    b.trans_alloc_profile_attributes === null
  ) {
    return 0;
  } else if (
    a.trans_alloc_profile_attributes !== null &&
    b.trans_alloc_profile_attributes === null
  ) {
    return -1;
  } else if (
    a.trans_alloc_profile_attributes === null &&
    b.trans_alloc_profile_attributes !== null
  ) {
    return 1;
  } else if (
    a.trans_alloc_profile_attributes!.physical_segment_ref === null &&
    b.trans_alloc_profile_attributes!.physical_segment_ref === null
  ) {
    return 0;
  } else if (
    a.trans_alloc_profile_attributes!.physical_segment_ref !== null &&
    b.trans_alloc_profile_attributes!.physical_segment_ref === null
  ) {
    return -1;
  } else if (
    a.trans_alloc_profile_attributes!.physical_segment_ref === null &&
    b.trans_alloc_profile_attributes!.physical_segment_ref !== null
  ) {
    return 1;
  } else if (
    a.trans_alloc_profile_attributes!.physical_segment_ref! <
    b.trans_alloc_profile_attributes!.physical_segment_ref!
  ) {
    return -1;
  } else if (
    a.trans_alloc_profile_attributes!.physical_segment_ref! >
    b.trans_alloc_profile_attributes!.physical_segment_ref!
  ) {
    return 1;
  } else if (
    a.trans_alloc_profile_attributes!.trans_alloc_id <
    b.trans_alloc_profile_attributes!.trans_alloc_id
  ) {
    return -1;
  } else if (
    a.trans_alloc_profile_attributes!.trans_alloc_id >
    b.trans_alloc_profile_attributes!.trans_alloc_id
  ) {
    return 1;
  }

  return 0;
};

// exported for testing
export const checkForBadTransAllocIds = (
  transAllocProfiles: IETagPathTransmissionAllocationProfile[],
) => {
  let hasBadIds: boolean = false;

  // we sort here because this method shouldn't rely on a sorted data set
  // We do pass a sorted data set to this method where it's used, because
  // We also need it sorted in the fixOutOfOrderTransAllocIds method,
  // so this sort will usually only take a small amount of time, O(n).
  const sortedTransAllocProfiles = [...transAllocProfiles].sort(
    pathTransmissionAllocationProfileByPhysicalSegmentSorter,
  );
  sortedTransAllocProfiles.forEach((transAllocProfile, index) => {
    if (index < sortedTransAllocProfiles.length - 1) {
      if (
        transAllocProfile.trans_alloc_profile_attributes &&
        sortedTransAllocProfiles[index + 1] &&
        sortedTransAllocProfiles[index + 1].trans_alloc_profile_attributes
      ) {
        if (
          transAllocProfile.trans_alloc_profile_attributes.trans_alloc_id >
            sortedTransAllocProfiles[index + 1].trans_alloc_profile_attributes!
              .trans_alloc_id &&
          transAllocProfile.trans_alloc_profile_attributes
            .physical_segment_ref !==
            sortedTransAllocProfiles[index + 1].trans_alloc_profile_attributes!
              .physical_segment_ref
        ) {
          hasBadIds = true;
        }
      }
    }
  });
  return hasBadIds;
};

// exported for testing
export const fixOutOfOrderTransAllocIds = (
  profile: IETagPathProfile | null,
): IETagPathProfile | null => {
  if (profile === null || profile.trans_alloc_profiles === null) {
    return profile;
  }

  // We sort the transmission allocations by physical segment id
  // and then compare each transAllocId consecutively with the next
  // If we find any out of order, we set a flag
  // If that flag is false, just return the original
  // If that flag is true, we fix the transAllocIds starting at 1
  const sortedTransAllocProfiles: IETagPathTransmissionAllocationProfile[] = [
    ...profile.trans_alloc_profiles,
  ].sort(pathTransmissionAllocationProfileByPhysicalSegmentSorter);
  let hasBadIds: boolean = checkForBadTransAllocIds(sortedTransAllocProfiles);

  if (hasBadIds === false) {
    return profile;
  }

  const fixedTransAllocProfiles: IETagPathTransmissionAllocationProfile[] = [];
  sortedTransAllocProfiles.forEach((transAllocProfile, index) => {
    // If transAllocProfileAttributes is null, we don't need to change anything
    if (!transAllocProfile.trans_alloc_profile_attributes) {
      fixedTransAllocProfiles.push({ ...transAllocProfile });
    } else {
      fixedTransAllocProfiles.push({
        ...transAllocProfile,
        trans_alloc_profile_attributes: {
          ...transAllocProfile.trans_alloc_profile_attributes,
          trans_alloc_id: index + 1,
        },
      });
    }
  });

  return { ...profile, trans_alloc_profiles: fixedTransAllocProfiles };
};

const shouldUseUniqueProfiles = (
  path_profile: IETagPathProfile | null,
): boolean =>
  path_profile !== null &&
  path_profile.energy_profiles !== null &&
  path_profile.energy_profiles.length > 1;

export const retrieveAndTransformETagDraftSummary =
  (
    toEntity: TToEntityId,
    draftId: TETagDraftId,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const response: AxiosResponse = await retrieveETagDraft(toEntity, draftId);
    const eTagDraftResponse: IETagDraftResponse = response.data;

    if (!isSuccessStatus(response.status)) {
      if (response.status === NOT_FOUND_STATUS_CODE) {
        throw new NotFoundError(
          `ETag Draft Summary Not Found: ${eTagDraftResponse.errorMessage}`,
        );
      }

      throw new Error(eTagDraftResponse.errorMessage!);
    }

    const responseData = response.data.response;
    let isMarketInfoNeeded = false;

    if (responseData.path && responseData.path) {
      if (responseData.path.market_segments) {
        const market_segments = responseData.path.market_segments;
        const hasSWPPOnMarketSegment = market_segments.findIndex(
          (segment: IDetailMarketSegment) =>
            segment.pse?.entity_code === 'SWPP',
        );
        const hasMISOOnMarketSegment = market_segments.findIndex(
          (segment: IDetailMarketSegment) =>
            segment.pse?.entity_code === 'MISO',
        );
        isMarketInfoNeeded =
          hasSWPPOnMarketSegment > 0 || hasMISOOnMarketSegment > 0;
      }

      if (!isMarketInfoNeeded) {
        if (
          responseData.path.physical_segments &&
          responseData.path.physical_segments.transmission_physical_segments
        ) {
          const tp_segments =
            responseData.path.physical_segments.transmission_physical_segments;
          const hasSWPPOnPhysicalSegment = tp_segments.findIndex(
            (segment: IETagTransmissionPhysicalSegment) =>
              segment.tp_code?.entity_code === 'SWPP',
          );
          const hasMISOOnPhysicalSegment = tp_segments.findIndex(
            (segment: IETagTransmissionPhysicalSegment) =>
              segment.tp_code?.entity_code === 'MISO',
          );
          isMarketInfoNeeded =
            hasSWPPOnPhysicalSegment > 0 || hasMISOOnPhysicalSegment > 0;
        }
      }
    }

    const {
      default_range,
      draft_id,
      path,
      path_profile,
      resolved,
      security_key,
      tag_id,
      tag_meta_attribute,
    } = eTagDraftResponse.response;

    let splitPathProfile: IETagPathProfile | null;
    let dontMatch: boolean = false;
    if (
      // check if this is a new draft with time 00:00 -> 00:00 next day and no profile
      default_range && // default range should exist
      default_range.start && // should be a time string
      default_range.stop && // should be a time string
      ZonedDateTime.parseIso(default_range.start, 'UTC') // start is 24 hours before stop
        .add(1, 'day')
        .isSame(ZonedDateTime.parseIso(default_range.stop, 'UTC')) &&
      (path_profile === null ||
        path_profile?.energy_profiles === null ||
        (path_profile?.energy_profiles.length ?? 2) <= 1) // if length is undefined, evaluate it to 2 so it fails
    ) {
      if (
        path_profile === null ||
        path_profile?.energy_profiles === null || // energy profiles are null
        path_profile.energy_profiles.length === 0 || // energy profiles are empty
        (path_profile.energy_profiles.length === 1 && // there is one energy profile
          path_profile.energy_profiles[0].energy_profile_details?.length === 0) // with details length zero
      ) {
        // if nothing in the energy profiles, we can split
        splitPathProfile = splitRangeToPathProfile(default_range, path_profile);
        dontMatch = true;
      } else {
        splitPathProfile = path_profile;
      }
    } else {
      splitPathProfile = path_profile;
    }

    const newProfile: IETagPathProfile | null = dontMatch
      ? splitPathProfile
      : matchProfiles(splitPathProfile);

    // Some tags in e-Tag 1.0 have transAllocIds that are out of order relative to physical segment profiles,
    // and e-Tag+ doesn't allow this.
    const fixedIdsProfile = fixOutOfOrderTransAllocIds(newProfile);

    const {
      generationPhysicalSegment,
      loadPhysicalSegment,
      physicalSegmentsProfiles,
      transmissionAllocations,
    } = transformETagPathEnergyProfile(fixedIdsProfile, path);

    const pp = responseData.physical_segment_loss_percentages;

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      cc_list: tag_meta_attribute === null ? null : tag_meta_attribute.cc_list,
      composite_state:
        tag_meta_attribute === null ? null : tag_meta_attribute.composite_state,
      contact_info:
        tag_meta_attribute === null || tag_meta_attribute.contact_info === null
          ? null
          : {
              ...tag_meta_attribute.contact_info,
              key: getEditInfoKey(
                EDIT_SUMMARY_INFORMATION_CONTACT_INFO_LABEL,
                0,
                0,
              ),
            },
      draft_id,
      draftIsResolved: resolved,
      end_date: default_range === null ? null : default_range.stop,
      generationPhysicalSegment,
      loadPhysicalSegment,
      lossAccountings:
        path === null || path.loss_accountings === null
          ? []
          : transformETagLossAccountingsToDetailLossAccountings(
              path.loss_accountings,
            ),
      isMarketInfoNeeded,
      marketSegments:
        path === null || path.market_segments === null
          ? []
          : path.market_segments.map(transformETagMarketSegment),
      notes: tag_meta_attribute === null ? null : tag_meta_attribute.notes,
      physicalSegmentsProfiles,
      security_key,
      start_date: default_range === null ? null : default_range.start,
      tag_id:
        tag_id === null
          ? null
          : {
              ...copyETagTagId(tag_id),
              key: getEditInfoKey(EDIT_ETAG_TAG_ID_LABEL, 0, 0),
            },
      test_flag:
        tag_meta_attribute === null ? null : tag_meta_attribute.test_flag,
      transaction_type:
        tag_meta_attribute === null
          ? null
          : tag_meta_attribute.transaction_type,
      transmissionAllocations,
      transmission_physical_segments:
        path === null ||
        path.physical_segments === null ||
        path.physical_segments.transmission_physical_segments === null
          ? null
          : path.physical_segments.transmission_physical_segments.map(
              initialiseTransmissionPhysicalSegment,
            ),
      useUniqueProfiles: shouldUseUniqueProfiles(path_profile),
      physical_segment_loss_percentages: pp,
      show_losses: responseData.show_losses,
    });
  };

export const retrieveAndTransformETagTemplate =
  (
    toEntity: TToEntityId,
    templateId: TETagTemplateId,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const response: AxiosResponse = await retrieveETagTemplate(
      toEntity,
      templateId,
    );

    const eTagTemplateResponse: IETagTemplateResponse = response.data;

    if (!isSuccessStatus(response.status)) {
      throw new Error(eTagTemplateResponse.errorMessage!);
    }

    const {
      active,
      creator,
      description,
      group_name,
      id,
      last_update_time,
      last_update_user,
      name,
      tag,
    } = eTagTemplateResponse.response;
    let message_meta_data: IETagTemplateMessageMetaData | null = null;
    let path: IETagPath | null = null;
    let path_profile: IETagPathProfile | null = null;
    let security_key: string | null = null;
    let tag_id: IETagTagId | null = null;
    let tag_meta_attribute: IETagTagMetaAttribute | null = null;
    let physical_segment_loss_percentages: Record<
      number,
      string | number | null | undefined
    > | null;
    let show_losses: boolean | null;

    if (tag !== null) {
      message_meta_data = tag.message_meta_data;
      path = tag.path;
      path_profile = tag.path_profile;
      security_key = tag.security_key;
      tag_id = tag.tag_id;
      tag_meta_attribute = tag.tag_meta_attribute;
      physical_segment_loss_percentages = tag.physical_segment_loss_percentages;
      show_losses = tag.show_losses;
    }

    const newProfile: IETagPathProfile | null = matchProfiles(path_profile);

    const {
      generationPhysicalSegment,
      loadPhysicalSegment,
      physicalSegmentsProfiles,
      transmissionAllocations,
    } = transformETagPathEnergyProfile(newProfile, path);

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      active,
      cc_list: tag_meta_attribute === null ? null : tag_meta_attribute.cc_list,
      composite_state:
        tag_meta_attribute === null ? null : tag_meta_attribute.composite_state,
      contact_info:
        tag_meta_attribute === null || tag_meta_attribute.contact_info === null
          ? null
          : {
              ...tag_meta_attribute.contact_info,
              key: getEditInfoKey(
                EDIT_SUMMARY_INFORMATION_CONTACT_INFO_LABEL,
                0,
                0,
              ),
            },
      creator,
      description,
      generationPhysicalSegment,
      group_name,
      last_update_time,
      last_update_user,
      loadPhysicalSegment,
      lossAccountings:
        path === null || path.loss_accountings === null
          ? []
          : transformETagLossAccountingsToDetailLossAccountings(
              path.loss_accountings,
            ),
      marketSegments:
        path === null || path.market_segments === null
          ? []
          : path.market_segments.map(transformETagMarketSegment),
      message_meta_data,
      name,
      notes: tag_meta_attribute === null ? null : tag_meta_attribute.notes,
      physicalSegmentsProfiles,
      security_key,
      tag_id:
        tag_id === null
          ? null
          : {
              ...copyETagTagId(tag_id),
              key: getEditInfoKey(EDIT_ETAG_TAG_ID_LABEL, 0, 0),
            },
      template_id: id,
      test_flag:
        tag_meta_attribute === null ? null : tag_meta_attribute.test_flag,
      transaction_type:
        tag_meta_attribute === null
          ? null
          : tag_meta_attribute.transaction_type,
      transmissionAllocations,
      transmission_physical_segments:
        path === null ||
        path.physical_segments === null ||
        path.physical_segments.transmission_physical_segments === null
          ? null
          : path.physical_segments.transmission_physical_segments.map(
              initialiseTransmissionPhysicalSegment,
            ),
      useUniqueProfiles: shouldUseUniqueProfiles(path_profile),
      physical_segment_loss_percentages: physical_segment_loss_percentages,
      show_losses,
    });
  };

const transformTagId = (tag_id: IETagTagId): IETagTagId => ({
  gca:
    tag_id.gca === null
      ? null
      : { ...tag_id.gca, entity_type: EEntityType.GCA },
  key: tag_id.key,
  lca:
    tag_id.lca === null
      ? null
      : { ...tag_id.lca, entity_type: EEntityType.LCA },
  pse: tag_id.pse === null ? null : { ...tag_id.pse },
  tag_code: tag_id.tag_code,
  tag_primary_key: tag_id.tag_primary_key,
  ui_tag_id: tag_id.ui_tag_id,
});

const transformDetailLossAccountingToETagLossMethodEntry = (
  detailLossAccounting: IDetailLossAccounting,
  isCorrectionOrETagDraft: boolean,
): IETagLossMethodEntry | null => {
  const { lossMethod, physical_segment_ref, request_ref, start, stop } =
    detailLossAccounting;
  if (
    (lossMethod === null ||
      (lossMethod.contractNumbers === null &&
        lossMethod.loss_method_entry_type === null &&
        lossMethod.tag_ids === null)) &&
    physical_segment_ref === null &&
    request_ref === null &&
    start === null &&
    stop === null
  ) {
    return null;
  }

  let contract_numbers: string[] | null = null;

  if (
    lossMethod !== null &&
    lossMethod.contractNumbers !== null &&
    lossMethod.loss_method_entry_type === ELossMethodEntryType.Internal &&
    (lossMethod.tag_ids === null || lossMethod.tag_ids.length === 0)
  ) {
    contract_numbers = lossMethod.contractNumbers
      .filter(
        (contract: IContract): boolean => !isEmptyValue(contract.contract),
      )
      .map((contract: IContract): string => contract.contract!);

    if (contract_numbers.length === 0) {
      contract_numbers = null;
    }
  }

  return {
    loss_method: {
      contract_numbers,
      loss_method_entry_type:
        lossMethod === null ? null : lossMethod.loss_method_entry_type,
      tag_ids:
        lossMethod === null ||
        lossMethod.tag_ids === null ||
        !(
          lossMethod.loss_method_entry_type === ELossMethodEntryType.External ||
          lossMethod.loss_method_entry_type === ELossMethodEntryType.Internal
        )
          ? null
          : lossMethod.tag_ids.map(transformTagId),
    },
    request_ref: isCorrectionOrETagDraft ? 0 : request_ref,
    start,
    stop,
  };
};

const detailLossAccountingSorter =
  (timeZone: TTimeZone) =>
  (a: IDetailLossAccounting, b: IDetailLossAccounting): number => {
    if (a.physical_segment_ref === null && b.physical_segment_ref === null) {
      return 0;
    } else if (
      a.physical_segment_ref === null &&
      b.physical_segment_ref !== null
    ) {
      return -1;
    } else if (
      a.physical_segment_ref !== null &&
      b.physical_segment_ref === null
    ) {
      return 1;
    } else if (
      a.physical_segment_ref !== null &&
      b.physical_segment_ref !== null
    ) {
      if (a.physical_segment_ref < b.physical_segment_ref) {
        return -1;
      } else if (a.physical_segment_ref > b.physical_segment_ref) {
        return 1;
      } else {
        if (a.start === null && b.start === null) {
          return 0;
        } else if (a.start === null && b.start !== null) {
          return 1;
        } else if (a.start !== null && b.start === null) {
          return -1;
        } else if (a.start !== null && b.start !== null) {
          const start: ZonedDateTime = ZonedDateTime.parseIso(
            a.start,
            timeZone,
          );
          if (start.isBefore(ZonedDateTime.parseIso(b.start, timeZone))) {
            return -1;
          } else if (start.isAfter(ZonedDateTime.parseIso(b.start, timeZone))) {
            return 1;
          }
        }
      }
    }

    return 0;
  };

const eTagLossAccountingSorter = (
  a: IETagLossAccounting,
  b: IETagLossAccounting,
): number => {
  if (a.physical_segment_ref === null && b.physical_segment_ref === null) {
    return 0;
  } else if (
    a.physical_segment_ref === null &&
    b.physical_segment_ref !== null
  ) {
    return 1;
  } else if (
    a.physical_segment_ref !== null &&
    b.physical_segment_ref === null
  ) {
    return -1;
  }

  return 0;
};

export const transformDetailLossAccountingsToETagLossAccountings = (
  detailLossAccountings: IDetailLossAccounting[],
  isCorrectionOrETagDraft: boolean,
  timeZone: TTimeZone,
): IETagLossAccounting[] => {
  const sortedDetailLossAccountings: IDetailLossAccounting[] = [
    ...detailLossAccountings,
  ].sort(detailLossAccountingSorter(timeZone));
  const transform = (
    detailLossAccounting: IDetailLossAccounting,
  ): IETagLossMethodEntry | null =>
    transformDetailLossAccountingToETagLossMethodEntry(
      detailLossAccounting,
      isCorrectionOrETagDraft,
    );
  const eTagLossAccountings: IETagLossAccounting[] = [];
  const eTagLossAccountingsMap: TMap<number, IDetailLossAccounting[]> = {};

  sortedDetailLossAccountings.forEach(
    (detailLossAccounting: IDetailLossAccounting) => {
      const { physical_segment_ref } = detailLossAccounting;
      if (physical_segment_ref === null) {
        const eTagLossMethodEntry: IETagLossMethodEntry | null =
          transform(detailLossAccounting);

        eTagLossAccountings.push({
          loss_method_entries:
            eTagLossMethodEntry === null ? null : [eTagLossMethodEntry],
          physical_segment_ref: null,
        });
      } else {
        let mappedDetailLossAccountings: IDetailLossAccounting[] | undefined =
          eTagLossAccountingsMap[physical_segment_ref];

        if (mappedDetailLossAccountings === undefined) {
          mappedDetailLossAccountings = [];

          eTagLossAccountingsMap[physical_segment_ref] =
            mappedDetailLossAccountings;
        }

        mappedDetailLossAccountings.push(detailLossAccounting);
      }
    },
  );

  for (let physicalSegmentRef in eTagLossAccountingsMap) {
    eTagLossAccountings.push({
      loss_method_entries: eTagLossAccountingsMap[physicalSegmentRef]!.map(
        transform,
      ).filter(
        (eTagLossMethodEntry: IETagLossMethodEntry | null): boolean =>
          eTagLossMethodEntry !== null,
      ) as IETagLossMethodEntry[],
      physical_segment_ref: parseInt(physicalSegmentRef, 10),
    });
  }

  eTagLossAccountings.sort(eTagLossAccountingSorter);

  return eTagLossAccountings;
};

const createTransmissionAllocationProfilesMap = (
  transmissionAllocations: IETagTransmissionAllocation[] | null,
): TMap<number, IETagPathTransmissionAllocationProfile> => {
  const transmissionAllocationProfilesMap: TMap<
    number,
    IETagPathTransmissionAllocationProfile
  > = {};

  if (transmissionAllocations !== null) {
    transmissionAllocations.forEach(
      (eTagTransmissionAllocation: IETagTransmissionAllocation) => {
        const {
          contract_number,
          misc_infos,
          nits_resource,
          physical_segment_ref,
          trans_alloc_customer_code,
          trans_alloc_id,
          trans_product_ref,
        } = eTagTransmissionAllocation;
        let eTagPathTransmissionAllocationProfile:
          | IETagPathTransmissionAllocationProfile
          | undefined = transmissionAllocationProfilesMap[trans_alloc_id];

        if (eTagPathTransmissionAllocationProfile === undefined) {
          eTagPathTransmissionAllocationProfile = {
            trans_alloc_profile_attributes: null,
            trans_alloc_profile_details: null,
          };
          transmissionAllocationProfilesMap[trans_alloc_id] =
            eTagPathTransmissionAllocationProfile;
        }

        eTagPathTransmissionAllocationProfile.trans_alloc_profile_attributes = {
          contract_number,
          misc_infos:
            misc_infos === null
              ? null
              : misc_infos.map(
                  (miscInfo: IMiscInfo): IMiscInfo => ({
                    ...miscInfo,
                  }),
                ),
          nits_resource: isEmptyValue(nits_resource) ? null : nits_resource,
          physical_segment_ref,
          trans_alloc_customer_code:
            trans_alloc_customer_code === null
              ? null
              : { ...trans_alloc_customer_code },
          trans_alloc_id,
          trans_product_ref:
            trans_product_ref === null ? null : { ...trans_product_ref },
        };
      },
    );
  }

  return transmissionAllocationProfilesMap;
};

const updateEnergyProfileDetailsMap = (
  energyProfile: IEnergyProfile | null,
  start: string | null,
  stop: string | null,
  energyProfileDetailsMap: TMap<number, IETagPathEnergyProfile>,
) => {
  if (energyProfile !== null) {
    const {
      market_level,
      mw,
      profile_id,
      reliability_limit,
      start_ramp_dur,
      stop_ramp_dur,
    } = energyProfile;
    let eTagPathEnergyProfile: IETagPathEnergyProfile | undefined =
      energyProfileDetailsMap[profile_id];

    if (eTagPathEnergyProfile === undefined) {
      eTagPathEnergyProfile = {
        energy_profile_details: null,
        profile_id,
      };

      energyProfileDetailsMap[profile_id] = eTagPathEnergyProfile;
    }

    if (eTagPathEnergyProfile.energy_profile_details === null) {
      eTagPathEnergyProfile.energy_profile_details = [];
    }

    const segmentKey: string = getSegmentKey(start, stop);
    const eTagEnergyProfileDetail: IETagEnergyProfileDetail | undefined =
      eTagPathEnergyProfile.energy_profile_details.find(
        (eTagEnergyProfileDetail: IETagEnergyProfileDetail): boolean =>
          getSegmentKey(
            eTagEnergyProfileDetail.start,
            eTagEnergyProfileDetail.stop,
          ) === segmentKey,
      );

    if (eTagEnergyProfileDetail === undefined) {
      eTagPathEnergyProfile.energy_profile_details.push({
        energy_profile_type:
          market_level !== null
            ? EEnergyProfileType.MarketLevel
            : reliability_limit !== null
            ? EEnergyProfileType.ReliabilityLimit
            : null,
        limit_clearing: null,
        mw:
          market_level !== null
            ? market_level
            : reliability_limit !== null
            ? reliability_limit
            : mw,
        start,
        stop,
        start_ramp_dur,
        stop_ramp_dur,
      });
    }
  }
};

const createEnergyProfileDetailsMap = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  transmissionAllocationProfilesMap: TMap<
    number,
    IETagPathTransmissionAllocationProfile
  >,
  useUniqueProfiles: boolean,
): TMap<number, IETagPathEnergyProfile> => {
  const energyProfileDetailsMap: TMap<number, IETagPathEnergyProfile> = {};

  if (
    physicalSegmentsProfiles !== null &&
    physicalSegmentsProfiles.length > 0
  ) {
    physicalSegmentsProfiles.forEach(
      (eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile) => {
        if (eTagPhysicalSegmentsProfile.physical_segments_profiles !== null) {
          const { physical_segments_profiles, start, stop } =
            eTagPhysicalSegmentsProfile;

          if (physical_segments_profiles.generation === null) {
            throw new Error(
              `Missing generation for eTagPhysicalSegmentsProfile with key: ${eTagPhysicalSegmentsProfile.key}`,
            );
          }

          updateEnergyProfileDetailsMap(
            physical_segments_profiles.generation.profile,
            start,
            stop,
            energyProfileDetailsMap,
          );

          if (transmissionPhysicalSegments !== null) {
            if (
              physical_segments_profiles.transmission === null ||
              physical_segments_profiles.transmission.transmission_segments ===
                null
            ) {
              transmissionPhysicalSegments.forEach(
                (
                  eTagTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
                ) => {
                  if (
                    eTagTransmissionPhysicalSegment.pod_profile_ref === null
                  ) {
                    throw new Error(
                      `Missing pod_profile_ref for eTagTransmissionPhysicalSegment with physical_segment_id: ${eTagTransmissionPhysicalSegment.physical_segment_id}`,
                    );
                  }

                  if (useUniqueProfiles) {
                    updateEnergyProfileDetailsMap(
                      getInitialEnergyProfile(
                        eTagTransmissionPhysicalSegment.physical_segment_id,
                      ),
                      start,
                      stop,
                      energyProfileDetailsMap,
                    );
                  }
                },
              );
            } else {
              const { transmission_segments } =
                physical_segments_profiles.transmission;

              transmission_segments.forEach(
                (eTagTransmissionSegment: IETagTransmissionSegment) => {
                  const { pod_energy_profile, trans_alloc_profiles } =
                    eTagTransmissionSegment;

                  if (useUniqueProfiles) {
                    updateEnergyProfileDetailsMap(
                      pod_energy_profile,
                      start,
                      stop,
                      energyProfileDetailsMap,
                    );
                  }

                  if (trans_alloc_profiles !== null) {
                    trans_alloc_profiles.forEach(
                      (
                        eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile,
                      ) => {
                        const { profile_mw, trans_alloc_id } =
                          eTagTransmissionAllocationProfile;

                        if (trans_alloc_id !== null && profile_mw !== null) {
                          let eTagPathTransmissionAllocationProfile:
                            | IETagPathTransmissionAllocationProfile
                            | undefined =
                            transmissionAllocationProfilesMap[trans_alloc_id];

                          if (
                            eTagPathTransmissionAllocationProfile === undefined
                          ) {
                            eTagPathTransmissionAllocationProfile = {
                              trans_alloc_profile_attributes: null,
                              trans_alloc_profile_details: null,
                            };
                            transmissionAllocationProfilesMap[trans_alloc_id] =
                              eTagPathTransmissionAllocationProfile;
                          }

                          if (
                            eTagPathTransmissionAllocationProfile.trans_alloc_profile_details ===
                            null
                          ) {
                            eTagPathTransmissionAllocationProfile.trans_alloc_profile_details =
                              [];
                          }

                          eTagPathTransmissionAllocationProfile.trans_alloc_profile_details!.push(
                            {
                              mw: profile_mw,
                              start,
                              stop,
                            },
                          );
                        }
                      },
                    );
                  }
                },
              );
            }
          }

          if (useUniqueProfiles && physical_segments_profiles.load !== null) {
            updateEnergyProfileDetailsMap(
              physical_segments_profiles.load.profile,
              start,
              stop,
              energyProfileDetailsMap,
            );
          }
        }
      },
    );
  }

  return energyProfileDetailsMap;
};

export const getETagPathProfile = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  useUniqueProfiles: boolean,
): IETagPathProfile | null => {
  if (physicalSegmentsProfiles === null && transmissionAllocations === null) {
    return null;
  }

  const transmissionAllocationProfilesMap: TMap<
    number,
    IETagPathTransmissionAllocationProfile
  > = createTransmissionAllocationProfilesMap(transmissionAllocations);
  const energyProfileDetailsMap: TMap<number, IETagPathEnergyProfile> =
    createEnergyProfileDetailsMap(
      physicalSegmentsProfiles,
      transmissionPhysicalSegments,
      transmissionAllocationProfilesMap,
      useUniqueProfiles,
    );
  const energyProfiles: IETagPathEnergyProfile[] = Object.values(
    energyProfileDetailsMap,
  ).filter(
    (value: IETagPathEnergyProfile | undefined): boolean => value !== undefined,
  ) as IETagPathEnergyProfile[];
  const transAllocProfiles: IETagPathTransmissionAllocationProfile[] =
    Object.values(transmissionAllocationProfilesMap).filter(
      (value: IETagPathTransmissionAllocationProfile | undefined): boolean =>
        value !== undefined,
    ) as IETagPathTransmissionAllocationProfile[];
  const energy_profiles: IETagPathEnergyProfile[] | null =
    energyProfiles.length === 0 ? null : energyProfiles;

  const trans_alloc_profiles: IETagPathTransmissionAllocationProfile[] | null =
    transAllocProfiles.length === 0 ? null : transAllocProfiles;

  if (energy_profiles === null && trans_alloc_profiles === null) {
    return null;
  }

  validateEnergyProfiles(energy_profiles, true);

  return {
    energy_profiles,
    trans_alloc_profiles,
  };
};

export const transformDetailMarketSegment = (
  detailMarketSegment: IDetailMarketSegment,
): IETagMarketSegment => ({
  contact_info:
    detailMarketSegment.contactInfos === null
      ? null
      : { ...detailMarketSegment.contactInfos[0] },
  contracts:
    detailMarketSegment.contracts === null
      ? null
      : detailMarketSegment.contracts
          .filter((contract: IContract): boolean => contract.contract !== null)
          .map((contract: IContract): string => contract.contract!),
  energy_product_ref: copyEnergyProductInfo(
    detailMarketSegment.energy_product_ref,
  ),
  market_segment_id: detailMarketSegment.market_segment_id,
  misc_infos:
    detailMarketSegment.misc_infos === null
      ? null
      : detailMarketSegment.misc_infos
          .filter(
            (miscInfo: IMiscInfo): boolean =>
              !(isEmptyValue(miscInfo.token) && isEmptyValue(miscInfo.value)),
          )
          .map((miscInfo: IMiscInfo): IMiscInfo => ({ ...miscInfo })),
  pse: detailMarketSegment.pse === null ? null : { ...detailMarketSegment.pse },
  pse_contact_info:
    detailMarketSegment.pse_contact_info === null
      ? null
      : { ...detailMarketSegment.pse_contact_info },
});

export const transformDetailGenerationPhysicalSegment = (
  detailGenerationPhysicalSegment: IDetailGenerationPhysicalSegment,
): IETagGenerationPhysicalSegment => ({
  contact_info:
    detailGenerationPhysicalSegment.contactInfos === null
      ? null
      : { ...detailGenerationPhysicalSegment.contactInfos[0] },
  contracts:
    detailGenerationPhysicalSegment.contracts === null
      ? null
      : detailGenerationPhysicalSegment.contracts
          .filter((contract: IContract): boolean => contract.contract !== null)
          .map((contract: IContract): string => contract.contract!),
  energy_product_ref: copyEnergyProductInfo(
    detailGenerationPhysicalSegment.energy_product_ref,
  ),
  gca:
    detailGenerationPhysicalSegment.gca === null
      ? null
      : { ...detailGenerationPhysicalSegment.gca },
  generation_source:
    detailGenerationPhysicalSegment.generation_source === null
      ? null
      : { ...detailGenerationPhysicalSegment.generation_source },
  market_segment_id: detailGenerationPhysicalSegment.market_segment_id,
  misc_infos:
    detailGenerationPhysicalSegment.misc_infos === null
      ? null
      : detailGenerationPhysicalSegment.misc_infos
          .filter(
            (miscInfo: IMiscInfo): boolean =>
              !(isEmptyValue(miscInfo.token) && isEmptyValue(miscInfo.value)),
          )
          .map((miscInfo: IMiscInfo): IMiscInfo => ({ ...miscInfo })),
  mo_code:
    detailGenerationPhysicalSegment.mo_code === null
      ? null
      : { ...detailGenerationPhysicalSegment.mo_code },
  physical_segment_id: detailGenerationPhysicalSegment.physical_segment_id,
  profile_ref: detailGenerationPhysicalSegment.profile_ref,
  pse:
    detailGenerationPhysicalSegment.pse === null
      ? null
      : { ...detailGenerationPhysicalSegment.pse },
});

export const transformDetailLoadPhysicalSegment = (
  detailLoadPhysicalSegment: IDetailLoadPhysicalSegment,
  useUniqueProfiles: boolean,
): IETagLoadPhysicalSegment => ({
  contact_info:
    detailLoadPhysicalSegment.contactInfos === null
      ? null
      : { ...detailLoadPhysicalSegment.contactInfos[0] },
  contracts:
    detailLoadPhysicalSegment.contracts === null
      ? null
      : detailLoadPhysicalSegment.contracts
          .filter((contract: IContract): boolean => contract.contract !== null)
          .map((contract: IContract): string => contract.contract!),
  energy_product_ref: copyEnergyProductInfo(
    detailLoadPhysicalSegment.energy_product_ref,
  ),
  lca:
    detailLoadPhysicalSegment.lca === null
      ? null
      : { ...detailLoadPhysicalSegment.lca },
  load_sink:
    detailLoadPhysicalSegment.load_sink === null
      ? null
      : { ...detailLoadPhysicalSegment.load_sink },
  market_segment_id: detailLoadPhysicalSegment.market_segment_id,
  misc_infos:
    detailLoadPhysicalSegment.misc_infos === null
      ? null
      : detailLoadPhysicalSegment.misc_infos
          .filter(
            (miscInfo: IMiscInfo): boolean =>
              !(isEmptyValue(miscInfo.token) && isEmptyValue(miscInfo.value)),
          )
          .map((miscInfo: IMiscInfo): IMiscInfo => ({ ...miscInfo })),
  mo_code:
    detailLoadPhysicalSegment.mo_code === null
      ? null
      : { ...detailLoadPhysicalSegment.mo_code },
  physical_segment_id: detailLoadPhysicalSegment.physical_segment_id,
  profile_ref: useUniqueProfiles ? detailLoadPhysicalSegment.profile_ref : 1,
  pse:
    detailLoadPhysicalSegment.pse === null
      ? null
      : { ...detailLoadPhysicalSegment.pse },
});

export const transformTransmissionPhysicalSegments = (
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[],
  useUniqueProfiles: boolean,
): IETagTransmissionPhysicalSegment[] =>
  transmissionPhysicalSegments.map(
    (
      transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
    ): IETagTransmissionPhysicalSegment => {
      const updatedSchedulingEntities: IEntityInfo[] | null =
        transmissionPhysicalSegment.scheduling_entities === null ||
        transmissionPhysicalSegment.scheduling_entities.length === 0
          ? null
          : transmissionPhysicalSegment.scheduling_entities;
      const updatedPod: IPointInfo | null = copyPointInfo(
        transmissionPhysicalSegment.pod,
      );
      const updatedPor: IPointInfo | null = copyPointInfo(
        transmissionPhysicalSegment.por,
      );

      if (updatedPod !== null) {
        updatedPod.point_type = EPointType.POD;
      }

      if (updatedPor !== null) {
        updatedPor.point_type = EPointType.POR;
      }

      return {
        ...copyTransmissionPhysicalSegment(transmissionPhysicalSegment),
        pod: updatedPod,
        pod_profile_ref: useUniqueProfiles
          ? transmissionPhysicalSegment.pod_profile_ref
          : 1,
        por: updatedPor,
        por_profile_ref: useUniqueProfiles
          ? transmissionPhysicalSegment.por_profile_ref
          : 1,
        scheduling_entities: updatedSchedulingEntities,
        tp_code: transmissionPhysicalSegment.tp_code,
      };
    },
  );

const getETagPath = (
  lossAccountings: IDetailLossAccounting[],
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  marketSegments: IDetailMarketSegment[] | null,
  transmission_physical_segments: IETagTransmissionPhysicalSegment[] | null,
  useUniqueProfiles: boolean,
  isCorrectionOrETagDraft: boolean,
  timeZone: TTimeZone,
): IETagPath | null => {
  if (
    generationPhysicalSegment !== null ||
    loadPhysicalSegment !== null ||
    lossAccountings.length > 0 ||
    marketSegments !== null ||
    transmission_physical_segments !== null
  ) {
    const loss_accountings: IETagLossAccounting[] | null =
      lossAccountings.length === 0
        ? null
        : transformDetailLossAccountingsToETagLossAccountings(
            lossAccountings,
            isCorrectionOrETagDraft,
            timeZone,
          );

    const market_segments: IETagMarketSegment[] | null =
      marketSegments === null || marketSegments.length === 0
        ? null
        : marketSegments.map(transformDetailMarketSegment);

    const physical_segments: IETagPhysicalSegment = {
      generation_physical_segment:
        generationPhysicalSegment === null
          ? null
          : transformDetailGenerationPhysicalSegment(generationPhysicalSegment),
      load_physical_segment:
        loadPhysicalSegment === null
          ? null
          : transformDetailLoadPhysicalSegment(
              loadPhysicalSegment,
              useUniqueProfiles,
            ),
      transmission_physical_segments:
        transmission_physical_segments === null ||
        transmission_physical_segments.length === 0
          ? null
          : transformTransmissionPhysicalSegments(
              transmission_physical_segments,
              useUniqueProfiles,
            ),
    };

    if (
      loss_accountings === null &&
      market_segments === null &&
      physical_segments.generation_physical_segment === null &&
      physical_segments.load_physical_segment === null &&
      physical_segments.transmission_physical_segments === null
    ) {
      return null;
    }

    return {
      loss_accountings,
      market_segments,
      physical_segments,
    };
  }

  return null;
};

export const buildETagDraft = (
  detailState: IDetailState,
  timeZone: TTimeZone,
): IETagDraft => {
  const {
    draft_id,
    cc_list,
    composite_state,
    contact_info,
    end_date,
    generationPhysicalSegment,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    notes,
    physicalSegmentsProfiles,
    security_key,
    start_date,
    tag_id,
    test_flag,
    toEntity,
    transaction_type,
    transmission_physical_segments,
    transmissionAllocations,
    useUniqueProfiles,
    physical_segment_loss_percentages,
    show_losses,
  } = detailState;

  checkToEntity(toEntity);

  const eTagDraft: IETagDraft = {
    default_range: null,
    draft_id,
    path: null,
    path_profile: null,
    security_key,
    tag_id: tag_id === null ? null : transformTagId(tag_id),
    tag_meta_attribute: null,
    physical_segment_loss_percentages: null,
    show_losses: null,
  };

  if (end_date !== null || start_date !== null) {
    eTagDraft.default_range = {
      stop: end_date,
      start: start_date,
    };
  }

  if (
    cc_list !== null ||
    composite_state !== null ||
    contact_info !== null ||
    notes !== null ||
    test_flag !== null ||
    transaction_type !== null
  ) {
    eTagDraft.tag_meta_attribute = {
      cc_list:
        cc_list === null || cc_list.length === 0
          ? null
          : cc_list.map(
              (entityInfo: IEntityInfo): IEntityInfo => ({ ...entityInfo }),
            ),
      composite_state,
      contact_info:
        contact_info === null ||
        (contact_info.contact === null &&
          contact_info.fax === null &&
          contact_info.phone === null)
          ? null
          : { ...contact_info },
      notes: isEmptyValue(notes) ? null : notes,
      test_flag,
      transaction_type,
    };
  }

  eTagDraft.path = getETagPath(
    lossAccountings,
    generationPhysicalSegment,
    loadPhysicalSegment,
    marketSegments,
    transmission_physical_segments,
    useUniqueProfiles,
    true,
    timeZone,
  );

  eTagDraft.path_profile = getETagPathProfile(
    physicalSegmentsProfiles,
    transmission_physical_segments,
    transmissionAllocations,
    useUniqueProfiles,
  );

  eTagDraft.physical_segment_loss_percentages =
    physical_segment_loss_percentages;
  eTagDraft.show_losses = show_losses;

  return eTagDraft;
};

export const transformAndUpdateETagDraft =
  (timeZone: TTimeZone) =>
  async (detailState: IDetailState): Promise<void> => {
    const { toEntity } = detailState;

    checkToEntity(toEntity);

    const eTagDraft: IETagDraft = buildETagDraft(detailState, timeZone);

    const response: AxiosResponse = await updateETagDraft(
      toEntity!.to_entity,
      eTagDraft,
    );

    const eTagDraftUpdateResponse: IETagDraftUpdateResponse = response.data;

    if (!isSuccessStatus(response.status)) {
      throw new Error(eTagDraftUpdateResponse.errorMessage!);
    }
  };

export const transformUpdateAndValidateETagDraft =
  (timeZone: TTimeZone) =>
  async (detailState: IDetailState): Promise<void> => {
    const { toEntity, draft_id } = detailState;

    checkToEntity(toEntity);

    if (draft_id == null) {
      throw new Error('Draft ID not defined');
    }

    const eTagDraft: IETagDraft = buildETagDraft(detailState, timeZone);

    const response: AxiosResponse = await saveAndValidateNewTag(
      toEntity!.to_entity,
      draft_id,
      timeZone,
      eTagDraft,
    );

    const eTagDraftUpdateResponse: IETagDraftUpdateResponse = response.data;

    if (!isSuccessStatus(response.status)) {
      throw new Error(eTagDraftUpdateResponse.errorMessage!);
    }
  };

export const transformUpdateAndSubmitETagDraft =
  (timeZone: TTimeZone, skipValidation: boolean) =>
  async (detailState: IDetailState): Promise<void> => {
    const { toEntity, draft_id } = detailState;

    checkToEntity(toEntity);

    if (draft_id == null) {
      throw new Error('Draft ID not defined');
    }

    let eTagDraft = buildETagDraft(detailState, timeZone);

    const response: AxiosResponse = await saveAndRequestNewTag(
      toEntity!.to_entity,
      skipValidation,
      draft_id,
      timeZone,
      eTagDraft,
    );

    const eTagDraftUpdateResponse: IETagDraftUpdateResponse = response.data;

    if (!isSuccessStatus(response.status)) {
      throw new Error(eTagDraftUpdateResponse.errorMessage!);
    }
  };

export const getETagTemplate = (
  active: boolean | null,
  cc_list: IEntityInfo[] | null,
  composite_state: ECompositeState | null,
  contact_info: IContactInfo | null,
  creator: string | null,
  description: string | null,
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  group_name: string | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  lossAccountings: IDetailLossAccounting[],
  marketSegments: IDetailMarketSegment[] | null,
  message_meta_data: IETagTemplateMessageMetaData | null,
  name: string | null,
  notes: string | null,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
  security_key: string | null,
  tag_id: IETagTagId | null,
  timeZone: TTimeZone,
  template_id: TETagTemplateId | undefined,
  test_flag: boolean | null,
  toEntity: IToEntity | null,
  transaction_type: ETransactionType | null,
  transmission_physical_segments: IETagTransmissionPhysicalSegment[] | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  useUniqueProfiles: boolean,
  physical_segment_loss_percentages: Record<
    number,
    string | number | null | undefined
  > | null,
  show_losses: boolean | null,
): IETagTemplate => {
  if (template_id === undefined) {
    throw new Error('Template failed due to missing template_id');
  }

  const eTagTemplate: IETagTemplate = {
    active,
    creator,
    description,
    group_name,
    id: template_id,
    last_update_time: null,
    last_update_user: null,
    name,
    tag: null,
    to_entity: toEntity,
  };

  eTagTemplate.tag = {
    message_meta_data,
    path: null,
    path_profile: null,
    security_key,
    tag_id: tag_id === null ? null : transformTagId(tag_id),
    tag_meta_attribute: null,
    physical_segment_loss_percentages: null,
    show_losses: null,
  };

  if (
    cc_list !== null ||
    composite_state !== null ||
    contact_info !== null ||
    notes !== null ||
    test_flag !== null ||
    transaction_type !== null
  ) {
    eTagTemplate.tag.tag_meta_attribute = {
      cc_list:
        cc_list === null || cc_list.length === 0
          ? null
          : cc_list.map(
              (entityInfo: IEntityInfo): IEntityInfo => ({ ...entityInfo }),
            ),
      composite_state,
      contact_info:
        contact_info === null ||
        (contact_info.contact === null &&
          contact_info.fax === null &&
          contact_info.phone === null)
          ? null
          : { ...contact_info },
      notes: isEmptyValue(notes) ? null : notes,
      test_flag,
      transaction_type,
    };
  }

  eTagTemplate.tag.path = getETagPath(
    lossAccountings,
    generationPhysicalSegment,
    loadPhysicalSegment,
    marketSegments,
    transmission_physical_segments,
    useUniqueProfiles,
    true,
    timeZone,
  );

  eTagTemplate.tag.path_profile = getETagPathProfile(
    physicalSegmentsProfiles,
    transmission_physical_segments,
    transmissionAllocations,
    useUniqueProfiles,
  );
  eTagTemplate.tag.physical_segment_loss_percentages =
    physical_segment_loss_percentages;
  eTagTemplate.tag.show_losses = show_losses;

  return eTagTemplate;
};

export const transformAndUpdateETagTemplate =
  (timeZone: TTimeZone) =>
  async (detailState: IDetailState): Promise<void> => {
    const {
      active,
      cc_list,
      composite_state,
      contact_info,
      creator,
      description,
      generationPhysicalSegment,
      group_name,
      loadPhysicalSegment,
      lossAccountings,
      marketSegments,
      message_meta_data,
      name,
      notes,
      physicalSegmentsProfiles,
      security_key,
      tag_id,
      template_id,
      test_flag,
      toEntity,
      transaction_type,
      transmission_physical_segments,
      transmissionAllocations,
      useUniqueProfiles,
      physical_segment_loss_percentages,
      show_losses,
    } = detailState;

    checkToEntity(toEntity);

    const eTagTemplate: IETagTemplate = getETagTemplate(
      active,
      cc_list,
      composite_state,
      contact_info,
      creator,
      description,
      generationPhysicalSegment,
      group_name,
      loadPhysicalSegment,
      lossAccountings,
      marketSegments,
      message_meta_data,
      name,
      notes,
      physicalSegmentsProfiles,
      security_key,
      tag_id,
      timeZone,
      template_id,
      test_flag,
      toEntity,
      transaction_type,
      transmission_physical_segments,
      transmissionAllocations,
      useUniqueProfiles,
      physical_segment_loss_percentages,
      show_losses,
    );

    const response: AxiosResponse = await updateETagTemplate(
      toEntity!.to_entity,
      eTagTemplate,
    );

    const eTagTemplateUpdateResponse: IETagTemplateUpdateResponse =
      response.data;

    if (!isSuccessStatus(response.status)) {
      throw new Error(eTagTemplateUpdateResponse.errorMessage!);
    }
  };

const isEnergyProfileEmpty = (energyProfile: IEnergyProfile | null): boolean =>
  energyProfile === null ||
  (energyProfile.market_level === null &&
    energyProfile.start_ramp_dur === null &&
    energyProfile.stop_ramp_dur === null);

const isTransmissionAllocationProfileEmpty = (
  transmissionAllocationProfile: IETagTransmissionAllocationProfile,
): boolean => transmissionAllocationProfile.profile_mw === null;

const isTransmissionSegmentEmpty = (
  transmissionSegment: IETagTransmissionSegment,
): boolean =>
  isEnergyProfileEmpty(transmissionSegment.pod_energy_profile) &&
  isEnergyProfileEmpty(transmissionSegment.por_energy_profile) &&
  (transmissionSegment.trans_alloc_profiles === null ||
    transmissionSegment.trans_alloc_profiles.every(
      isTransmissionAllocationProfileEmpty,
    ));

const isPhysicalSegmentsProfileEmpty = (
  physicalSegmentsProfile: IETagPhysicalSegmentsProfile,
): boolean =>
  isEmptyValue(physicalSegmentsProfile.start) &&
  isEmptyValue(physicalSegmentsProfile.stop) &&
  (physicalSegmentsProfile.physical_segments_profiles === null ||
    ((physicalSegmentsProfile.physical_segments_profiles.generation === null ||
      isEnergyProfileEmpty(
        physicalSegmentsProfile.physical_segments_profiles.generation.profile,
      )) &&
      (physicalSegmentsProfile.physical_segments_profiles.transmission ===
        null ||
        physicalSegmentsProfile.physical_segments_profiles.transmission
          .transmission_segments === null ||
        physicalSegmentsProfile.physical_segments_profiles.transmission.transmission_segments.every(
          isTransmissionSegmentEmpty,
        )) &&
      (physicalSegmentsProfile.physical_segments_profiles.load === null ||
        isEnergyProfileEmpty(
          physicalSegmentsProfile.physical_segments_profiles.load.profile,
        ))));

const physcialSegmentsProfileSorter = (
  a: IETagPhysicalSegmentsProfile,
  b: IETagPhysicalSegmentsProfile,
): number => stringSortWithEmpties(true)(a.start, b.start);

const cleanUpPhysicalSegmentsProfiles = (
  detailState: IDetailState,
): IDetailState => {
  const { physicalSegmentsProfiles, transmission_physical_segments } =
    detailState;
  const updatedDetailState: IDetailState = { ...detailState };
  let hasUpdatedDetailState: boolean = false;

  if (physicalSegmentsProfiles !== null) {
    updatedDetailState.physicalSegmentsProfiles =
      physicalSegmentsProfiles.filter(
        (physicalSegmentsProfile: IETagPhysicalSegmentsProfile): boolean =>
          !isPhysicalSegmentsProfileEmpty(physicalSegmentsProfile),
      );

    updatedDetailState.physicalSegmentsProfiles.sort(
      physcialSegmentsProfileSorter,
    );

    if (transmission_physical_segments) {
      transmission_physical_segments.map(
        (segment: IETagTransmissionPhysicalSegment) =>
          delete segment.physical_segment_loss_percentage,
      );
    }

    hasUpdatedDetailState = true;
  }

  return hasUpdatedDetailState ? updatedDetailState : detailState;
};

/*const cleanUpTransmissionPhysicalSegments = (
  detailState: IDetailState,
): IDetailState => {
  const { transmission_physical_segments } = detailState;
  const updatedDetailState: IDetailState = { ...detailState };
  let hasUpdatedDetailState: boolean = false;

  if (transmission_physical_segments) {
    transmission_physical_segments.map(
      (segment: IETagTransmissionPhysicalSegment) => {
        delete segment.physical_segment_loss_percentage;
      },
    );
  }

  return hasUpdatedDetailState ? updatedDetailState : detailState;
};*/

const clearProfileChanges = (detailState: IDetailState): IDetailState => ({
  ...detailState,
  profileChanges: [],
});

export const cleanUpDetailState = (): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    if (
      detailState.draft_id !== null ||
      detailState.template_id !== undefined
    ) {
      return cleanUpPhysicalSegmentsProfiles(detailState);
    }

    if (
      detailState.viewMode === EViewMode.EditETagAdjustment ||
      detailState.viewMode === EViewMode.EditETagAdjustmentWithATF
    ) {
      return clearProfileChanges(detailState);
    }

    return detailState;
  };
};

export const cleanUpDetailStateProfileChanges =
  (): TStateTransform<IDetailState> => {
    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      profileChanges: detailState.profileChanges.filter(
        (row: TProfileDataGridRow): boolean =>
          !Object.keys(row).every((key: string): boolean => {
            const cell: IProfileDataGridCell | null | undefined = row[key];

            if (cell === undefined) {
              throw new Error(
                `Invalid key: ${key} for row: ${JSON.stringify(row)}`,
              );
            }

            return cell === null || isEmptyValue(cell.value) || key === ID_KEY;
          }),
      ),
    });
  };

export const copyGenerationEnergyProfileToTransmissionSegmentsEnergyProfile = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfiles,
): IETagPhysicalSegmentsProfiles => {
  const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfiles = {
    ...physicalSegmentsProfiles,
  };

  if (
    updatedPhysicalSegmentsProfiles.generation !== null &&
    updatedPhysicalSegmentsProfiles.generation.profile !== null &&
    updatedPhysicalSegmentsProfiles.transmission !== null &&
    updatedPhysicalSegmentsProfiles.transmission.transmission_segments !== null
  ) {
    updatedPhysicalSegmentsProfiles.transmission = {
      ...updatedPhysicalSegmentsProfiles.transmission,
      transmission_segments:
        updatedPhysicalSegmentsProfiles.transmission.transmission_segments.map(
          (
            eTagTransmissionSegment: IETagTransmissionSegment,
          ): IETagTransmissionSegment => ({
            ...eTagTransmissionSegment,
            pod_energy_profile: copyEnergyProfile(
              updatedPhysicalSegmentsProfiles.generation!.profile,
            ),
            por_energy_profile: copyEnergyProfile(
              updatedPhysicalSegmentsProfiles.generation!.profile,
            ),
          }),
        ),
    };
  }

  return updatedPhysicalSegmentsProfiles;
};

export const applySameEnergyProfiles = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
): IETagPhysicalSegmentsProfile[] | null => {
  if (physicalSegmentsProfiles === null) {
    return null;
  }

  return physicalSegmentsProfiles.map(
    (
      eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile,
    ): IETagPhysicalSegmentsProfile => {
      if (
        eTagPhysicalSegmentsProfile.physical_segments_profiles !== null &&
        eTagPhysicalSegmentsProfile.physical_segments_profiles.generation !==
          null &&
        eTagPhysicalSegmentsProfile.physical_segments_profiles.generation
          .profile !== null &&
        eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission !==
          null &&
        eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission
          .transmission_segments !== null
      ) {
        return {
          ...eTagPhysicalSegmentsProfile,
          physical_segments_profiles: {
            ...eTagPhysicalSegmentsProfile.physical_segments_profiles,
            transmission: {
              ...eTagPhysicalSegmentsProfile.physical_segments_profiles
                .transmission,
              transmission_segments:
                eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission.transmission_segments.map(
                  (
                    transmissionSegment: IETagTransmissionSegment,
                  ): IETagTransmissionSegment => {
                    let updatedTransmissionSegment: IETagTransmissionSegment =
                      transmissionSegment;

                    if (transmissionSegment.physical_segment_id !== null) {
                      updatedTransmissionSegment = {
                        ...transmissionSegment,
                        pod_energy_profile: copyEnergyProfile(
                          eTagPhysicalSegmentsProfile.physical_segments_profiles!
                            .generation!.profile,
                        ),
                        por_energy_profile: copyEnergyProfile(
                          eTagPhysicalSegmentsProfile.physical_segments_profiles!
                            .generation!.profile,
                        ),
                      };

                      if (
                        updatedTransmissionSegment.pod_energy_profile !== null
                      ) {
                        updatedTransmissionSegment.pod_energy_profile.profile_id =
                          getPODProfileRef(
                            transmissionSegment.physical_segment_id,
                          );
                      }

                      if (
                        updatedTransmissionSegment.por_energy_profile !== null
                      ) {
                        updatedTransmissionSegment.por_energy_profile.profile_id =
                          getPORProfileRef(
                            transmissionSegment.physical_segment_id,
                          );
                      }
                    }

                    return updatedTransmissionSegment;
                  },
                ),
            },
          },
        };
      }

      return eTagPhysicalSegmentsProfile;
    },
  );
};

export const getInitialCustomInterval = (): ICustomInterval => ({
  genRequest: null,
  time: null,
});

export const getStartStopDateTimesForAdHocProfile = (
  adHocProfile: IAdHocProfile,
) => {
  const {
    customIntervals,
    fixedDuration,
    startDateTime,
    stopDateTime,
    timeSplit,
    timeUnit,
  } = adHocProfile;

  if (stopDateTime.isBefore(startDateTime)) {
    throw new Error('Invalid stopDateTime, stopDateTime is before startDate');
  }

  const genValues: (number | null)[] = [];
  const startStopDateTimes: ZonedDateTime[] = [];

  if (timeSplit === EProfileTimeSplit.CustomInterval) {
    if (customIntervals.length === 0) {
      throw new Error('Invalid Custom Intervals');
    }

    const adjustedStopDate: ZonedDateTime = stopDateTime.startOf('minute');
    let dateTimeIndex: ZonedDateTime = startDateTime.startOf('minute');
    let previousDateTime: ZonedDateTime = dateTimeIndex;

    while (dateTimeIndex.isBefore(adjustedStopDate)) {
      for (let index = 0; index < customIntervals.length; index++) {
        const customInterval: ICustomInterval = customIntervals[index];

        if (customInterval.time === null) {
          throw new Error('Invalid currentCustomInterval.time');
        }

        let currentDateTime: ZonedDateTime = dateTimeIndex
          .withHour(customInterval.time.getHour())
          .withMinute(customInterval.time.getMinute());

        if (dateTimeIndex.isAfter(currentDateTime)) {
          currentDateTime = currentDateTime.add(1, 'day');
          dateTimeIndex = dateTimeIndex.add(1, 'day');
        }

        if (!previousDateTime.isSame(currentDateTime)) {
          startStopDateTimes.push(previousDateTime);
          startStopDateTimes.push(
            currentDateTime.isAfter(adjustedStopDate)
              ? adjustedStopDate
              : currentDateTime,
          );

          genValues.push(customInterval.genRequest);
        }

        previousDateTime = currentDateTime;
      }

      dateTimeIndex = dateTimeIndex.add(1, 'day');
    }

    // Add the last segment, if needed.
    if (previousDateTime.isBefore(adjustedStopDate)) {
      startStopDateTimes.push(previousDateTime);
      startStopDateTimes.push(adjustedStopDate);
      genValues.push(customIntervals[customIntervals.length - 1].genRequest);
    }
  } else {
    let timeStepInMinutes: number = 0;

    switch (timeSplit) {
      case EProfileTimeSplit.Daily: {
        timeStepInMinutes = 24 * 60;
        break;
      }
      case EProfileTimeSplit.Fifteen: {
        timeStepInMinutes = 15;
        break;
      }
      case EProfileTimeSplit.Five: {
        timeStepInMinutes = 5;
        break;
      }
      case EProfileTimeSplit.FixedDuration: {
        if (fixedDuration === null || fixedDuration < 0) {
          throw new Error('Invalid fixed duration value');
        }

        if (timeUnit === undefined) {
          throw new Error('Missing time unit');
        }

        if (timeUnit === EProfileTimeUnit.Day) {
          timeStepInMinutes = fixedDuration * 24 * 60;
        } else if (timeUnit === EProfileTimeUnit.Hour) {
          timeStepInMinutes = fixedDuration * 60;
        } else if (timeUnit === EProfileTimeUnit.Minute) {
          timeStepInMinutes = fixedDuration;
        }

        break;
      }
      case EProfileTimeSplit.Hourly: {
        timeStepInMinutes = 60;
        break;
      }
      default: {
        break;
      }
    }

    const adjustedStopDateTime: ZonedDateTime = stopDateTime.startOf('minute');
    let dateTimeIndex: ZonedDateTime = startDateTime.startOf('minute');

    while (dateTimeIndex.isBefore(adjustedStopDateTime)) {
      const currentStartDateTime: ZonedDateTime = dateTimeIndex;

      dateTimeIndex = dateTimeIndex.add(timeStepInMinutes, 'minutes');

      const currentStopDateTime: ZonedDateTime = dateTimeIndex;

      if (currentStartDateTime.isSameOrBefore(adjustedStopDateTime)) {
        startStopDateTimes.push(currentStartDateTime);

        if (currentStopDateTime.isSameOrBefore(adjustedStopDateTime)) {
          startStopDateTimes.push(currentStopDateTime);
        } else {
          startStopDateTimes.push(adjustedStopDateTime);
        }
      }
    }
  }

  return {
    genValues,
    startStopDateTimes,
  };
};

export const isNumberSelectEmpty = (
  value: number | null | (number | null)[] | undefined,
): boolean => isEmptyValue(value);

export const getViewModeForTagPrimaryKey = async (
  tagPrimaryKey: TETagTagPrimaryKey,
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
): Promise<EViewMode> => {
  const response: AxiosResponse<IETagEditModeResponse> =
    await retrieveETagDistributedEditMode(toEntityId, tagPrimaryKey!, timeZone);
  const eTagEditModeResponse: IETagEditModeResponse = response.data;

  if (!isSuccessStatus(response.status)) {
    if (response.status === FORBIDDEN_STATUS_CODE) {
      throw new Error('You do not have permission to edit details.');
    }

    throw new Error(eTagEditModeResponse.errorMessage!);
  }

  if (eTagEditModeResponse.response.mode === EEditMode.Adjustment) {
    return eTagEditModeResponse.response.allowATF === true
      ? EViewMode.EditETagAdjustmentWithATF
      : EViewMode.EditETagAdjustment;
  } else if (eTagEditModeResponse.response.mode === EEditMode.Correction) {
    return EViewMode.EditETagCorrection;
  } else {
    throw new Error(
      `Invalid edit mode response: ${eTagEditModeResponse.response.mode}`,
    );
  }
};

export const detailIdTypeToDisplayString = (
  detailIdType: EDetailIdType,
): string => {
  switch (detailIdType) {
    case EDetailIdType.DraftId: {
      return 'ETag Draft';
    }
    case EDetailIdType.TagPrimaryKey: {
      return 'ETag';
    }
    case EDetailIdType.TemplateId: {
      return 'ETag Template';
    }
    default: {
      throw new Error(`Invalid EDetailIdType: ${detailIdType}`);
    }
  }
};

export const filterTemplateGroups = (
  inputValue: string,
  option: OptionData | OptionGroupData | undefined,
): boolean =>
  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

export const matchProfiles = (
  pathProfile: IETagPathProfile | null,
): IETagPathProfile | null => {
  const timeZone: TTimeZone = 'UTC';
  if (pathProfile === null) {
    return pathProfile;
  }

  const { energy_profiles, trans_alloc_profiles } = pathProfile;
  if (
    energy_profiles === null ||
    energy_profiles.length === 0 ||
    trans_alloc_profiles === null ||
    trans_alloc_profiles.length === 0
  ) {
    return pathProfile;
  }

  // extract the non-null energy profile details
  const energyProfileDetails: IETagEnergyProfileDetail[] = [];
  energy_profiles[0].energy_profile_details?.forEach(
    (profile: IETagEnergyProfileDetail) => {
      if (profile) {
        energyProfileDetails.push(profile);
      }
    },
  );

  // collect the intervals that the energy profiles span
  const energyIntervals: IInterval[] = [];
  if (energy_profiles[0].energy_profile_details) {
    energy_profiles[0].energy_profile_details.forEach(
      (profile: IETagEnergyProfileDetail) => {
        if (profile) {
          energyIntervals.push({
            start: ZonedDateTime.strictParse(profile.start, timeZone),
            stop: ZonedDateTime.strictParse(profile.stop, timeZone),
          });
        }
      },
    );
  }

  // extract the non-null trans alloc profile details
  const transAllocProfileDetails: IETagTransmissionAllocationProfileDetail[][] =
    [];
  trans_alloc_profiles.forEach(
    (profile: IETagPathTransmissionAllocationProfile) => {
      if (profile.trans_alloc_profile_details) {
        transAllocProfileDetails.push(profile.trans_alloc_profile_details);
      }
    },
  );

  // we include the original energy intervals in the beginning array
  // we will modify this in the following loop
  const newIntervals: IInterval[] = [...energyIntervals];
  trans_alloc_profiles.forEach(
    // looping through each of the profile details
    (profile: IETagPathTransmissionAllocationProfile) => {
      // and looking at each of the profiles in that detail
      if (profile.trans_alloc_profile_details) {
        const transAllocProfileDetails: IETagTransmissionAllocationProfileDetail[] =
          profile.trans_alloc_profile_details;
        transAllocProfileDetails.forEach(
          (segment: IETagTransmissionAllocationProfileDetail) => {
            // store the time interval of this profile
            const transmissionInterval: IInterval = {
              start: ZonedDateTime.strictParse(segment.start, timeZone),
              stop: ZonedDateTime.strictParse(segment.stop, timeZone),
            };
            // for every interval in our new intervals array
            for (let i = 0; i < newIntervals.length; i++) {
              // if the new interval is the same, do nothing, it is already in the array
              if (
                newIntervals[i].start.isSame(transmissionInterval.start) &&
                newIntervals[i].stop.isSame(transmissionInterval.stop)
              ) {
                // nothing
              }
              // if the transmission interval has the same start, but ends earlier
              // add a new interval with the latter part of the intersection
              // change the current interval to be the first part of the intersection
              if (
                newIntervals[i].start.isSame(transmissionInterval.start) &&
                transmissionInterval.stop.isBefore(newIntervals[i].stop)
              ) {
                newIntervals.push({
                  start: transmissionInterval.stop,
                  stop: newIntervals[i].stop,
                });
                newIntervals[i] = transmissionInterval;
                continue;
              }
              // if the transmission interval has the same stop, but begins after the interval
              // we add a new piece that is the latter part of the intersection
              // and update the original to be the first part of the intersection
              if (
                newIntervals[i].stop.isSame(transmissionInterval.stop) &&
                transmissionInterval.start.isAfter(newIntervals[i].start)
              ) {
                newIntervals.push({
                  start: newIntervals[i].start,
                  stop: transmissionInterval.start,
                });
                newIntervals[i] = transmissionInterval;
              }
              // if the transmission interval begins after the interval, but ends after
              // we only split the newEnergyInterval[i] rather than adding the remainder
              // of the transmission interval, as the next element in that
              // array will capture the remaining piece of this transmission interval
              // we update this interval to be the first part of the intersection
              // and add a piece to be the second part of the intersection
              if (
                newIntervals[i].start.isBefore(transmissionInterval.start) &&
                transmissionInterval.start.isBefore(newIntervals[i].stop) &&
                newIntervals[i].stop.isBefore(transmissionInterval.stop)
              ) {
                newIntervals.push({
                  start: transmissionInterval.start,
                  stop: newIntervals[i].stop,
                });
                newIntervals[i] = {
                  start: newIntervals[i].start,
                  stop: transmissionInterval.start,
                };
              }
              // similarly, if the transmission interval begins before the interval and ends after
              // we only split the newEnergyInterval[i] rather than adding the beginning
              // of the transmission interval, as that was covered by the previous newEnergyInterval in the array
              if (
                transmissionInterval.start.isBefore(newIntervals[i].start) &&
                newIntervals[i].start.isBefore(transmissionInterval.stop) &&
                transmissionInterval.stop.isBefore(newIntervals[i].stop)
              ) {
                newIntervals.push({
                  start: transmissionInterval.stop,
                  stop: newIntervals[i].stop,
                });
                newIntervals[i] = {
                  start: newIntervals[i].start,
                  stop: transmissionInterval.stop,
                };
              }
            }
          },
        );
      }
    },
  );

  // translate incoming energy profiles to ensure they're on the new intervals
  const newEnergyProfiles: IETagPathEnergyProfile[] = [];
  energy_profiles.forEach((energyProfile: IETagPathEnergyProfile) => {
    // extract the details from this profile
    const energyProfileDetails: IETagEnergyProfileDetail[] | null =
      energyProfile.energy_profile_details;
    // if our details or the attributes are null, return out of this function with null
    if (
      energyProfileDetails === null ||
      energyProfile.energy_profile_details === null
    ) {
      return null;
    }

    // map all of the details to details stored with intervals rather than just times as strings
    // we use strict parse in case the strings do happen to be null, since that is a possibility
    const intervalEnergyDetails: IETagZonedDateTimeEnergyProfileDetail[] =
      energyProfileDetails.map(
        (
          profile: IETagEnergyProfileDetail,
        ): IETagZonedDateTimeEnergyProfileDetail => ({
          interval: {
            start: ZonedDateTime.strictParse(profile.start, timeZone),
            stop: ZonedDateTime.strictParse(profile.stop, timeZone),
          },
          mw: profile.mw,
          energy_profile_type: profile.energy_profile_type,
          limit_clearing: profile.limit_clearing,
          start_ramp_dur: profile.start_ramp_dur,
          stop_ramp_dur: profile.stop_ramp_dur,
        }),
      );

    // we create a place to store the new details
    const newEnergyProfileDetails: IETagEnergyProfileDetail[] = [];
    // checking each energyInterval
    // for each of the energy details we extracted earlier
    intervalEnergyDetails.forEach(
      (intervalDetails: IETagZonedDateTimeEnergyProfileDetail) => {
        // check if the energy interval is on this interval
        newIntervals.forEach((newInterval: IInterval) => {
          if (
            newInterval.start.isSameOrAfter(intervalDetails.interval.start) &&
            newInterval.stop.isSameOrBefore(intervalDetails.interval.stop)
          ) {
            // if it is, push a new energy profile details that match our interval
            newEnergyProfileDetails.push({
              energy_profile_type: intervalDetails.energy_profile_type,
              mw: intervalDetails.mw,
              start: newInterval.start.toIsoString(),
              stop: newInterval.stop.toIsoString(),
              limit_clearing: intervalDetails.limit_clearing,
              start_ramp_dur: intervalDetails.start_ramp_dur,
              stop_ramp_dur: intervalDetails.stop_ramp_dur,
            });
          }
        });
      },
    );
    // reapply the profileId from this transmission profile (we only had one incoming profile, so we use the same Id)
    const newEnergyProfile: IETagPathEnergyProfile = {
      profile_id: energyProfile.profile_id,
      energy_profile_details: newEnergyProfileDetails,
    };
    // store it in our results array
    newEnergyProfiles.push(newEnergyProfile);
  });

  // construct new trans alloc profiles. We do the same operation for all transmission segments
  const newTransAllocProfiles: IETagPathTransmissionAllocationProfile[] = [];
  trans_alloc_profiles.forEach(
    (transAllocProfile: IETagPathTransmissionAllocationProfile) => {
      const { trans_alloc_profile_attributes, trans_alloc_profile_details } =
        transAllocProfile;

      if (trans_alloc_profile_attributes !== null) {
        if (trans_alloc_profile_details === null) {
          newTransAllocProfiles.push({
            trans_alloc_profile_attributes,
            trans_alloc_profile_details: null,
          });
        } else {
          // map all of the details to details stored with intervals rather than just times as strings
          // we use strict parse in case the strings do happen to be null, since that is a possiblilty
          const intervalTransmissionDetails: IETagZonedDateTimeTransmissionAllocationProfileDetail[] =
            trans_alloc_profile_details.map(
              (
                profile: IETagTransmissionAllocationProfileDetail,
              ): IETagZonedDateTimeTransmissionAllocationProfileDetail => ({
                interval: {
                  start: ZonedDateTime.strictParse(profile.start, timeZone),
                  stop: ZonedDateTime.strictParse(profile.stop, timeZone),
                },
                mw: profile.mw,
              }),
            );

          // we create a place to store the new details
          const newTransAllocProfileDetails: IETagTransmissionAllocationProfileDetail[] =
            [];
          // for each of the intervals we found above
          newIntervals.forEach((newInterval: IInterval) => {
            // for each of the transmission details we extracted earlier
            intervalTransmissionDetails.forEach(
              (
                intervalDetails: IETagZonedDateTimeTransmissionAllocationProfileDetail,
              ) => {
                // check if the energy interval is on this transmission interval
                if (
                  newInterval.start.isSameOrAfter(
                    intervalDetails.interval.start,
                  ) &&
                  newInterval.stop.isSameOrBefore(intervalDetails.interval.stop)
                ) {
                  // if it is, push a new transmission profile details that match our energy interval
                  newTransAllocProfileDetails.push({
                    mw: intervalDetails.mw,
                    start: newInterval.start.toIsoString(),
                    stop: newInterval.stop.toIsoString(),
                  });
                }
              },
            );
          });

          // store it in our results array
          // reapply the tran_alloc_attributes from this transmission profile
          newTransAllocProfiles.push({
            trans_alloc_profile_attributes,
            trans_alloc_profile_details: newTransAllocProfileDetails,
          });
        }
      }
    },
  );

  // put the new trans alloc profiles with the original energy profile and return
  const newProfile: IETagPathProfile = {
    energy_profiles: newEnergyProfiles,
    trans_alloc_profiles: newTransAllocProfiles,
  };
  return newProfile;
};

export const getInitialMarketData = (
  market: EMarketInfoMarket,
  marketDate: ZonedDateTime,
): TETagMarketInfoMarketData => {
  switch (market) {
    case EMarketInfoMarket.MISO: {
      const utcMarketDate: ZonedDateTime = marketDate.withTimeZone(
        'UTC',
        false,
      );
      return {
        key: getEditInfoKey(EDIT_MISO_MARKET_DATA_LABEL, 1, 0),
        miso_create_fin_schedule: null,
        miso_market_date:
          MISO_MARKET_DETAIL.dateTimeFormat === undefined
            ? utcMarketDate.isoFormat()
            : utcMarketDate.format(MISO_MARKET_DETAIL.dateTimeFormat),
        miso_market_type: null,
        miso_price_list: [],
        miso_transaction_type: null,
        enabled: false,
      };
    }
    case EMarketInfoMarket.SPP: {
      const utcMarketDate: ZonedDateTime = marketDate.withTimeZone(
        'UTC',
        false,
      );
      return {
        key: getEditInfoKey(EDIT_SPP_MARKET_DATA_LABEL, 1, 0),
        spp_market_date:
          SPP_MARKET_DETAIL.dateTimeFormat === undefined
            ? utcMarketDate.isoFormat()
            : utcMarketDate.format(SPP_MARKET_DETAIL.dateTimeFormat),
        spp_market_type: null,
        spp_price_list: [],
        spp_transaction_type: null,
        enabled: false,
      };
    }
    default: {
      throw new Error(`Invalid market: ${market}`);
    }
  }
};

export const getProfileInformationRowCellData = (
  cell: IProfileDataGridCell | null,
  viewMode: EViewMode,
  selectedRequestKey: string,
  start: string,
  stop: string,
  isUnconstrained: boolean,
  getBackgroundColour?: (
    energyProfile: IEnergyProfile,
    selectedRequestKey: string,
  ) => string | undefined,
) => {
  const columns: IViewDataTableColumn<ICurtailmentData>[] = [];
  const data: ICurtailmentData[] = [];
  let cellType: EProfileDataGridCellType =
    cell === null ? EProfileDataGridCellType.Empty : cell.type;
  let cellValue: number | string;
  let cellBackgroundColour: string | undefined = undefined;
  let additionalContent: ReactNode = null;

  if (cell === null) {
    cellValue = '';
  } else if (cellType === EProfileDataGridCellType.Number) {
    cellValue = cell.value as number;
  } else if (
    cellType === EProfileDataGridCellType.DateTimeString ||
    cellType === EProfileDataGridCellType.String
  ) {
    cellValue = cell.value as string;
  } else if (cellType === EProfileDataGridCellType.EnergyProfile) {
    const energyProfile: IEnergyProfile = cell.value as IEnergyProfile;

    cellValue = energyProfile.mw === null ? '' : energyProfile.mw;

    if (
      (viewMode === EViewMode.ReviewETag ||
        viewMode === EViewMode.EditETagAdjustment ||
        viewMode === EViewMode.EditETagAdjustmentWithATF) &&
      getBackgroundColour
    ) {
      const includeCurtailmentsAndReloads: boolean =
        selectedRequestKey === CURRENT_REQUEST_KEY ||
        selectedRequestKey === CURRENT_PENDING_REQUEST_KEY ||
        energyProfile.last_request_type === ERequestType.Curtailment ||
        energyProfile.last_request_type === ERequestType.Reload;
      cellBackgroundColour = getBackgroundColour(
        energyProfile,
        selectedRequestKey,
      );

      if (
        energyProfile.curtailments.length > 0 &&
        includeCurtailmentsAndReloads
      ) {
        columns.push(
          {
            dataIndex: 'code',
            render: getColumnRender(isUnconstrained),
            title: 'Tagging Entity',
          },
          {
            dataIndex: 'mw',
            render: getColumnRender(isUnconstrained),
            title: 'MW',
          },
        );

        energyProfile.curtailments.forEach((curtailment: ICurtailment) => {
          data.push({
            code: curtailment.entity.entity_code,
            mw: curtailment.mw,
          });
        });
      }

      if (energyProfile.reloaders.length > 0 && includeCurtailmentsAndReloads) {
        additionalContent = (
          <Card title={`Reloader(s) for:\n${start} - ${stop}`}>
            {energyProfile.reloaders
              .map((entityInfo: IEntityInfo): string => entityInfo.entity_code)
              .join(', ')}
          </Card>
        );
      }
    }
  } else {
    cellValue = '';
  }

  return {
    additionalContent,
    cellBackgroundColour,
    cellType,
    cellValue,
    columns,
    data,
  };
};

export const getInitialMarketInfoPricesRowForId = (
  id: string,
  initialMarketInfoPricesDataSet?: TMarketInfoPricesDataGridRow[],
): TMarketInfoPricesDataGridRow | undefined => {
  if (initialMarketInfoPricesDataSet !== undefined) {
    return initialMarketInfoPricesDataSet.find(
      (initialRow: TMarketInfoPricesDataGridRow): boolean => {
        const initialRowId: IMarketInfoPricesDataGridCell | null | undefined =
          initialRow[ID_KEY];

        if (initialRowId === undefined) {
          throw new Error(
            `Missing key: id for initialRow: ${JSON.stringify(initialRow)}`,
          );
        }

        return initialRowId !== null && id === initialRowId.value;
      },
    );
  }

  return undefined;
};

export const getMarketInfoPricesRowCellData = (
  cell: IMarketInfoPricesDataGridCell | null,
  isUnconstrained: boolean,
): IMarketInfoPricesRowCellData => {
  let cellType: EMarketInfoPricesDataGridCellType =
    cell === null ? EMarketInfoPricesDataGridCellType.Empty : cell.type;
  let cellValue: number | string;

  if (cell === null) {
    cellValue = '';
  } else if (
    cellType === EMarketInfoPricesDataGridCellType.Number ||
    cellType === EMarketInfoPricesDataGridCellType.String
  ) {
    // We still use string type even when it is a number since cell.value could
    // be a floating point value
    cellValue = cell.value as string;
  } else {
    cellValue = '';
  }

  return {
    cellType,
    cellValue,
  };
};

export const marketInfoPriceFilter = (
  eTagMarketInfoPrice: IETagMarketInfoPrice,
): boolean =>
  !(eTagMarketInfoPrice.mw === null && eTagMarketInfoPrice.price === null);

const updateMarketInfoDataKey = (
  eTagMarketInfo: IETagMarketInfo,
): IETagMarketInfo => {
  const { data, market_info_market } = eTagMarketInfo;

  switch (market_info_market) {
    case EMarketInfoMarket.MISO: {
      const updatedData: IETagMarketData = copyETagMisoMarketData(
        data as IETagMisoMarketData,
      );

      updatedData.key = getEditInfoKey(EDIT_MISO_MARKET_DATA_LABEL, 1, 0);

      return {
        data: updatedData as IETagMisoMarketData,
        market_info_market,
      };
    }
    case EMarketInfoMarket.SPP: {
      const updatedData: IETagMarketData = copyETagSppMarketData(
        data as IETagSppMarketData,
      );

      updatedData.key = getEditInfoKey(EDIT_SPP_MARKET_DATA_LABEL, 1, 0);

      return {
        data: updatedData as IETagSppMarketData,
        market_info_market,
      };
    }
    default: {
      throw new Error(`Invalid market_info_market: ${market_info_market}`);
    }
  }
};

const updateMarketInfoDataKeys = (
  marketInfos: IETagMarketInfo[],
): IETagMarketInfo[] => marketInfos.map(updateMarketInfoDataKey);

export const retrieveAndTransformTransmissionPriorityConfigurations =
  (
    toEntityId: TToEntityId,
    templateId?: TETagTemplateId,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    if (templateId) {
      const retrieveTransmissionPriorityConfigurationsResponse: AxiosResponse<IEtagTransmissionPriorityConfigurationResponse> =
        await retrieveTransmissionPrioritySegment(toEntityId, templateId);

      const parsedTransmissionPriorityConfigurations: any[] = [];
      const eTagTransmissionPriorityConfigurationsResponse: any =
        retrieveTransmissionPriorityConfigurationsResponse.data;

      if (
        !isSuccessStatus(
          retrieveTransmissionPriorityConfigurationsResponse.status,
        )
      ) {
        return (detailState: IDetailState): IDetailState => ({
          ...detailState,
          transmissionPriorityConfigurations:
            parsedTransmissionPriorityConfigurations,
        });
      }

      const transmissionPriorityConfigurations: IEtagTransmissionPriorityConfiguration[] =
        eTagTransmissionPriorityConfigurationsResponse.response.data_rows;

      transmissionPriorityConfigurations.map((config) => {
        config.key = `${config.pid}-${config.order}`;
        config.pid = config.pid ? parseInt(config.pid.toString(10), 10) : null;

        return config;
      });

      // The response needs to be parsed since it comes in a string format
      transmissionPriorityConfigurations.slice().forEach((config) => {
        const owner = config.owner
          ? JSON.parse(
              JSON.stringify(config.owner).replaceAll("'", '"').slice(1, -1),
            )
          : null;
        const tp = config.tp
          ? JSON.parse(
              JSON.stringify(config.tp).replaceAll("'", '"').slice(1, -1),
            )
          : null;
        const sink = config.sink
          ? JSON.parse(
              JSON.stringify(config.sink).replaceAll("'", '"').slice(1, -1),
            )
          : null;
        const source = config.source
          ? JSON.parse(
              JSON.stringify(config.source).replaceAll("'", '"').slice(1, -1),
            )
          : null;
        const por = config.por
          ? JSON.parse(
              JSON.stringify(config.por).replaceAll("'", '"').slice(1, -1),
            )
          : null;
        const pod = config.pod
          ? JSON.parse(
              JSON.stringify(config.pod).replaceAll("'", '"').slice(1, -1),
            )
          : null;
        parsedTransmissionPriorityConfigurations.push({
          key: `${config.pid}-${config.order}`,
          order: config.order,
          pid: config.pid,
          product: config.product,
          owner,
          tp,
          sink,
          source,
          por,
          pod,
        });
      });

      return (detailState: IDetailState): IDetailState => ({
        ...detailState,
        transmissionPriorityConfigurations:
          parsedTransmissionPriorityConfigurations,
      });
    } else {
      return (detailState: IDetailState): IDetailState => detailState;
    }
  };
export const retrieveAndTransformMarketInfos =
  (
    toEntityId: TToEntityId,
    draftId: TETagDraftId,
    tagPrimaryKey?: TETagTagPrimaryKey,
    templateId?: TETagTemplateId,
  ): TStateLoadTransform<IDetailState> =>
  async (): Promise<TStateTransform<IDetailState>> => {
    const retrieveETagMarketInfosResponse: AxiosResponse<IETagMarketInfosResponse> =
      await retrieveETagMarketInfos(
        toEntityId,
        draftId,
        tagPrimaryKey,
        templateId,
      );

    const eTagMarketInfosResponse: IETagMarketInfosResponse =
      retrieveETagMarketInfosResponse.data;

    if (!isSuccessStatus(retrieveETagMarketInfosResponse.status)) {
      // TODO: Determine if this is the correct approach if missing marketInfos
      if (retrieveETagMarketInfosResponse.status === NOT_FOUND_STATUS_CODE) {
        return (detailState: IDetailState): IDetailState => detailState;
      }

      throw new Error(eTagMarketInfosResponse.errorMessage!);
    }

    const marketInfos: IETagMarketInfo[] = eTagMarketInfosResponse.response;
    const updatedMarketInfos: IETagMarketInfo[] =
      updateMarketInfoDataKeys(marketInfos);

    return (detailState: IDetailState): IDetailState => ({
      ...detailState,
      marketInfos: updatedMarketInfos,
    });
  };

const isValidMisoMarketInfo = (misoData: IETagMisoMarketData): boolean =>
  misoData.miso_create_fin_schedule !== null ||
  misoData.miso_market_type !== null ||
  misoData.miso_transaction_type !== null ||
  misoData.miso_market_date !== null;

const isValidMisoTemplateMarketInfo = (
  misoData: IETagMisoMarketData,
): boolean =>
  !(
    misoData.miso_create_fin_schedule === null ||
    !misoData.miso_market_type ||
    !misoData.miso_transaction_type ||
    !misoData.miso_market_date
  );

const isValidSppMarketInfo = (sppData: IETagSppMarketData): boolean =>
  !(
    !sppData.spp_market_date ||
    !sppData.spp_market_type ||
    !sppData.spp_transaction_type
  );

const isValidSppTemplateMarketInfo = (sppData: IETagSppMarketData): boolean =>
  sppData.spp_market_date !== null ||
  sppData.spp_market_type !== null ||
  sppData.spp_transaction_type !== null;

const cleanUpMarketInfos = (
  marketInfos: IETagMarketInfo[],
  isTemplate: boolean,
): IETagMarketInfo[] => {
  return marketInfos
    .map((marketInfo: IETagMarketInfo): IETagMarketInfo | undefined => {
      const { data, market_info_market } = marketInfo;

      if (market_info_market === EMarketInfoMarket.MISO) {
        if (
          !data.enabled ||
          (isTemplate &&
            isValidMisoTemplateMarketInfo(data as IETagMisoMarketData)) ||
          isValidMisoMarketInfo(data as IETagMisoMarketData)
        ) {
          return {
            data: {
              ...data,
              miso_price_list: (
                data as IETagMisoMarketData
              ).miso_price_list.filter(marketInfoPriceFilter),
            },
            market_info_market,
          };
        }
        return undefined;
      } else if (market_info_market === EMarketInfoMarket.SPP) {
        if (
          !data.enabled ||
          (isTemplate &&
            isValidSppTemplateMarketInfo(data as IETagSppMarketData)) ||
          isValidSppMarketInfo(data as IETagSppMarketData)
        ) {
          return {
            data: {
              ...data,
              spp_price_list: (
                data as IETagSppMarketData
              ).spp_price_list.filter(marketInfoPriceFilter),
            },
            market_info_market,
          };
        }
        return undefined;
      }

      return marketInfo;
    })
    .filter(
      (data: IETagMarketInfo | undefined) => data !== undefined,
    ) as IETagMarketInfo[];
};

export const transformAndUpdateMarketInfos =
  () =>
  async (detailState: IDetailState): Promise<void> => {
    const {
      config,
      draft_id,
      isMarketInfosEdited,
      marketInfos,
      template_id,
      toEntity,
    } = detailState;

    if (
      isMarketInfosEdited &&
      config !== undefined &&
      config.market_info_markets !== undefined &&
      config.market_info_markets.length > 0
    ) {
      if (marketInfos.length === 0) {
        throw new Error(
          `Missing markets for config.market_info: ${JSON.stringify(
            config.market_info_markets,
          )}`,
        );
      }

      checkToEntity(toEntity);

      const isTemplate = template_id !== null && template_id !== undefined;

      const cleanedUpMarketInfos: IETagMarketInfo[] = cleanUpMarketInfos(
        marketInfos,
        isTemplate,
      );

      const updateETagMarketInfosResponse: AxiosResponse<IETagMarketInfosResponse> =
        await updateETagMarketInfos(
          toEntity!.to_entity,
          draft_id,
          template_id,
          cleanedUpMarketInfos,
        );

      const eTagMarketInfosResponse: IETagMarketInfosResponse =
        updateETagMarketInfosResponse.data;

      if (!isSuccessStatus(updateETagMarketInfosResponse.status)) {
        throw new Error(eTagMarketInfosResponse.errorMessage!);
      }
    }
  };

export const isMarketInfosEditable = (viewMode: EViewMode): boolean =>
  viewMode === EViewMode.EditETagDraft ||
  viewMode === EViewMode.EditETagTemplate;

const splitRangeToPathProfile = (
  range: IETagDefaultRange,
  path_profile: IETagPathProfile | null,
): IETagPathProfile | null => {
  if (range.start === null || range.stop === null) {
    return path_profile;
  }
  const interval: IInterval = {
    start: ZonedDateTime.parseIso(range.start, 'UTC'),
    stop: ZonedDateTime.parseIso(range.stop, 'UTC'),
  };
  const newIntervals: IInterval[] = divideIntervalHourly(interval);
  const newEnergyProfiles: IETagPathEnergyProfile[] = [
    {
      energy_profile_details: newIntervals.map((interval: IInterval) => ({
        start: interval.start.toIsoString(),
        stop: interval.stop.toIsoString(),
        mw: null,
        energy_profile_type: null,
        limit_clearing: null,
        start_ramp_dur: null,
        stop_ramp_dur: null,
      })),
      profile_id: 1,
    },
  ];
  const newPathProfile: IETagPathProfile = path_profile
    ? {
        ...path_profile,
        energy_profiles: newEnergyProfiles,
      }
    : { energy_profiles: newEnergyProfiles, trans_alloc_profiles: null };

  return newPathProfile;
};

/**
 * Given an interval, it divides the interval into hourly chunks.
 *
 * Will stop when the stop of an interval is same or after the end of the given interval
 *
 * @param interval The interval to divide into hourly intervals
 */
const divideIntervalHourly = (interval: IInterval): IInterval[] => {
  const returnVal: IInterval[] = [
    {
      start: interval.start,
      stop: interval.start.add(60, 'minutes'),
    },
  ];

  // repeat this until the new interval stop matches or exceeds the overall stop
  while (returnVal[returnVal.length - 1].stop.isBefore(interval.stop)) {
    returnVal.push({
      start: returnVal[returnVal.length - 1].stop,
      stop: returnVal[returnVal.length - 1].stop.add(60, 'minutes'),
    });
  }
  return returnVal;
};

/**
 * Builds the profile intervals given a start time, stop time, gen value, and
 * a product profile template
 *
 * @param productProfile The template that will be used to calculate the intervals
 * @param startDateTime Start time for the profile intervals
 * @param stopDateTime Stop time for the profile intervals
 * @param genRequest The number of MW this profile wants to generate
 * @param holidayList The list of custom holidays for an entity
 */
export const getIntervalsForProductProfile = (
  productProfile: IDailyProductProfile | undefined,
  startDateTime: ZonedDateTime | null,
  stopDateTime: ZonedDateTime | null,
  genRequest: number | null,
  holidayList: string[] | undefined,
): IProfileInterval[] => {
  if (productProfile === undefined) {
    throw new Error('Product profile not found for given ID');
  }
  if (startDateTime === null) {
    throw new Error('Start date cannot be null');
  }
  if (stopDateTime === null) {
    throw new Error('Stop date cannot be null');
  }
  if (genRequest === null) {
    throw new Error('Gen request cannot be null');
  }
  if (holidayList === undefined) {
    throw new Error('Holiday list cannot be undefined');
  }
  if (startDateTime > stopDateTime) {
    throw new Error('Start time must be earlier than stop time');
  }

  const productProfileIntervals: IProfileInterval[] = [];
  let currentStartDateTime: ZonedDateTime = startDateTime;
  // Check for intervals day by day until the stopDateTime is passed
  while (currentStartDateTime < stopDateTime.withSeconds(0)) {
    // Set the stop time for this day's intervals to the next day at 00:00:00;
    // If the overall stop dateTime is before then, set it to that time
    let currentStopDateTime = currentStartDateTime
      .withHour(0)
      .withMinute(0)
      .withSeconds(0)
      .add(1, 'day');
    if (currentStopDateTime > stopDateTime) {
      currentStopDateTime = stopDateTime.withSeconds(0);
    }

    let intervalsToAdd: IInterval[] = [];

    const currentDateIso: string = currentStartDateTime.format('YYYY-MM-DD');

    // Check if the current day is in the custom holiday list by comparing
    // just the Date portion of the current DateTime's ISO string
    if (holidayList.includes(currentDateIso)) {
      if (productProfile.holiday_template.interval_templates.length > 0) {
        intervalsToAdd = getIntervalsForDay(
          currentStartDateTime,
          currentStopDateTime,
          productProfile.holiday_template,
        );
      }
    } else {
      // Checks whitch day of the week this current day is; 0 = Sunday, Saturday = 6
      switch (currentStartDateTime.asDate().getDay()) {
        case EDayOfWeek.Sunday: {
          if (productProfile.sunday_template.interval_templates.length > 0) {
            intervalsToAdd = getIntervalsForDay(
              currentStartDateTime,
              currentStopDateTime,
              productProfile.sunday_template,
            );
          }
          break;
        }
        case EDayOfWeek.Saturday: {
          if (productProfile.saturday_template.interval_templates.length > 0) {
            intervalsToAdd = getIntervalsForDay(
              currentStartDateTime,
              currentStopDateTime,
              productProfile.saturday_template,
            );
          }
          break;
        }
        case EDayOfWeek.Friday: {
          if (productProfile.friday_template.interval_templates.length > 0) {
            intervalsToAdd = getIntervalsForDay(
              currentStartDateTime,
              currentStopDateTime,
              productProfile.friday_template,
            );
          }
          break;
        }
        default: {
          if (productProfile.default_template.interval_templates.length > 0) {
            intervalsToAdd = getIntervalsForDay(
              currentStartDateTime,
              currentStopDateTime,
              productProfile.default_template,
            );
          }
        }
      }
    }
    intervalsToAdd.forEach((interval: IInterval) => {
      productProfileIntervals.push({
        genRequest: genRequest,
        startDateTime: interval.start,
        stopDateTime: interval.stop,
      });
    });
    // Next interval starts at 00:00:00 of the next day
    currentStartDateTime = currentStartDateTime
      .add(1, 'day')
      .withHour(0)
      .withMinute(0)
      .withSeconds(0);
  }
  return productProfileIntervals;
};

const getIntervalsForDay = (
  startTime: ZonedDateTime,
  stopTime: ZonedDateTime,
  dailyTemplate: ISingleDayProfileTemplate,
): IInterval[] => {
  const dailyIntervals: IInterval[] = [];
  const startTimeInMinutes = startTime.getHour() * 60 + startTime.getMinute();
  let stopTimeInMinutes = stopTime.getHour() * 60 + stopTime.getMinute();
  // Add a days worth of minutes to the stopTimeInMinutes if stopTime is at least a day
  // after startTime (adjusted value counts from the final hour in the day to account for DST)
  if (
    startTime.withHour(0).withMinute(0).withSeconds(0) <
    stopTime.withHour(0).withMinute(0).withSeconds(0)
  ) {
    stopTimeInMinutes +=
      (stopTime
        .add(1, 'day')
        .withHour(0)
        .withMinute(0)
        .subtract(1, 'hour')
        .getHour() +
        1) *
      60;
  }
  dailyTemplate.interval_templates.forEach(
    (interval: ISingleDayProfileInterval) => {
      const intervalStartInMinutes =
        interval.start.hour * 60 + interval.start.minute;
      const intervalStopInMinutes =
        interval.stop.hour * 60 + interval.stop.minute;
      // Compare by minute in the day (hour times 60, plus current minute), to see if a given template
      // interval is within the bounds of the passed start and stop dateTimes
      if (startTimeInMinutes <= intervalStartInMinutes) {
        if (stopTimeInMinutes >= intervalStopInMinutes) {
          // Template interval completely within start and stop times
          dailyIntervals.push({
            start: startTime
              .withHour(interval.start.hour)
              .withMinute(interval.start.minute)
              .withSeconds(0),
            stop: startTime
              .withHour(interval.stop.hour)
              .withMinute(interval.stop.minute)
              .withSeconds(0),
          });
        } else {
          if (
            stopTimeInMinutes < intervalStopInMinutes &&
            stopTimeInMinutes > intervalStartInMinutes
          ) {
            // Stop time is before the end of the interval, and after the start, so use that as the new end time;
            // Otherwise, add no interval since the total time range is before the interval start
            dailyIntervals.push({
              start: startTime
                .withHour(interval.start.hour)
                .withMinute(interval.start.minute)
                .withSeconds(0),
              stop: stopTime,
            });
          }
        }
      }
      // This means startTimeInMinutes > intervalStartTimeInMinutes
      else {
        // If the start time is after the interval's stop time, add no interval
        // since that means the time range is completely outside the interval
        if (startTimeInMinutes < intervalStopInMinutes) {
          if (stopTimeInMinutes >= intervalStopInMinutes) {
            // Start time is before end of interval, but stop time is after, so
            // set the profile interval start time as the passed in start dateTime
            dailyIntervals.push({
              start: startTime,
              stop: startTime
                .withHour(interval.stop.hour)
                .withMinute(interval.stop.minute)
                .withSeconds(0),
            });
          } else {
            // Start and stop times are completely within the interval,
            // so use them as the times for the new interval
            dailyIntervals.push({
              start: startTime,
              stop: stopTime,
            });
          }
        }
      }
    },
  );
  return dailyIntervals;
};
