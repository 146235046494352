import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import {
  IETagProfileChangeRequestResponse,
  IETagQueryResponse,
} from 'interfaces/ETag';
import {
  PROFILE_CHANGES_ROUTE,
  TAGS_DISTRIBUTED_EDIT_MODE_ROUTE,
  TAGS_DISTRIBUTED_ENERGY_PROFILE_SNAPSHOTS,
  TAGS_DISTRIBUTED_LOSS_ACCOUNTINGS,
  TAGS_DISTRIBUTED_MARKET_SEGMENTS,
  TAGS_DISTRIBUTED_PHYSICAL_SEGMENTS,
  TAGS_DISTRIBUTED_PHYSICAL_SEGMENTS_PROFILES,
  TAGS_DISTRIBUTED_ROUTE,
  TAGS_DISTRIBUTED_SEARCH_ROUTE,
  TAGS_DISTRIBUTED_SUMMARY_PROFILES_ROUTE,
  TAGS_DISTRIBUTED_SUMMARY_ROUTE,
  TAGS_DISTRIBUTED_TRANSACTION_STATUSES,
  TAGS_DISTRIBUTED_TRANSMISSION_ALLOCATIONS,
} from 'services/agent/constants';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TTimeZone } from 'types/DateTime';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TFilterId } from 'types/Filter';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const retrieveETagDistributedEditMode = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  timeZone: TTimeZone,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_EDIT_MODE_ROUTE(toEntityId, tagPrimaryKey, timeZone),
    DEFAULT_OPTIONS,
  );
};

// TODO: Add retry count for error handling
export const retrieveETagDistributedSummaryAttribute = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  start?: ZonedDateTime,
  end?: ZonedDateTime,
  filterId?: TFilterId,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_SUMMARY_ROUTE(
      toEntityId,
      tagPrimaryKey,
      start,
      end,
      filterId,
    ),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagDistributedSummaryProfiles = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  timeZone: string,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: string,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_SUMMARY_PROFILES_ROUTE(
      toEntityId,
      tagPrimaryKey,
      timeZone,
      start,
      end,
      profileSegment,
    ),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagDistributedMarketSegment = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  miscInfoTokens?: string,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_MARKET_SEGMENTS(toEntityId, tagPrimaryKey, miscInfoTokens),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagDistributedPhysicalSegment = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_PHYSICAL_SEGMENTS(toEntityId, tagPrimaryKey),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagDistributedLossAccountings = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_LOSS_ACCOUNTINGS(toEntityId, tagPrimaryKey),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagDistributedTransmissionAllocations = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  retrieveAll?: boolean,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_TRANSMISSION_ALLOCATIONS(
      toEntityId,
      tagPrimaryKey,
      retrieveAll,
    ),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagDistributedTransactionStatuses = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_TRANSACTION_STATUSES(toEntityId, tagPrimaryKey),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagDistributedPhysicalSegmentsProfiles = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  highlightRevision: string,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_PHYSICAL_SEGMENTS_PROFILES(
      toEntityId,
      tagPrimaryKey,
      highlightRevision,
    ),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagDistributedEnergyProfileSnapshots = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  minRequestId: number,
  maxRequestId: number,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_ENERGY_PROFILE_SNAPSHOTS(
      toEntityId,
      tagPrimaryKey,
      minRequestId,
      maxRequestId,
    ),
    DEFAULT_OPTIONS,
  );
};

export const removeETag = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
): Promise<AxiosResponse> => {
  return await API.del(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_ROUTE(toEntityId, tagPrimaryKey),
    DEFAULT_OPTIONS,
  );
};

export const queryETagDistributedSummaryAttribute = async (
  toEntityId: TToEntityId,
  tagCode?: string,
  uiTagId?: string,
): Promise<AxiosResponse<IETagQueryResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DISTRIBUTED_SEARCH_ROUTE(toEntityId, tagCode, uiTagId),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagDistributedProfileChange = async (
  toEntityId: TToEntityId,
  profileChangeId: string,
): Promise<AxiosResponse<IETagProfileChangeRequestResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    PROFILE_CHANGES_ROUTE(toEntityId, profileChangeId),
    DEFAULT_OPTIONS,
  );
};
