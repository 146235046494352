import { AxiosResponse } from 'axios';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import {
  eTagTemplateToUid,
  getTemplateProfileStartDateTime,
} from 'components/molecules/ToEntityETagCreatePanel/helper';
import ToEntityETagPopover from 'components/molecules/ToEntityETagPopover/ToEntityETagPopover';
import { ERROR_RED } from 'constants/styles';
import { EActionState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import useDisplayTimedMessage from 'hooks/useDisplayTimedMessage';
import { IOption } from 'interfaces/Component';
import {
  IETagDraftCreateResponse,
  IETagExtendedIdentifier,
  IETagTemplate,
  IETagTemplatesResponse,
} from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import { ReactNode, useEffect, useState } from 'react';
import {
  createETagDraft,
  createETagDraftFromTemplate,
} from 'services/agent/tags/drafts';
import { retrieveTemplates } from 'services/agent/templates';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { detailPageLocationString } from 'utils/detail';
import { captureError } from 'utils/error';
import {
  isEmptyValue,
  isSuccessStatus,
  selectOptionLabelFilter,
} from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const MESSAGE_DISPLAY_TIME_IN_MILLISECONDS = 4000;

// Specialize the Select component
const TemplateSelect = styled((props: ISelectProps<IETagTemplate>) =>
  Select<IETagTemplate>(props),
)`
  width: 181px;
`;

const Message = styled.div`
  color: ${ERROR_RED};
  text-align: center;
`;

interface IToEntityETagCreatePanelProps {
  children?: ReactNode;
  contactInfo?: IContactInfo;
  eTagExtendedIdentifier?: IETagExtendedIdentifier;
  template?: IETagTemplate;
  timeZone: TTimeZone;
  toEntityId?: TToEntityId;
}

const ToEntityETagCreatePanel = ({
  children,
  contactInfo,
  template,
  timeZone,
  toEntityId,
}: IToEntityETagCreatePanelProps): JSX.Element => {
  const { displayTimedMessage, showTimedMessage, timedMessage } =
    useDisplayTimedMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tagTemplateOptions, setTagTemplateOptions] = useState<
    IOption<IETagTemplate>[]
  >([]);
  const [actionState, setActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [startDateTime, setStartDateTime] = useState<ZonedDateTime | null>(
    ZonedDateTime.now(timeZone).startOf('day'),
  );
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [selectedTagTemplate, setSelectedTagTemplate] = useState<
    IETagTemplate | undefined
  >();

  useAsyncEffect(async () => {
    if (template === undefined && toEntityId !== undefined) {
      try {
        setIsLoading(true);

        const response: AxiosResponse<IETagTemplatesResponse> =
          await retrieveTemplates(toEntityId);
        const eTagTemplatesResponse: IETagTemplatesResponse = response.data;

        if (!isSuccessStatus(response.status)) {
          throw new Error(eTagTemplatesResponse.errorMessage!);
        }

        setTagTemplateOptions(
          eTagTemplatesResponse.response
            .map(
              (eTagTemplate: IETagTemplate): IOption<IETagTemplate> => ({
                label: eTagTemplate.name === null ? '' : eTagTemplate.name,
                value: eTagTemplate,
              }),
            )
            .sort(
              (a: IOption<IETagTemplate>, b: IOption<IETagTemplate>): number =>
                a.label < b.label ? -1 : a.label > b.label ? 1 : 0,
            ),
        );
      } catch (error: any) {
        captureError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [template, toEntityId]);

  useEffect(() => {
    if (template !== undefined) {
      setStartDateTime(getTemplateProfileStartDateTime(template, timeZone));
    }
  }, [displayTimedMessage, template, timeZone]);

  useEffect(() => {
    if (
      startDateTime !== null &&
      selectedTagTemplate !== undefined &&
      !isEmptyValue(selectedTagTemplate.profile_start_time)
    ) {
      const templateStartTime: ZonedDateTime =
        template?.profile_start_time === undefined
          ? ZonedDateTime.parseIso(
              selectedTagTemplate.profile_start_time!,
              timeZone,
            )
          : ZonedDateTime.parseIso(template.profile_start_time!, timeZone);
      const templateStartHours: number = templateStartTime.getHour();
      const templateStartMinutes: number = templateStartTime.getMinute();

      if (
        !(
          startDateTime.getHour() === templateStartHours &&
          startDateTime.getMinute() === templateStartMinutes
        )
      ) {
        setStartDateTime(
          startDateTime
            .withHour(templateStartHours)
            .withMinute(templateStartMinutes),
        );

        displayTimedMessage(
          <Message>
            Tag start time has been reset to the selected template's profile
            start time.
          </Message>,
          MESSAGE_DISPLAY_TIME_IN_MILLISECONDS,
        );
      }
    }
  }, [
    displayTimedMessage,
    selectedTagTemplate,
    startDateTime,
    template,
    timeZone,
  ]);

  const handleSelectTagTemplate = (eTagTemplate: IETagTemplate | undefined) => {
    setSelectedTagTemplate(eTagTemplate);
  };

  const handleCreate = async () => {
    try {
      if (toEntityId === undefined) {
        throw new Error(`Must select a to-entity before creating a tag`);
      }
      setActionState(EActionState.Actioning);

      setErrorMessage(null);

      const response: AxiosResponse<IETagDraftCreateResponse> =
        template !== undefined && template !== null
          ? await createETagDraftFromTemplate(
              toEntityId,
              template.id,
              startDateTime!,
              timeZone,
              contactInfo,
            )
          : selectedTagTemplate === undefined
          ? await createETagDraft(toEntityId, startDateTime!)
          : await createETagDraftFromTemplate(
              toEntityId,
              selectedTagTemplate.id,
              startDateTime!,
              timeZone,
              contactInfo,
            );
      const eTagDraftCreateResponse: IETagDraftCreateResponse = response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(eTagDraftCreateResponse.errorMessage!);
      }

      setActionState(EActionState.Succeeded);

      if (eTagDraftCreateResponse.response.draft_id !== null) {
        const href: string = detailPageLocationString({
          draftId: eTagDraftCreateResponse.response.draft_id,
          defaultTimeZone: timeZone,
          mode: EPageMode.Edit,
          toEntity: toEntityId,
        });
        window.open(`${href}`, '_blank');
      } else {
        throw new Error('No draft_id in response, cannot open draft edit page');
      }
    } catch (error: any) {
      captureError(error);

      setErrorMessage(
        'An error occurred during draft tag creation. Please try again later.',
      );

      setActionState(EActionState.Failed);
    }
  };

  return (
    <ToEntityETagPopover
      actionState={actionState}
      childrenBefore={true}
      dateTimePickerMessage={timedMessage}
      dateTimePickerVisible={showTimedMessage}
      errorMessage={errorMessage}
      isPrimaryActionDisabled={toEntityId === null}
      onPrimaryAction={handleCreate}
      onStartDateTimeChange={setStartDateTime}
      primaryActionLabel='Create'
      startDateTime={startDateTime}
      timeZone={timeZone}
      toEntityId={toEntityId}
    >
      {children}
      {template === undefined ? (
        <SeparatedRowLayout>
          <span>
            Tag Template:
            <br />
            (optional)
          </span>
          <TemplateSelect
            allowClear={true}
            dropdownMatchSelectWidth={false}
            filter={selectOptionLabelFilter}
            isDisabled={isLoading || toEntityId === null}
            isLoading={isLoading}
            onChange={handleSelectTagTemplate}
            options={tagTemplateOptions}
            placeholder='Select Tag Template'
            showSearch={true}
            value={selectedTagTemplate}
            valueToUid={eTagTemplateToUid}
          />
        </SeparatedRowLayout>
      ) : null}
    </ToEntityETagPopover>
  );
};

export default ToEntityETagCreatePanel;
