import ProfileDataGridColumnHeader from 'components/molecules/ProfileDataGridColumnHeader/ProfileDataGridColumnHeader';
import {
  CELL_DISABLED_CLASS,
  CELL_TOTALS_CLASS,
  EDITOR_OPTIONS,
  TRANSMISSION_CONTRACT_NUMBER_KEY_REG_EXP,
} from 'components/organisms/ProfileInformationView/constants';
import {
  EDIT_PHYSICAL_SEGMENTS_PROFILES_LABEL,
  INITIAL_RECORD_ID,
  PROFILE_PATH_RAMP_START_KEY,
  PROFILE_PATH_RAMP_STOP_KEY,
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
  TRANSMISSION_ALLOC_TOTALS_KEY_SUFFIX,
  TRANSMISSION_LOSS_KEY_SUFFIX,
  TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY,
  TRANSMISSION_POD_KEY_SUFFIX,
  TRANSMISSION_POR_KEY_SUFFIX,
} from 'constants/Detail';
import { ID_KEY, LEFT_SEPARATOR_CLASS } from 'constants/General';
import { DATA_GRID_DATA_COLUMN_WIDTH_VALUE } from 'constants/styles';
import { EProfileDataGridCellType, ETableConfiguration } from 'enums/Detail';
import { EEnergyProfileType } from 'enums/ETag';
import { ESeverity } from 'enums/General';
import {
  IDataGridSelectionContext,
  IValidationMessage,
} from 'interfaces/Component';
import { IProfileDataGridCell } from 'interfaces/Detail';
import {
  IETagExceptionTransAllocProfileChange,
  IETagPathEnergyProfile,
  IETagPathTransmissionAllocationProfile,
  IETagPhysicalSegmentsProfile,
  IETagProfileChangeRequest,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
  ITransmissionDetailPlusProfileId,
} from 'interfaces/ETag';
import { Context } from 'react';
import {
  EditorProps,
  FormatterProps,
  SummaryFormatterProps,
} from 'react-data-grid';
import { IDetailState } from 'reduxes/Detail/types';
import {
  TDetailValidations,
  TProfileDataGridColumn,
  TProfileDataGridRow,
  TProfileDataGridSummaryRow,
} from 'types/Detail';
import { TStateTransform } from 'types/General';
import {
  getAdjustedContractNumber,
  getEditInfoKey,
  getKeyForProfileTransmission,
  getSplitEditInfoKey,
  getTransmissionName,
  stopDisabledDate,
} from 'utils/detail';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const checkRowId = (row: TProfileDataGridRow) => {
  if (
    row.id === undefined ||
    row.id === null ||
    row.id.type !== EProfileDataGridCellType.String
  ) {
    throw new Error(`Invalid row id for row: ${row}`);
  }
};

const displayAdjustedContractNumber = (
  adjustedContractNumber: string,
  multipleContracts = false,
  previewMode = false,
  contracts: IContracts[],
): any => {
  if (!multipleContracts || previewMode) {
    return adjustedContractNumber.match(
      TRANSMISSION_CONTRACT_NUMBER_KEY_REG_EXP,
    ) === null
      ? adjustedContractNumber
      : '';
  } else {
    let joinedContracts = '';
    if (contracts) {
      contracts.forEach((contract) => {
        joinedContracts =
          joinedContracts +
          `<div style="flex: 1; width: 70px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="${contract.contract} (${contract.product_name})">${contract.contract}</div>`;
      });
    }

    return `<div style="display: flex; flex-direction: row; gap: 10px;">${joinedContracts}</div>`;
  }
};

interface IContracts {
  contract: string | null;
  product_name?: string;
}

interface ISegment {
  segment: number | null;
  count: number;
  contracts: IContracts[];
}

export const getTransmissionColumns = (
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  selectedTableConfiguration: ETableConfiguration | undefined,
  isEditingUniqueProfiles: boolean,
  getCurrentFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  getFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  getEditor?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  summaryFormatter?: (
    props: SummaryFormatterProps<
      TProfileDataGridSummaryRow,
      TProfileDataGridRow
    >,
  ) => JSX.Element,
  groupColumnHeaders?: boolean,
  showLosses?: boolean,
  isPrintView?: boolean,
): TProfileDataGridColumn[] => {
  const transmissionColumns: TProfileDataGridColumn[] = [];
  let previousPid: number | null = null;
  let nextPid: number | null | undefined = undefined;
  let transmissionName: string = '';
  let shouldIncludeSeparator: boolean = false;

  const getColSpan = (props: any, colSpan: number) => {
    return props.type === 'HEADER' ? colSpan : 1;
  };

  const uniquePhysicalSegments = sortedTransmissionAllocations
    .map((item) => item.physical_segment_ref)
    .filter((value, index, self) => self.indexOf(value) === index);

  const segmentArray: ISegment[] = [];
  uniquePhysicalSegments.forEach((segment) => {
    const count = sortedTransmissionAllocations.filter(
      (item) => item.physical_segment_ref === segment,
    ).length;

    const contracts = sortedTransmissionAllocations
      .filter((item) => item.physical_segment_ref === segment)
      .map((item) => {
        return {
          contract: item.contract_number,
          product_name: item.trans_product_ref?.product_name,
        };
      });

    segmentArray.push({
      segment,
      count,
      contracts,
    });
  });

  let previousSegment: ISegment | undefined;

  const arefCount = segmentArray
    .slice()
    .map((a) => a.count)
    .reduce((a, b) => a + b, 0);

  sortedTransmissionAllocations.forEach(
    (
      eTagTransmissionAllocation: IETagTransmissionAllocation,
      index: number,
    ) => {
      let transmissionCode: string | undefined = '';
      const contractNumber = eTagTransmissionAllocation.contract_number;
      const physicalSegment = eTagTransmissionAllocation.physical_segment_ref;
      let transmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined;

      if (eTagTransmissionAllocation.contract_number) {
        transmissionPhysicalSegment = transmissionPhysicalSegments?.find(
          (segment) => segment.oasis_info?.includes(contractNumber!),
        );
      }

      if (
        !transmissionPhysicalSegment &&
        eTagTransmissionAllocation.physical_segment_ref
      ) {
        transmissionPhysicalSegment = transmissionPhysicalSegments?.find(
          (segment) =>
            segment.physical_segment_id ===
            eTagTransmissionAllocation.physical_segment_ref,
        );
      }
      transmissionCode = transmissionPhysicalSegment?.tp_code?.entity_code;

      const currentSegment = segmentArray.find(
        (segment) => segment.segment === physicalSegment,
      );
      const { physical_segment_ref, trans_alloc_id, trans_product_ref } =
        eTagTransmissionAllocation;
      const adjustedContractNumber: string = getAdjustedContractNumber(
        eTagTransmissionAllocation,
      );
      const physicalSegmentRefString: string =
        physical_segment_ref === null
          ? TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY
          : physical_segment_ref.toString();
      const nextTransmissionAllocation:
        | IETagTransmissionAllocation
        | undefined = sortedTransmissionAllocations[index + 1];
      const hasChangedPid: boolean = physical_segment_ref !== previousPid;

      nextPid =
        nextTransmissionAllocation === undefined
          ? undefined
          : nextTransmissionAllocation.physical_segment_ref;

      const productName: string =
        trans_product_ref === null ? '' : `\n${trans_product_ref.product_name}`;

      if (hasChangedPid) {
        previousPid = physical_segment_ref;
        transmissionName = getTransmissionName(
          eTagTransmissionAllocation,
          transmissionPhysicalSegments,
        );
        shouldIncludeSeparator = index > 0;

        const key: string = getKeyForProfileTransmission({
          adjustedContractNumber,
          physicalSegmentRef: physical_segment_ref,
          transAllocId: trans_alloc_id,
          transmissionName,
        });

        if (selectedTableConfiguration === ETableConfiguration.All) {
          transmissionColumns.push({
            cellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            getFormatter: getCurrentFormatter,
            headerCellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            headerRenderer: ProfileDataGridColumnHeader,
            key: `${key}:${TRANSMISSION_POR_KEY_SUFFIX}`,
            name: `${
              transmissionCode || ''
            }\n${transmissionName}\nPOR-Current for T${physicalSegmentRefString}`,
            resizable: true,
            summaryCellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            summaryFormatter,
            width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
          });
        }

        shouldIncludeSeparator =
          index > 0 &&
          (selectedTableConfiguration === ETableConfiguration.POD ||
            isEditingUniqueProfiles);

        if (
          selectedTableConfiguration === ETableConfiguration.POD ||
          selectedTableConfiguration === ETableConfiguration.All ||
          isEditingUniqueProfiles
        ) {
          transmissionColumns.push({
            cellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            editorOptions: isEditingUniqueProfiles ? EDITOR_OPTIONS : undefined,
            getEditor:
              physical_segment_ref === null || !isEditingUniqueProfiles
                ? undefined
                : getEditor,
            getFormatter: getCurrentFormatter,
            headerCellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            headerRenderer: ProfileDataGridColumnHeader,
            key: `${key}:${TRANSMISSION_POD_KEY_SUFFIX}`,
            name: `${transmissionCode || ''}\n${transmissionName}\n${
              isEditingUniqueProfiles ? 'POD Profile' : 'POD-Current'
            } for T${physicalSegmentRefString}`,
            resizable: true,
            summaryCellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            summaryFormatter,
            width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
          });
        }

        if (
          (selectedTableConfiguration === ETableConfiguration.All ||
            selectedTableConfiguration === ETableConfiguration.NewTagRequest) &&
          showLosses
        ) {
          transmissionColumns.push({
            getFormatter,
            headerRenderer: ProfileDataGridColumnHeader,
            key: `${key}:${TRANSMISSION_LOSS_KEY_SUFFIX}`,
            name: `${
              transmissionCode || ''
            }\n${transmissionName}\nLOSS-Current for T${physicalSegmentRefString}`,
            resizable: true,
            summaryFormatter,
            width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
          });
        }
      }

      shouldIncludeSeparator =
        index > 0 &&
        selectedTableConfiguration === ETableConfiguration.ProfileTotals &&
        hasChangedPid;

      const key: string = getKeyForProfileTransmission({
        adjustedContractNumber,
        physicalSegmentRef: physical_segment_ref,
        transAllocId: trans_alloc_id,
        transmissionName,
      });

      const shouldIncludeLeftAltSeparator =
        index > -1 && currentSegment !== previousSegment;
      if (previousSegment !== currentSegment) {
        previousSegment = currentSegment;
      }

      let cellClass: string | undefined = shouldIncludeLeftAltSeparator
        ? LEFT_SEPARATOR_CLASS
        : undefined;

      if (physical_segment_ref === null) {
        cellClass = `${CELL_DISABLED_CLASS} ${cellClass}`;
      }

      transmissionColumns.push({
        resizable: false,
        cellClass,
        colSpan:
          groupColumnHeaders && currentSegment
            ? (props: any) => getColSpan(props, currentSegment.count)
            : () => 1,
        editorOptions: EDITOR_OPTIONS,
        getEditor: physical_segment_ref === null ? undefined : getEditor,
        getFormatter,
        headerCellClass: shouldIncludeLeftAltSeparator
          ? LEFT_SEPARATOR_CLASS
          : undefined,
        headerRenderer: ProfileDataGridColumnHeader,
        key,
        name: `${transmissionCode || ''}\n\n${displayAdjustedContractNumber(
          adjustedContractNumber,
          currentSegment && currentSegment.count > 1,
          isPrintView,
          currentSegment ? currentSegment.contracts : [],
        )}${productName}\n\n${
          transmissionPhysicalSegment?.physical_segment_id
        }`,
        summaryCellClass: shouldIncludeLeftAltSeparator
          ? LEFT_SEPARATOR_CLASS
          : undefined,
        summaryFormatter,
        width: arefCount < 3 ? 80 : null,
      });

      // Hacky way of adding a prop
      if (groupColumnHeaders && currentSegment && currentSegment.count > 1) {
        transmissionColumns.forEach((column) => {
          if (column.name.toString().includes('<div')) {
            (column as any)['hideTooltip'] = true;
          }
        });
        const lastColumn = transmissionColumns[transmissionColumns.length - 1];
        if (lastColumn) {
          (lastColumn as any)['renderTextAsHtml'] = true;
          (lastColumn as any)['tooltipText'] = `${
            transmissionCode || ''
          }\n\n${displayAdjustedContractNumber(
            adjustedContractNumber,
            currentSegment && currentSegment.count > 1,
            true,
            [],
          )}${productName}`;
        }
      }

      if (physical_segment_ref !== nextPid) {
        if (
          selectedTableConfiguration === ETableConfiguration.ProfileTotals ||
          selectedTableConfiguration === ETableConfiguration.All
        ) {
          transmissionColumns.push({
            cellClass: CELL_TOTALS_CLASS,
            getFormatter,
            headerRenderer: ProfileDataGridColumnHeader,
            key: `${key}:${TRANSMISSION_ALLOC_TOTALS_KEY_SUFFIX}`,
            name: `${
              transmissionCode || ''
            }\n${transmissionName}\nAlloc-Totals for T${physicalSegmentRefString}`,
            resizable: true,
            summaryFormatter,
            width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
          });
        }
      }
    },
  );

  return transmissionColumns;
};

export const getInitialRowForId = (
  id: string,
  initialProfileInformationDataSet?: TProfileDataGridRow[],
): TProfileDataGridRow | undefined => {
  if (initialProfileInformationDataSet !== undefined) {
    return initialProfileInformationDataSet.find(
      (initialRow: TProfileDataGridRow): boolean => {
        const initialRowId: IProfileDataGridCell | null | undefined =
          initialRow[ID_KEY];

        if (initialRowId === undefined) {
          throw new Error(
            `Missing key: id for initialRow: ${JSON.stringify(initialRow)}`,
          );
        }

        return initialRowId !== null && id === initialRowId.value;
      },
    );
  }

  return undefined;
};

export const stopDisabledDateForRow =
  (row: TProfileDataGridRow) =>
  (dateTime: ZonedDateTime | null, isSelected?: boolean): boolean => {
    if (dateTime !== null) {
      const startCell: IProfileDataGridCell | null | undefined =
        row[PROFILE_PATH_START_KEY];

      if (startCell === undefined) {
        throw new Error(
          `Missing key: ${PROFILE_PATH_START_KEY} for row: ${JSON.stringify(
            row,
          )}`,
        );
      }

      if (startCell !== null) {
        if (startCell.type !== EProfileDataGridCellType.DateTimeString) {
          throw new Error('Invalid start cell type');
        }

        if (startCell !== null) {
          if (startCell.type !== EProfileDataGridCellType.DateTimeString) {
            throw new Error('Invalid start cell type');
          }

          return stopDisabledDate(
            startCell.value as string,
            dateTime,
            isSelected,
          );
        }
      }
    }

    return false;
  };

export const isInitialProfileDataGridRow = (
  row: TProfileDataGridRow,
): boolean => {
  const rowId: IProfileDataGridCell | null | undefined = row[ID_KEY];

  if (rowId === undefined) {
    throw new Error(`Missing key: id for row: ${JSON.stringify(row)}`);
  }

  if (rowId === null || rowId.type !== EProfileDataGridCellType.String) {
    throw new Error(`Invalid rowId for row: ${JSON.stringify(row)}`);
  }

  const { primaryId } = getSplitEditInfoKey(rowId.value as string);

  return primaryId === INITIAL_RECORD_ID;
};

export const isInitialProfileDataGrid = (
  rows: TProfileDataGridRow[],
): boolean =>
  rows.length === 1 ? isInitialProfileDataGridRow(rows[0]) : false;

export const validateProfileInformation = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
) => {
  const detailValidations: TDetailValidations = {};
  const validationMessages: IValidationMessage[] = [];

  if (physicalSegmentsProfiles !== null) {
    let isPhysicalSegmentsProfilesValid: boolean = true;

    physicalSegmentsProfiles.forEach(
      (
        eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile,
        index: number,
      ) => {
        if (isEmptyValue(eTagPhysicalSegmentsProfile.start)) {
          isPhysicalSegmentsProfilesValid = false;

          validationMessages.push({
            message: `Missing Start for row ${index + 1}`,
            severity: ESeverity.Error,
          });
        }

        if (isEmptyValue(eTagPhysicalSegmentsProfile.stop)) {
          isPhysicalSegmentsProfilesValid = false;

          validationMessages.push({
            message: `Missing Stop for row ${index + 1}`,
            severity: ESeverity.Error,
          });
        }
      },
    );

    detailValidations[
      getEditInfoKey(EDIT_PHYSICAL_SEGMENTS_PROFILES_LABEL, 0, 0)
    ] = isPhysicalSegmentsProfilesValid;
  }

  return {
    detailValidations,
    validationMessages,
  };
};

const unpackTagProfileChanges = (
  profileChangeRequest: IETagProfileChangeRequest,
): [
  IETagPathEnergyProfile[],
  IETagPathTransmissionAllocationProfile[],
  IETagExceptionTransAllocProfileChange[],
] => {
  // Unpack the energy profile, trans alloc base changes, and exception_trans_alloc_profile_change from the request
  let profileChangeEnergyProfiles: IETagPathEnergyProfile[] = [];
  profileChangeRequest.tag_profile_changes?.energy_profile_changes?.forEach(
    (energyProfileChange) => {
      profileChangeEnergyProfiles.push({
        energy_profile_details: energyProfileChange.energy_profile_details,
        profile_id: energyProfileChange.profile_ref,
      });
    },
  );

  let profileChangeBaseTransAllocs: IETagPathTransmissionAllocationProfile[] =
    [];
  profileChangeRequest.tag_profile_changes?.base_trans_alloc_profile_changes?.forEach(
    (baseTransAllocChange) => {
      profileChangeBaseTransAllocs.push({
        trans_alloc_profile_attributes:
          baseTransAllocChange.trans_alloc_profile_attributes,
        trans_alloc_profile_details:
          baseTransAllocChange.trans_alloc_profile_details,
      });
    },
  );

  let profileChangeExceptionTransAllocs: IETagExceptionTransAllocProfileChange[] =
    [];
  profileChangeRequest.tag_profile_changes?.exception_trans_alloc_profile_changes?.forEach(
    (exceptionTransAllocChange) => {
      profileChangeExceptionTransAllocs.push({
        changed_trans_alloc_profile_details:
          exceptionTransAllocChange.changed_trans_alloc_profile_details,
        trans_alloc_id: exceptionTransAllocChange.trans_alloc_id,
      });
    },
  );
  return [
    profileChangeEnergyProfiles,
    profileChangeBaseTransAllocs,
    profileChangeExceptionTransAllocs,
  ];
};

const genEnergyProfileToDataGridRows = (
  profileChangeEnergyProfiles: IETagPathEnergyProfile[],
  detailState: IDetailState,
): [TProfileDataGridRow[], number, string] => {
  // Loop through all the energy profiles and turn them into TProfileDataGridRow objects; on non draft, non template tags (which should be every
  // tag we are doing this on), there should be no way to uncheck the "Same Energy Profile" button and enable unique energy profiles,
  // but we'll check here anyway just to be safe
  let currentRowNum: number = 0;
  let genSegmentKey: string | null = null;
  let incomingProfileChanges: TProfileDataGridRow[] = [];
  for (let energyProfile of profileChangeEnergyProfiles) {
    // On the detail screen (non draft and non template) the only profile we can edit with a profile change is the generation profile, so we
    // only need to look for and apply those changes
    if (energyProfile.profile_id === 1) {
      // This is the generation segment/cell, so apply all the details as such
      for (let energyProfileDetail of energyProfile.energy_profile_details ??
        []) {
        currentRowNum += 1;
        let generationProfileChange: TProfileDataGridRow = {};
        generationProfileChange[ID_KEY] = {
          type: EProfileDataGridCellType.String,
          value: ['profileChangeRowEdit', currentRowNum, '0'].join(':'),
        };
        generationProfileChange[PROFILE_PATH_START_KEY] = {
          type: EProfileDataGridCellType.DateTimeString,
          value: energyProfileDetail.start ?? '',
        };
        generationProfileChange[PROFILE_PATH_STOP_KEY] = {
          type: EProfileDataGridCellType.DateTimeString,
          value: energyProfileDetail.stop ?? '',
        };
        generationProfileChange[PROFILE_PATH_RAMP_START_KEY] = {
          type: EProfileDataGridCellType.Number,
          value: energyProfileDetail.start_ramp_dur ?? '',
        };
        generationProfileChange[PROFILE_PATH_RAMP_STOP_KEY] = {
          type: EProfileDataGridCellType.Number,
          value: energyProfileDetail.stop_ramp_dur ?? '',
        };
        // Get the generation source from the detail state so we can build the correct cell key

        const energyProfileType: string =
          energyProfileDetail.energy_profile_type
            ? energyProfileDetail.energy_profile_type ===
              EEnergyProfileType.MarketLevel
              ? 'marketLevel'
              : 'reliabilityLimit'
            : 'marketLevel';

        genSegmentKey = [
          'gen',
          detailState.generationPhysicalSegment?.generation_source
            ?.point_name ?? '-',
          energyProfileType,
        ].join(':');

        generationProfileChange[genSegmentKey] = {
          type: EProfileDataGridCellType.Number,
          value: energyProfileDetail.mw ?? 0,
        };
        // Build out the transmission segment cells with null values (may fill them in with the profileChangeExceptionTransAllocs below)
        for (let transAlloc of detailState.transmissionAllocations ?? []) {
          const key: string = getKeyForProfileTransmission({
            adjustedContractNumber: getAdjustedContractNumber(transAlloc),
            physicalSegmentRef: transAlloc.physical_segment_ref,
            transAllocId: transAlloc.trans_alloc_id,
            transmissionName: getTransmissionName(
              transAlloc,
              detailState.transmission_physical_segments,
            ),
          });
          generationProfileChange[key] = null;
        }
        incomingProfileChanges.push(generationProfileChange);
      }
    }
  }
  return [incomingProfileChanges, currentRowNum, genSegmentKey ?? ''];
};

const transAllocChangesToTransAllocDetails = (
  profileChangeBaseTransAllocs: IETagPathTransmissionAllocationProfile[],
  profileChangeExceptionTransAllocs: IETagExceptionTransAllocProfileChange[],
  detailState: IDetailState,
): ITransmissionDetailPlusProfileId[] => {
  let transAllocDetailPlusIds: ITransmissionDetailPlusProfileId[] = [];
  for (let transAllocProfile of profileChangeBaseTransAllocs) {
    if (
      transAllocProfile.trans_alloc_profile_details &&
      transAllocProfile.trans_alloc_profile_attributes
    ) {
      // Verify that this trans alloc id exists on this tag/detail
      for (let transAlloc of detailState.transmissionAllocations ?? []) {
        if (
          transAlloc.trans_alloc_id ===
          transAllocProfile.trans_alloc_profile_attributes.trans_alloc_id
        ) {
          transAllocProfile.trans_alloc_profile_details.forEach(
            (transAllocDetail) => {
              transAllocDetailPlusIds.push({
                trans_alloc_profile_detail: transAllocDetail,
                trans_alloc_profile_id:
                  transAllocProfile.trans_alloc_profile_attributes!
                    .trans_alloc_id,
              });
            },
          );
          break;
        }
      }
    }
  }

  for (let transAllocExceptionProfileDetail of profileChangeExceptionTransAllocs) {
    transAllocExceptionProfileDetail.changed_trans_alloc_profile_details.forEach(
      (changedDetail) => {
        transAllocDetailPlusIds.push({
          trans_alloc_profile_detail: changedDetail,
          trans_alloc_profile_id:
            transAllocExceptionProfileDetail.trans_alloc_id,
        });
      },
    );
  }

  return transAllocDetailPlusIds;
};

const createNewTransAllocProfileChange = (
  transAllocDetailWithId: ITransmissionDetailPlusProfileId,
  currentRowNum: number,
  genSegmentKey: string,
  transAllocCellKey: string,
  detailState: IDetailState,
): TProfileDataGridRow => {
  // Only way to determine energy profile type is to look at the existing physical segment profiles
  if (!detailState.physicalSegmentsProfiles) {
    throw new Error(
      'Error attempting to apply existing profile: must have existing physical segment profiles if no energy profile is included with this change',
    );
  }
  let transAllocProfileChange: TProfileDataGridRow = {};
  const energyProfileType: string = detailState.physicalSegmentsProfiles[0]
    .physical_segments_profiles?.generation?.profile?.market_level
    ? 'marketLevel'
    : 'reliabilityLimit';
  // If we didn't get a generation energy profile, we need to calculate the gen cell key here instead
  if (!genSegmentKey) {
    if (!detailState.generationPhysicalSegment?.generation_source?.point_name) {
      throw new Error(
        'Error attempting to apply existing profile detail: tag must already have a generation energy profile or one must be supplied with this profile change request',
      );
    }
    genSegmentKey = [
      'gen',
      detailState.generationPhysicalSegment.generation_source.point_name,
      energyProfileType,
    ].join(':');
  }
  transAllocProfileChange[ID_KEY] = {
    type: EProfileDataGridCellType.String,
    value: ['profileChangeRowEdit', currentRowNum, '0'].join(':'),
  };
  transAllocProfileChange[genSegmentKey] = null;
  transAllocProfileChange[PROFILE_PATH_START_KEY] = {
    type: EProfileDataGridCellType.DateTimeString,
    value: transAllocDetailWithId.trans_alloc_profile_detail.start ?? '',
  };
  transAllocProfileChange[PROFILE_PATH_STOP_KEY] = {
    type: EProfileDataGridCellType.DateTimeString,
    value: transAllocDetailWithId.trans_alloc_profile_detail.stop ?? '',
  };
  transAllocProfileChange[PROFILE_PATH_RAMP_START_KEY] = null;
  transAllocProfileChange[PROFILE_PATH_RAMP_STOP_KEY] = null;
  // Build out the transmission segment cells with null values except the one matching this trans alloc profile and those already set
  for (let transAlloc of detailState.transmissionAllocations ?? []) {
    const key: string = getKeyForProfileTransmission({
      adjustedContractNumber: getAdjustedContractNumber(transAlloc),
      physicalSegmentRef: transAlloc.physical_segment_ref,
      transAllocId: transAlloc.trans_alloc_id,
      transmissionName: getTransmissionName(
        transAlloc,
        detailState.transmission_physical_segments,
      ),
    });
    if (transAllocCellKey === key) {
      transAllocProfileChange[key] = {
        type: EProfileDataGridCellType.Number,
        value: transAllocDetailWithId.trans_alloc_profile_detail.mw ?? 0,
      };
    } else {
      if (!transAllocProfileChange[key]) {
        transAllocProfileChange[key] = null;
      }
    }
  }
  return transAllocProfileChange;
};

// Main goal with this function is to transform the IETagProfileChangeRequest into a list of
// TProfileDataGridRow's to pass into the detail state's profileChanges attribute
export const profileChangeRequestToDetailState = (
  profileChangeRequest: IETagProfileChangeRequest,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    if (profileChangeRequest.tag_profile_changes === undefined) {
      throw new Error(
        'Error loading existing profile change, no tag_profile_changes found',
      );
    }

    if (!detailState.generationPhysicalSegment?.generation_source?.point_name) {
      throw new Error(
        'Error applying existing profile change: tag must have a generation physical segment',
      );
    }

    const [
      profileChangeEnergyProfiles,
      profileChangeBaseTransAllocs,
      profileChangeExceptionTransAllocs,
    ] = unpackTagProfileChanges(profileChangeRequest);

    // No need to auto fill in the misc infos, notes, or contact info from the existing profile change; they will fill
    // this out themselves when submitting the request from our UI
    // Unsure if we need to verify that the incoming profile change has a tag_id matching the one of the tag we're loading it on,
    // or if we trust the producer of these URLs to only pass in profile changes for the tag that's loading; will skip for now (11/19/24)

    // Also ignoring loss accountings and curtailments for now (11/18/24), focusing only on trans alloc and energy profiles

    let [incomingProfileChanges, currentRowNum, genSegmentKey] =
      genEnergyProfileToDataGridRows(profileChangeEnergyProfiles, detailState);

    // Loop through all the base trans alloc changes and exception_trans_alloc_profile_changes and turn their detail sections into TProfileDataGridRow objects;
    // the base profile change (adding a profile) is taken care of in a different transform/dispatch (profileBaseTransAllocChangeToDetailState);
    // make sure to check if we have an existing rowthat this trans alloc should be added to before making a whole new one (the UI would
    // eventually combine them, but better to do it here to avoid user confusion)
    const transAllocDetailPlusIds: ITransmissionDetailPlusProfileId[] =
      transAllocChangesToTransAllocDetails(
        profileChangeBaseTransAllocs,
        profileChangeExceptionTransAllocs,
        detailState,
      );
    transAllocDetailPlusIds.forEach((transAllocDetailWithId) => {
      // Find the matching trans alloc on the detail state
      let matchedTransAlloc: IETagTransmissionAllocation | null = null;
      for (let transAlloc of detailState.transmissionAllocations ?? []) {
        if (
          transAlloc.trans_alloc_id ===
          transAllocDetailWithId.trans_alloc_profile_id
        ) {
          matchedTransAlloc = transAlloc;
        }
      }
      // If the trans alloc doesn't exist on the tag, just skip this trans alloc profile and try the next one
      if (!matchedTransAlloc) {
        return;
      }
      const transAllocCellKey: string = getKeyForProfileTransmission({
        adjustedContractNumber: getAdjustedContractNumber(matchedTransAlloc),
        physicalSegmentRef: matchedTransAlloc.physical_segment_ref,
        transAllocId: transAllocDetailWithId.trans_alloc_profile_id,
        transmissionName: getTransmissionName(
          matchedTransAlloc,
          detailState.transmission_physical_segments,
        ),
      });

      let transAllocProfileDetailApplied: boolean = false;
      // First check if this belongs in the same row as one of the profile changes we already added by comparing start and stop times
      incomingProfileChanges.forEach((profileChange) => {
        if (
          profileChange[PROFILE_PATH_START_KEY]?.value ===
            transAllocDetailWithId.trans_alloc_profile_detail.start &&
          profileChange[PROFILE_PATH_STOP_KEY]?.value ===
            transAllocDetailWithId.trans_alloc_profile_detail.stop &&
          !transAllocProfileDetailApplied
        ) {
          // Matched start and stop time, so need to find the same row as the energy profile change, and mark the detail as applied so that
          // we know not to make a new row for it
          profileChange[transAllocCellKey] = {
            type: EProfileDataGridCellType.Number,
            value: transAllocDetailWithId.trans_alloc_profile_detail.mw ?? 0,
          };
          transAllocProfileDetailApplied = true;
        }
      });
      if (!transAllocProfileDetailApplied) {
        // Didn't match an existing row, so we must make a new one
        currentRowNum += 1;
        let transAllocProfileChange: TProfileDataGridRow =
          createNewTransAllocProfileChange(
            transAllocDetailWithId,
            currentRowNum,
            genSegmentKey,
            transAllocCellKey,
            detailState,
          );
        incomingProfileChanges.push(transAllocProfileChange);
      }
    });

    let updatedDetailState: IDetailState = {
      ...detailState,
      profileChanges: incomingProfileChanges,
    };
    return updatedDetailState;
  };
};

export const profileBaseTransAllocChangeToDetailState = (
  baseTransAllocProfileChanges: IETagPathTransmissionAllocationProfile[],
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    let updatedTransAllocs: IETagTransmissionAllocation[] =
      detailState.transmissionAllocations ?? [];
    for (let baseTransAllocProfileChange of baseTransAllocProfileChanges) {
      let changeApplied: boolean = false;
      const baseTransAllocProfileChangeAttributes: IETagTransmissionAllocation | null =
        baseTransAllocProfileChange.trans_alloc_profile_attributes;
      // Check if this is an existing trans alloc we need to change
      for (let transAlloc of updatedTransAllocs ?? []) {
        if (
          baseTransAllocProfileChangeAttributes?.trans_alloc_id ===
          transAlloc.trans_alloc_id
        ) {
          transAlloc = baseTransAllocProfileChangeAttributes;
          changeApplied = true;
        }
      }
      if (!changeApplied && baseTransAllocProfileChangeAttributes) {
        updatedTransAllocs.push(baseTransAllocProfileChangeAttributes);
      }
    }
    let updatedDetailState: IDetailState = {
      ...detailState,
      transmissionAllocations: updatedTransAllocs,
    };
    return updatedDetailState;
  };
};
