import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { IETagMarketInfo, IETagMarketInfosResponse } from 'interfaces/ETag';
import { MARKET_INFOS_ROUTE } from 'services/agent/constants';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TETagDraftId, TETagTagPrimaryKey, TETagTemplateId } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';

export const retrieveETagMarketInfos = async (
  toEntityId: TToEntityId,
  draftId: TETagDraftId,
  tagPrimaryKey: TETagTagPrimaryKey | undefined,
  templateId: TETagTemplateId | undefined,
): Promise<AxiosResponse<IETagMarketInfosResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    MARKET_INFOS_ROUTE(toEntityId, draftId, tagPrimaryKey, templateId),
    DEFAULT_OPTIONS,
  );
};

export const updateETagMarketInfos = async (
  toEntityId: TToEntityId,
  draftId: TETagDraftId,
  templateId: TETagTemplateId | undefined,
  marketInfos: IETagMarketInfo[],
): Promise<AxiosResponse<IETagMarketInfosResponse>> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: marketInfos,
  };
  return await API.put(
    AGENT_SERVICE_API_NAME,
    MARKET_INFOS_ROUTE(toEntityId, draftId, undefined, templateId),
    options,
  );
};
