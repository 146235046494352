import { AxiosResponse } from 'axios';
import DetailView from 'components/molecules/DetailView/DetailView';
import SummaryInformationTitle from 'components/molecules/SummaryInformationTitle/SummaryInformationTitle';
import PhysicalPathReview from 'components/organisms/PhysicalPathReview/PhysicalPathReview';
import {
  editPhysicalPathInformationToDetailState,
  transAllocChangesToEditTransAllocMap,
  validatePhysicalPath,
} from 'components/organisms/PhysicalPathView/helpers';
import PhysicalPathEdit from 'components/organisms/PhysicalPathView/PhysicalPathEdit';
import { PROFILE_CHANGE_ID_QUERY_PARAM } from 'constants/Detail';
import { EDistributedTagItem } from 'enums/ETag';
import { ERetreiveState, EUpdateState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { EViewMode, EViewResize } from 'enums/View';
import useInitialData from 'hooks/useInitialData';
import usePhysicalPathReview from 'hooks/usePhysicalPathReview';
import usePrevious from 'hooks/usePrevious';
import { IEditPhysicalPathInformation } from 'interfaces/Detail';
import {
  IETagProfileChangeRequest,
  IETagProfileChangeRequestResponse,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { IViewProps } from 'interfaces/View';
import { Ref, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResizeDetector from 'react-resize-detector';
import {
  detailEditETagDetail,
  detailSetValidations,
} from 'reduxes/Detail/actions';
import {
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
} from 'reduxes/Detail/types';
import { retrieveETagDistributedProfileChange } from 'services/agent/tags/distributed';
import styled from 'styled-components';
import { TRootState } from 'types/Redux';
import useAsyncEffect from 'use-async-effect';
import { useLocation } from 'react-router-dom';
import { editOasisInfoToDetailState } from 'hooks/useTransmissionEditColumns/OasisInfoEdit/helpers';

const StyledTitle = styled(SummaryInformationTitle)`
  position: absolute;
  padding-left: 500px;
`;

const retrievePhysicalPathViewState = (state: TRootState) => {
  const {
    active,
    config,
    name,
    composite_state,
    generationPhysicalSegment,
    isDetailDeleted,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    pageMode,
    retrievingDetail,
    retrievingDistributedTagItems,
    tag_id,
    toEntity,
    transmission_physical_segments,
    transmissionAllocations,
    updatingDetail,
    viewMode,
    physical_segment_loss_percentages,
    show_losses,
    allUnfilteredTransmissionAllocations,
  } = state.detail.present;
  const isTransmissionAllocationsLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
      .retrieveState !== ERetreiveState.RetrievingCompleted;
  const isLossAccountingsLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
      .retrieveState !== ERetreiveState.RetrievingCompleted;
  const isMarketSegmentLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
      .retrieveState !== ERetreiveState.RetrievingCompleted;
  const isPhysicalSegmentLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegment]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegment]
      .retrieveState !== ERetreiveState.RetrievingCompleted;
  const isDetailLoading: boolean =
    (retrievingDetail !== ERetreiveState.NotRetrieving &&
      retrievingDetail !== ERetreiveState.RetrievingCompleted) ||
    isTransmissionAllocationsLoading ||
    isLossAccountingsLoading ||
    isMarketSegmentLoading ||
    isPhysicalSegmentLoading;
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;

  transmission_physical_segments?.map(
    (segment: IETagTransmissionPhysicalSegment) =>
      (segment.physical_segment_loss_percentage =
        (physical_segment_loss_percentages &&
          physical_segment_loss_percentages[segment.physical_segment_id]) ||
        '0'),
  );

  return {
    active,
    config,
    name,
    composite_state,
    generationPhysicalSegment,
    isDetailDeleted,
    isDetailLoading,
    isDetailUpdating,
    isPhysicalSegmentLoading,
    isTransmissionAllocationsLoading,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    pageMode,
    tag_id,
    toEntity,
    transmission_physical_segments,
    transmissionAllocations,
    viewMode,
    show_losses,
    allUnfilteredTransmissionAllocations,
  };
};

const PhysicalPathView = ({
  layoutGrid,
  resize,
  viewId,
}: IViewProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    active,
    name,
    composite_state,
    generationPhysicalSegment,
    isDetailDeleted,
    isDetailLoading,
    isDetailUpdating,
    isPhysicalSegmentLoading,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    pageMode,
    tag_id,
    toEntity,
    transmission_physical_segments,
    transmissionAllocations,
    viewMode,
    show_losses,
    config,
    allUnfilteredTransmissionAllocations,
  } = useSelector(retrievePhysicalPathViewState);
  const { search } = useLocation();
  const [viewWidth, setViewWidth] = useState<number>(0);
  const previousIsPhysicalSegmentLoading: boolean | undefined = usePrevious(
    isPhysicalSegmentLoading,
  );
  const previousIsDetailLoading: boolean | undefined =
    usePrevious(isDetailLoading);
  const previousIsDetailUpdating: boolean | undefined =
    usePrevious(isDetailUpdating);
  const previousPageMode: EPageMode | undefined = usePrevious(pageMode);

  const hasPhysicalSegmentChanged = (): boolean =>
    (isDetailUpdating === false && previousIsDetailUpdating === true) ||
    (isPhysicalSegmentLoading === false &&
      previousIsPhysicalSegmentLoading === true) ||
    ((viewMode === EViewMode.EditETagDraft ||
      viewMode === EViewMode.EditETagTemplate) &&
      isDetailLoading === false &&
      previousIsDetailLoading === true) ||
    previousPageMode !== pageMode;

  const {
    generationPhysicalSegments,
    loadPhysicalSegments,
    transmissionPhysicalSegments,
  } = usePhysicalPathReview(
    tag_id,
    marketSegments,
    generationPhysicalSegment,
    transmission_physical_segments,
    loadPhysicalSegment,
    lossAccountings,
    transmissionAllocations,
  );

  const initialGenerationPhysicalSegments:
    | IDetailGenerationPhysicalSegment[]
    | undefined = useInitialData(
    generationPhysicalSegments,
    hasPhysicalSegmentChanged,
  );

  const initialTransmissionPhysicalSegments:
    | IETagTransmissionPhysicalSegment[]
    | undefined = useInitialData(
    transmissionPhysicalSegments,
    hasPhysicalSegmentChanged,
  );

  const initialLoadPhysicalSegments: IDetailLoadPhysicalSegment[] | undefined =
    useInitialData(loadPhysicalSegments, hasPhysicalSegmentChanged);

  const handleResize = useCallback((width?: number) => {
    if (width !== undefined) {
      setViewWidth(width);
    }
  }, []);

  const handleChange = useCallback(
    (editPhysicalPathInformation: IEditPhysicalPathInformation) =>
      dispatch(
        detailEditETagDetail({
          isDetailEdited: true,
          stateTransform: editPhysicalPathInformationToDetailState(
            editPhysicalPathInformation,
          ),
        }),
      ),
    [dispatch],
  );

  const { detailValidations, validationMessages } = useMemo(() => {
    if (isDetailLoading) {
      return {
        detailValidations: undefined,
        validationMessages: undefined,
      };
    }

    return validatePhysicalPath(
      generationPhysicalSegment,
      transmission_physical_segments,
      loadPhysicalSegment,
      transmissionAllocations,
      lossAccountings,
    );
  }, [
    generationPhysicalSegment,
    isDetailLoading,
    loadPhysicalSegment,
    lossAccountings,
    transmissionAllocations,
    transmission_physical_segments,
  ]);

  useEffect(() => {
    if (detailValidations !== undefined) {
      dispatch(detailSetValidations({ detailValidations }));
    }
  }, [detailValidations, dispatch]);

  useAsyncEffect(async () => {
    if (!isDetailLoading) {
      // If at least starting in edit mode, and not a draft or template, check for a profileChangeId
      // and if found kick off the logic to apply it to the tag
      if (
        viewMode === EViewMode.EditETagAdjustment ||
        viewMode === EViewMode.EditETagAdjustmentWithATF ||
        viewMode === EViewMode.EditETagCorrection
      ) {
        const query: URLSearchParams = new URLSearchParams(search);
        const incomingProfileChangeId: string | null = query.get(
          PROFILE_CHANGE_ID_QUERY_PARAM,
        );
        if (incomingProfileChangeId && toEntity) {
          const retrieveProfileChangeResponse: AxiosResponse<IETagProfileChangeRequestResponse> =
            await retrieveETagDistributedProfileChange(
              toEntity.to_entity,
              incomingProfileChangeId,
            );

          const profileChangeResponse: IETagProfileChangeRequest =
            retrieveProfileChangeResponse.data.response;

          // Apply any base profile trans alloc changes to the OASIS info
          dispatch(
            detailEditETagDetail({
              isDetailEdited: true,
              stateTransform: editOasisInfoToDetailState({
                editTransmissionAllocationMap:
                  transAllocChangesToEditTransAllocMap(
                    profileChangeResponse.tag_profile_changes
                      ?.base_trans_alloc_profile_changes ?? [],
                  ),
              }),
            }),
          );
        }
      }
    }
  }, [dispatch, isDetailLoading]);

  const isDisabled: boolean =
    isDetailDeleted || isDetailLoading || isDetailUpdating;

  return (
    <ResizeDetector handleWidth={true} onResize={handleResize}>
      {({ targetRef }: { targetRef: Ref<HTMLDivElement> }) => (
        <DetailView
          isLoading={isDetailLoading}
          layoutGrid={layoutGrid}
          resize={resize}
          ref={targetRef}
          title={<>Physical Path</>}
          unformattedTitle={
            <StyledTitle
              active={active}
              compositeState={composite_state}
              isLoading={isDetailLoading}
              name={name}
              uiTagId={tag_id === null ? null : tag_id.ui_tag_id}
              viewMode={viewMode}
            />
          }
          validationMessages={validationMessages}
          viewId={viewId}
          viewResizeSetting={EViewResize.Down}
        >
          {pageMode === EPageMode.Review ||
          viewMode === EViewMode.EditETagAdjustment ||
          viewMode === EViewMode.EditETagAdjustmentWithATF ? (
            <PhysicalPathReview
              generationPhysicalSegments={generationPhysicalSegments}
              isDisabled={isDisabled}
              loadPhysicalSegments={loadPhysicalSegments}
              transmissionAllocations={allUnfilteredTransmissionAllocations}
              transmissionPhysicalSegments={transmissionPhysicalSegments}
              viewMode={viewMode}
              viewWidth={viewWidth}
              showLosses={
                viewMode !== EViewMode.ReviewETag ||
                pageMode === EPageMode.Review
                  ? config?.show_losses
                  : show_losses || false
              }
              composite_state={composite_state}
            />
          ) : pageMode === EPageMode.Edit ? (
            <PhysicalPathEdit
              generationPhysicalSegments={generationPhysicalSegments}
              initialGenerationPhysicalSegments={
                initialGenerationPhysicalSegments
              }
              initialLoadPhysicalSegments={initialLoadPhysicalSegments}
              initialTransmissionPhysicalSegments={
                initialTransmissionPhysicalSegments
              }
              isDisabled={isDisabled}
              loadPhysicalSegments={loadPhysicalSegments}
              onChange={handleChange}
              previousIsDetailUpdating={previousIsDetailUpdating}
              transmissionPhysicalSegments={transmissionPhysicalSegments}
              viewWidth={viewWidth}
            />
          ) : null}
        </DetailView>
      )}
    </ResizeDetector>
  );
};

export default PhysicalPathView;
